import React from "react";
import Svg from "../Svg";

const Icon = props => (
   <Svg viewBox="0 0 16 17" {...props}>
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M5.05263 0.25H10.9474C13.7379 0.25 16 2.51214 16 5.30263V11.1974C16 13.9879 13.7379 16.25 10.9474 16.25H5.05263C2.26214 16.25 0 13.9879 0 11.1974V5.30263C0 2.51214 2.26214 0.25 5.05263 0.25ZM5.68755 6.98615C6.30824 7.58157 7.14009 7.90595 8 7.88789C9.79215 7.92552 11.2765 6.50523 11.3179 4.71316C11.3179 4.36435 11.0351 4.08158 10.6863 4.08158C10.3375 4.08158 10.0547 4.36435 10.0547 4.71316C10.0091 5.80563 9.09292 6.65798 8 6.62474C6.90858 6.6579 5.99482 5.80431 5.95368 4.71316C5.95368 4.36435 5.67092 4.08158 5.3221 4.08158C4.97329 4.08158 4.69053 4.36435 4.69053 4.71316C4.70821 5.57308 5.06686 6.39073 5.68755 6.98615Z"
      />
   </Svg>
);

export default Icon;
