import { useEffect, useRef, useState } from "react";
import { isServer, replaceSpecialCharactersAndEmojis } from "utils";

export interface IHastagValue {
   hashTagValueError: string;
   hashTagValue: string;
}

export const switchHashTagValtoHashTagArr = (_hashTagValue: string) => {
   if (_hashTagValue.length > 0) {
      let hashTag = _hashTagValue
         .replace(/#/g, "")
         .trim()
         .split(" ")
         .filter(e => e.length > 0);
      return hashTag;
   }
   return [];
};

export const tagValidation = (val: string): string => {
   //replay regex space
   const customTagStr = val.replace(/\s+/g, "");
   if ((customTagStr.match(/#/g) ?? []).length > 10) {
      return "Nhập HashTag (tối đa 10).";
   }
   return "";
};
export const parseHashtagValue = (
   val: string
): {
   value: string;
   error: string;
} => {
   if (!val.trim()) {
      return { value: val, error: "" };
   }
   let newValue = val;
   let text = "";
   let newValueArrBySplit = val.split(" ");
   let flag = true;
   if (newValueArrBySplit.includes("")) {
      // when has a item is space
      for (let i = 0; i < newValueArrBySplit.length; i++) {
         const item = newValueArrBySplit[i];
         if (item.search("#")) {
            // If there is no # then add #
            if (item) {
               if (i == 0) {
                  //if index 0 => add #
                  newValueArrBySplit[i] = `#${item}`;
               } else {
                  //if index > 0 => add # and a space before item
                  newValueArrBySplit[i] = ` #${item}`;
               }
            } else {
               // has # and enter space by keyboard
               if (flag) {
                  // flag true => item and a space after item
                  newValueArrBySplit[i] = `${item} `;
                  flag = false;
               } else {
                  // flag true => item
                  newValueArrBySplit[i] = `${item}`;
                  flag = true;
               }
            }
         } else if (item.length > 0 && (item.match(/#/g) ?? []).length > 1) {
            // item has # > 1
            newValueArrBySplit[i] = item.split("#").join(" #");
         } else {
            // if newvalue string had # and enter space by keyboard
            if (i != 0) {
               newValueArrBySplit[i] = ` ${newValueArrBySplit[i]}`;
            }
         }
         // return text
         text = text + newValueArrBySplit[i];
      }
   } else {
      // enter newvalue or delete newvalue
      text = newValue;
      flag = true;
   }

   const validate = tagValidation(val);
   if (validate)
      return {
         error: validate,
         value: val
      };

   newValue = replaceSpecialCharactersAndEmojis(text.trimStart());
   return { value: newValue, error: "" };
};

const InputHashtag = ({
   hashtag,
   firstHashtag,
   onChange
}: {
   hashtag: IHastagValue;
   firstHashtag?: string;
   onChange: React.Dispatch<React.SetStateAction<IHastagValue>>;
}) => {
   const refInputTag = useRef<HTMLTextAreaElement>(null);

   //#regionhandle HashTagx
   //#region onBlur input tag
   const onBlur = () => {
      if (hashtag?.hashTagValueError == "") {
         onChangeInputTag(hashtag?.hashTagValue + " ");
      }
   };
   //#region check duplicate input tag
   const checkDuplicateTag = (arrayCustomTags: string[]) => {
      if (arrayCustomTags.length < 2) return false;
      const duplicateItems = arrayCustomTags.filter((item, index) => item && arrayCustomTags.indexOf(item) != index);
      if (duplicateItems.length) return true;
      return false;
   };

   //#endregion
   //#region check length input tag
   const checkLengthTag = (arrayCustomTags: string[]) => {
      for (let i = 0; i < arrayCustomTags.length; i++) {
         const tag = arrayCustomTags[i];
         if (!!tag) {
            if (tag.startsWith("#") && (tag.length <= 1 || tag.length >= 30)) {
               return false;
            }
            if (tag.length < 1 || tag.length > 30) {
               return false;
            }
         }
      }
      return true;
   };

   //#endregion
   const tagValidation = (val: string) => {
      //replay regex space
      const customTagStr = val.replace(/\s+/g, "");
      if ((customTagStr.match(/#/g) ?? []).length > 10) {
         onChange(prevState => ({
            ...prevState,
            hashTagValueError: "Nhập HashTag (tối đa 10)."
         }));
         return true;
      }
      // if (checkDuplicateTag(arrayCustomTags)) {
      //     onChange(prevState => ({
      //         ...prevState,
      //         hashTagValueError: ‘HashTag không thể được nhập nhiều lần.’
      //     }));
      //     return false;
      // }
      return false;
   };
   const onChangeInputTag = (val: string) => {
      if (!val.trim()) {
         onChange(prevState => ({
            ...prevState,
            hashTagValueError: "",
            hashTagValue: ""
         }));
         return;
      }
      let newValue = val;
      let text = "";
      let newValueArrBySplit = val.split(" ");
      let flag = true;
      if (newValueArrBySplit.includes("")) {
         // when has a item is space
         for (let i = 0; i < newValueArrBySplit.length; i++) {
            const item = newValueArrBySplit[i];
            if (item.search("#")) {
               // If there is no # then add #
               if (item) {
                  if (i == 0) {
                     //if index 0 => add #
                     newValueArrBySplit[i] = `#${item}`;
                  } else {
                     //if index > 0 => add # and a space before item
                     newValueArrBySplit[i] = ` #${item}`;
                  }
               } else {
                  // has # and enter space by keyboard
                  if (flag) {
                     // flag true => item and a space after item
                     newValueArrBySplit[i] = `${item} `;
                     flag = false;
                  } else {
                     // flag true => item
                     newValueArrBySplit[i] = `${item}`;
                     flag = true;
                  }
               }
            } else if (item.length > 0 && (item.match(/#/g) ?? []).length > 1) {
               // item has # > 1
               newValueArrBySplit[i] = item.split("#").join(" #");
            } else {
               // if newvalue string had # and enter space by keyboard
               if (i != 0) {
                  newValueArrBySplit[i] = ` ${newValueArrBySplit[i]}`;
               }
            }
            // return text
            text = text + newValueArrBySplit[i];
         }
      } else {
         // enter newvalue or delete newvalue
         text = newValue;
         flag = true;
      }
      newValue = replaceSpecialCharactersAndEmojis(text.trimStart());
      onChange(prevState => ({
         ...prevState,
         hashTagValueError: "",
         hashTagValue: newValue
      }));
      if (tagValidation(val)) return;
   };

   const onAddHashTag = () => {
      if (hashtag?.hashTagValue?.length === 0) {
         onChange(prevState => ({
            ...prevState,
            hashTagValue: "#"
         }));
      } else {
         onChangeInputTag(`${hashtag?.hashTagValue?.trimEnd()} #`);
      }
      refInputTag?.current?.focus();
      // if (hashtag?.hashTagValue?.length > 0) {
      //    refInputTag?.current?.focus();
      // } else {
      //    setTimeout(() => {
      //       refInputTag?.current?.focus();
      //    }, 0);
      // }
   };
   //#endregion handle hashTag

   const inputHeight = hashtag?.hashTagValue || firstHashtag ? "auto" : "0px";
   useEffect(() => {
      if (inputHeight === "0px") {
         refInputTag?.current?.blur();
      }
   }, [inputHeight]);

   return (
      <div className="wrap-hastag">
         <div
            className={`wrap-hashtag-input  ${hashtag.hashTagValueError ? "error" : ""}`}
            style={{ width: inputHeight, height: inputHeight, opacity: inputHeight === "0px" ? 0 : 1 }}
         >
            {firstHashtag && (
               <div className="first-hashtag">
                  <span>#</span>
                  {firstHashtag}
               </div>
            )}
            <textarea
               id="feed-hashtag"
               ref={refInputTag}
               className={`hashtag-input-text`}
               onInput={e => {
                  e.currentTarget.style.height = "";
                  e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
               }}
               value={hashtag?.hashTagValue}
               onBlur={onBlur}
               onChange={e => {
                  onChangeInputTag(e.target.value);
               }}
            />
         </div>
         {hashtag.hashTagValueError && <div className="error-text">{hashtag.hashTagValueError}</div>}
         <button className="btn-add-hashtag" type="button" onClick={onAddHashTag}>
            #Thêm hashtag
         </button>
      </div>
   );
};

export default InputHashtag;
