/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Model(s)
 */
import { Product } from "./product.model";

/**
 * Config(s)
 */
// import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import { Model, IModel, ModelError, TModelError, TPayload } from "./model";
import { apiDomain } from "./__variables";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete"
}

export enum EFilter {
   location = "location",
   common = "common",
   color = "color",
   space = "space",
   designType = "designType",
   articleType = "articleType",
   interiorStyle = "interiorStyle",
   typeOfConstruction = "typeOfConstruction",
   price = "price",
   acreage = "acreage",
   fromPrice = "fromPrice",
   toPrice = "toPrice",
   fromAcreage = "fromAcreage",
   toAcreage = "toAcreage"
}

export enum EType {
   common = "common",
   color = "color",
   space = "space",
   designType = "designType",
   articleType = "articleType",
   interiorStyle = "interiorStyle",
   typeOfConstruction = "typeOfConstruction"
}

export const enum EActualWorkConstructionTypes {
   apartment = "apartment",
   townhouse = "townhouse",
   villa = "villa",
   duplex = "duplex",
   other = "other"
}

export const projectType = [
   {
      title: "Căn hộ chung cư",
      value: EActualWorkConstructionTypes?.apartment,
      slug: "can-ho-chung-cu"
   },
   {
      title: "Nhà phố",
      value: EActualWorkConstructionTypes?.townhouse,
      slug: "nha-pho"
   },
   {
      title: "Biệt thự",
      value: EActualWorkConstructionTypes?.villa,
      slug: "biet-thu"
   },
   {
      title: "Duplex",
      value: EActualWorkConstructionTypes?.duplex,
      slug: "duplex"
   },
   {
      title: "Khác",
      value: EActualWorkConstructionTypes?.other,
      slug: "khac"
   }
];

export const typeTextVN = {
   [EType.common]: "",
   [EType.color]: "mau",
   [EType.space]: "loai-phong",
   [EType.designType]: "phong-cach-thiet-ke",
   [EType.articleType]: "loai-bai-viet",
   [EType.interiorStyle]: "phong-cach",
   [EType.typeOfConstruction]: "loai-cong-trinh",
   [EFilter.price]: "gia",
   [EFilter.fromPrice]: "tu-gia",
   [EFilter.toPrice]: "den-gia",
   [EFilter.acreage]: "dien-tich",
   [EFilter.fromAcreage]: "tu-dien-tich",
   [EFilter.toAcreage]: "den-dien-tich",
   [EFilter.location]: "dia-diem"
};

export type TListRoom = {
   [type in EType]: Keyword[];
};
export interface IKeyword extends IModel {
   ["info"]?: {
      ["content"]?: string | null;
      ["alphabetOnly"]?: string | null;
      ["slug"]: string;
      ["description"]?: string;
   };
   ["options"]?: {
      ["status"]?: EStatus;
      ["type"]?: EType;
   };
   ["relatedInfo"]?: {
      ["hexCode"]?: string;
      ["borderHexCode"]?: string;
      ["imageUrl"]?: string;
   };
}

export class Keyword extends Model<IKeyword> implements IKeyword {
   public ["info"]!: {
      ["content"]?: string | null;
      ["alphabetOnly"]?: string | null;
      ["slug"]: string;
      ["description"]?: string;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
      ["type"]?: EType;
   };

   public ["relatedInfo"]?: {
      ["hexCode"]?: string;
      ["borderHexCode"]?: string;
      ["imageUrl"]?: string;
   };

   public ["__products"]!: Product[];

   constructor(data?: object) {
      super();

      Object.assign(this, data);
   }

   /**
    *
    * @param keywords
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginate(
      keywords?: string,
      page: number = 1,
      limit: number = 50,
      sort: "desc" | "asc" = "desc",
      sortBy: "createdAt" | "info.name" = "createdAt"
   ) {
      // try {
      //     if (!token) {
      //         return {
      //             ["code"]: 401,
      //             ["data"]: "Unauthorized"
      //         } as unknown as IResult<string>;
      //     }
      //     const res = await fetch(`${apiDomain}/console/keyword/paginate`, {
      //         ["method"]: "GET",
      //         ["headers"]: {
      //             ["X-Requested-With"]: "XMLHttpRequest",
      //             ["Authorization"]: `Bearer ${token}`,
      //             ["page"]: String(page),
      //             ["limit"]: String(limit),
      //             ["sort"]: sort,
      //             ["sort-by"]: sortBy,
      //             ...!keywords ? undefined : {
      //                 ["keywords"]: encodeURI(keywords)
      //             }
      //         }
      //     });
      //     if (!res.ok) {
      //         console.error(await res.json());
      //         return {
      //             ["code"]: res.status,
      //             ["data"]: res.statusText
      //         } as IResult<string>;
      //     }
      //     const payload = await res.json() as IPayload<{
      //         ["paginator"]: IPaginate<IKeyword>
      //     }>;
      //     if (!payload?.data?.paginator) {
      //         return {
      //             ["code"]: 409,
      //             ["data"]: "Payload data not found."
      //         } as IResult<string>;
      //     }
      //     payload.data.paginator.docs = payload.data.paginator.docs?.map(keywordsJSON => new Keyword(keywordsJSON)) as Keyword[];
      //     return {
      //         ["code"]: 200,
      //         ["data"]: payload.data.paginator
      //     } as IResult<IPaginate<Keyword>>;
      // }
      // catch (error: any) {
      //     console.error(error);
      //     return {
      //         ["code"]: 500,
      //         ["data"]: error.message
      //     } as IResult<string>
      // }
   }

   /**
    *
    * @param id
    * @returns
    */
   public static async getProductKeywordsById(id: string) {
      // try {
      //     if (!token) {
      //         return {
      //             ["code"]: 401,
      //             ["data"]: "Unauthorized"
      //         } as unknown as IResult<string>;
      //     }
      //     const res = await fetch(`${apiDomain}/console/product/keywords`, {
      //         ["method"]: "GET",
      //         ["headers"]: {
      //             ["X-Requested-With"]: "XMLHttpRequest",
      //             ["Authorization"]: `Bearer ${token}`,
      //             ["id"]: encodeURI(id)
      //         }
      //     });
      //     if (!res.ok) {
      //         console.error(await res.json());
      //         return {
      //             ["code"]: res.status,
      //             ["data"]: res.statusText
      //         } as IResult<string>;
      //     }
      //     const payload = await res.json() as IPayload<{
      //         ["productKeywords"]: IKeyword[]
      //     }>;
      //     if (!payload?.data?.productKeywords) {
      //         return {
      //             ["code"]: 409,
      //             ["data"]: "Payload data not found."
      //         } as IResult<string>;
      //     }
      //     payload.data.productKeywords = payload.data.productKeywords.map(keywordJSON => new Keyword(keywordJSON));
      //     return {
      //         ["code"]: 200,
      //         ["data"]: payload.data.productKeywords
      //     } as IResult<Keyword[]>;
      // }
      // catch (error: any) {
      //     console.error(error);
      //     return {
      //         ["code"]: 500,
      //         ["data"]: error.message
      //     } as IResult<string>
      // }
   }

   /**
    *
    * @param name
    * @returns
    */
   public static async createKeyword(content: string) {
      // try {
      //     if (!token) {
      //         return {
      //             ["code"]: 401,
      //             ["data"]: "Unauthorized"
      //         } as unknown as IResult<string>;
      //     }
      //     const res = await fetch(`${apiDomain}/console/keyword`, {
      //         method: 'POST',
      //         headers: new Headers({
      //             ["X-Requested-With"]: "XMLHttpRequest",
      //             ["Authorization"]: `Bearer ${token}`,
      //             ["Content-Type"]: "application/json"
      //         }),
      //         body: JSON.stringify({
      //             ["info"]: {
      //                 ["content"]: content,
      //             }
      //         })
      //     });
      //     if (!res.ok) {
      //         console.error(await res.json());
      //         return {
      //             ["code"]: res.status,
      //             ["data"]: res.statusText
      //         } as IResult<string>;
      //     }
      //     const payload = await res.json() as IPayload<{
      //         ["newKeyword"]: IKeyword
      //     }>;
      //     if (!payload?.data?.newKeyword) {
      //         return {
      //             ["code"]: 409,
      //             ["data"]: "Payload data not found."
      //         } as IResult<string>;
      //     }
      //     payload.data.newKeyword = new Keyword(payload.data.newKeyword)
      //     return {
      //         ["code"]: 200,
      //         ["data"]: payload.data.newKeyword
      //     } as IResult<Keyword>;
      // }
      // catch (error: any) {
      //     console.error(error);
      //     return {
      //         ["code"]: 500,
      //         ["data"]: error.message
      //     } as IResult<string>
      // }
   }

   /**
    *
    * @param types
    * @returns
    */
   public static async getKeywordsByType(types: string[], sortBy: "createdAt" = "createdAt", sort?: "desc" | "asc") {
      try {
         const response = await fetch(`${apiDomain}/www/keyword/by-types`, {
            ["method"]: "GET",
            // ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["types"]: encodeURI(types.toString())
               // "sort": sort ?? "desc",
               // "sort-by": sortBy,
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["keywordGroupsByType"]: TListRoom;
         }>;

         return payload.data.keywordGroupsByType;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
   public static async getDescriptionByRoom(roomSlug: string) {
      try {
         const response = await fetch(`${apiDomain}/www/keyword/by-url`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["url"]: roomSlug.toString()
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         return payload.data.description;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
}

export default Keyword;
