/**
 * Model(s)
 */
import Article from "./article.model";
import Media from "./modelMedia.model";

export enum EArticleMediaStatus {
   enable = "enable",
   disable = "disable"
}

export enum EArticleMediaStorageProvider {
   Amazon = "amazon",
   Microsoft = "microsoft",
   Others = "others"
}

export interface IArticleMedia extends Media {
   ["articleId"]: string | null;
   ["__article"]?: Article | null;
}

export class ArticleMedia extends Media<IArticleMedia> implements IArticleMedia {
   public ["articleId"]!: string | null;
   public ["__article"]?: Article | null;

   constructor(data?: IArticleMedia) {
      super(data);

      Object.assign(this, data);
   }
}

export default ArticleMedia;
