import React from "react";
import { EStep } from "../const";

interface IProps {
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
}

const ForgotPasswordStep4: React.FC<IProps> = ({ setStep }) => {
   const historyPush = () => {
      setStep({ step: EStep.signinStep2 });
   };

   return (
      <div className="register-email-success">
         <div className="register-success-img">
            <img src="/assets/img/auth/forgot-success.png" alt="" />
         </div>
         <h1 className="register-success-title">Đổi mật khẩu thành công</h1>
         <p className="register-success-message">Mật khẩu đã được thay đổi, vui lòng quay lại đăng nhập.</p>

         <button className="btn btn-auth" style={{ backgroundColor: "#FF6347" }} onClick={historyPush}>
            Đăng nhập
         </button>
      </div>
   );
};

export default ForgotPasswordStep4;
