import { Drawer } from "@mui/material";
import classNames from "classnames";
import styles from "./_scss/index.module.scss";
import React, { useEffect, useState } from "react";
import SearchContent from "./components/SearchContent";
import { useRouter } from "next/router";
import { ISearchSuggetions, SearchModel } from "models/search.model";
import { tokenKey } from "models/__variables";
import { ModelError } from "models/model";

interface IState {
   searchQueue?: any;
   searchKeywords?: string;
   searchResult?: ISearchSuggetions;
}

export default function DrawerSearch({ open, onClose, onSearch }: { open?: boolean; onClose?: () => void; onSearch?: (v: any) => void }) {
   const _searchRef = React.useRef<HTMLInputElement>(null);
   const router = useRouter();

   const [state, setCurrState] = useState<IState>({
      searchQueue: null,
      searchKeywords: (router?.query?.data || "") as string,
      searchResult: {
         histories: [],
         suggestions: [],
         limit: 5,
      },
   });
   function setState(data: IState) {
      setCurrState((prev) => ({
         ...prev,
         ...data,
      }));
   }

   useEffect(() => {
      SearchModel.fetchSuggetions({ limit: "5" }).then((res) => {
         if (res instanceof ModelError) {
            return;
         }
         setCurrState({
            searchResult: res,
         });
      });
   }, []);

   const saveKeywords = async (keywords?: string) => {
      if (!keywords) return;
      const token = localStorage.getItem(tokenKey);
      if (token) {
         await SearchModel.saveKeywords({ keywords });
      }
   };

   const handleClickSearch = async (searchValue: string) => {
      await saveKeywords(searchValue);
      onSearch?.(searchValue);

      const asPath = router.asPath;
      switch (asPath) {
         case (asPath.match(/^(\/shop(.*))$/) || {})?.input:
            router.push(`/search/shop?data=${searchValue}`);
            break;

         // at search checking
         case (asPath.match(/^(\/search\/feed(.*))$/) || {})?.input:
            router.push(`/search/feed?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/user(.*))$/) || {})?.input:
            router.push(`/search/user?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/anh(.*))$/) || {})?.input:
            router.push(`/search/anh?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/tat-ca-du-an(.*))$/) || {})?.input:
            router.push(`/search/tat-ca-du-an?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/thu-vien-thiet-ke(.*))$/) || {})?.input:
            router.push(`/search/thu-vien-thiet-ke?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/cong-trinh-thuc-te(.*))$/) || {})?.input:
            router.push(`/search/cong-trinh-thuc-te?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/to-am-cua-toi(.*))$/) || {})?.input:
            router.push(`/search/to-am-cua-toi?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/blog(.*))$/) || {})?.input:
            router.push(`/search/blog?data=${searchValue}`);
            break;
         case (asPath.match(/^(\/search\/shop(.*))$/) || {})?.input:
            router.push(`/search/shop?data=${searchValue}`);
            break;
         default:
            router.push(`/search?data=${searchValue}`);
            break;
      }
   };

   const fetchAllSuggestions = async (keywords?: string) => {
      const result = await SearchModel.fetchSuggetions({ keywords, limit: "5" });
      if (result instanceof ModelError) return;
      setState({
         searchResult: result,
      });
   };

   const onInputSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
         const timeOut = 200;
         const keywords = e.target.value;

         const handler = async () => {
            try {
               await fetchAllSuggestions(keywords);
            } catch (error) {
               console.error(error);
            }
         };
         // Clear timeout if queue pending
         if (state.searchQueue) {
            window.clearTimeout(state.searchQueue);
         }

         // Reset new timeout
         setState({
            ["searchKeywords"]: keywords,
            ["searchQueue"]: window.setTimeout(handler, timeOut),
         });
      } catch (error) {
         console.error(error);
      }
   };

   return (
      <Drawer anchor="right" PaperProps={{ style: { width: "100%" } }} open={open} onClose={onClose}>
         <div className={classNames([styles["cart-detail"], "d-flex flex-column"])}>
            <div className={classNames([styles["search-mb"]])}>
               <div className={classNames([styles["search-input"]])}>
                  <div className={classNames(["col-auto", styles["img-close"]])} onClick={onClose}>
                     <svg width="12" height="19" viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M0.917969 9.49609C0.917969 9.62891 0.941406 9.75 0.988281 9.85938C1.03516 9.96875 1.10938 10.0703 1.21094 10.1641L10.0234 18.6953C10.1953 18.8672 10.4102 18.9531 10.668 18.9531C10.8398 18.9531 10.9922 18.9141 11.125 18.8359C11.2656 18.7656 11.375 18.6602 11.4531 18.5195C11.5391 18.3789 11.582 18.2227 11.582 18.0508C11.582 17.8008 11.4961 17.5859 11.3242 17.4062L3.14453 9.49609L11.3242 1.59766C11.4961 1.41016 11.582 1.19531 11.582 0.953125C11.582 0.773438 11.5391 0.617187 11.4531 0.484375C11.375 0.34375 11.2656 0.234375 11.125 0.15625C10.9922 0.078125 10.8398 0.0390625 10.668 0.0390625C10.4102 0.0390625 10.1953 0.128906 10.0234 0.308594L1.21094 8.83984C1.10938 8.93359 1.03516 9.03516 0.988281 9.14453C0.941406 9.25391 0.917969 9.37109 0.917969 9.49609Z"
                           fill="#666666"
                        />
                     </svg>
                  </div>
                  <div className={classNames(["col", styles["search-form"]])}>
                     <form
                        className={classNames(["align-items-center flex-nowrap gx-0", styles["search-box"]])}
                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                           e.preventDefault();
                           if (!_searchRef.current?.value) {
                              return;
                           }
                           handleClickSearch(_searchRef.current?.value);
                           _searchRef.current.blur();
                           // Clear timeout if queue pending
                           if (state.searchQueue) {
                              window.clearTimeout(state.searchQueue);
                           }
                           if (open) {
                              onClose?.();
                           }
                        }}
                     >
                        <input
                           type="text"
                           className={classNames(["col", styles["input"]])}
                           placeholder="Tìm kiếm"
                           autoComplete="off"
                           minLength={2}
                           ref={_searchRef}
                           value={state.searchKeywords}
                           onChange={onInputSearchChange}
                           // onBlur={() => {
                           //    setTimeout(() => {
                           //       setState({
                           //          toggleSearchResult: false
                           //       });
                           //    }, 300);
                           // }}
                           required
                        />
                        <button type="submit" className={styles["button"]} title="Tìm sản phẩm">
                           <img src="/assets/img/icon/search-icon-header-desktop.svg" alt="Tìm sản phẩm" />
                        </button>
                     </form>
                  </div>
               </div>
               <div className={classNames([styles["main"]])}>
                  {state.searchResult && (
                     <SearchContent
                        keywords={state.searchKeywords}
                        searchResult={state.searchResult}
                        onDeleteAllHistories={() => fetchAllSuggestions()}
                        onClickSearch={handleClickSearch}
                     />
                  )}
                  {/* {state.searchResult?.histories.map((doc, i) => {
                  return (
                     <div
                        className={classNames(["row align-items-center gx-0 mb-2", styles["result"]])}
                        key={i}
                        onClick={() => {
                           props.router.push(`/shop/${doc.info.slug}`);
                           if (open) {
                              setState({
                                 isOpenDrawer: false
                              });
                           }
                        }}
                     >
                        <div className={classNames(["col-4", styles["img"]])}>
                           <img src={doc.__images[0].$url ?? ""} alt={doc.info.title ?? ""} title={doc?.info?.title ?? ""} />
                        </div>
                        <div className={classNames(["col-8", styles["name"]])}>
                           <p>{doc.info.title ?? ""}</p>
                        </div>
                     </div>
                  );
               })} */}
                  {/* {!state.searchResult?.totalDocs ? null : (
                  <div className="row">
                     <div className="col-12 d-flex justify-content-center align-items-center" onClick={handleClick.bind(this)}>
                        <p>
                           <span>Xem thêm </span>
                           <span>{state.searchResult.totalDocs ?? ""}</span>
                           <span> sản phẩm</span>
                        </p>
                     </div>
                  </div>
               )} */}
               </div>
            </div>
         </div>
      </Drawer>
   );
}
