/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";
import Product from "./product.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete"
}

export enum ETax {
   enable = "enable",
   disable = "disable"
}

export enum EStockStatus {
   Stocking = "stocking",
   OutStock = "outstock",
   Unknown = "unknown"
}

export enum EProductAttr {
   Color = "Color",
   Size = "Size",
   Material = "Material"
}
export interface IProductVariantInfo {
   ["name"]?: EProductAttr;
   ["sku"]?: string;
   ["price"]?: number;
   ["quantity"]?: number;
   ["option01"]?: string;
   ["option02"]?: string;
   ["option03"]?: string;
   ["priceAfterDiscount"]?: number;
}

export interface IProductVariant extends Model {
   ["info"]: IProductVariantInfo;
   ["options"]: {
      ["status"]?: EStatus;
      ["tax"]?: ETax;
      ["stockStatus"]?: EStockStatus;
   };
   ["productId"]: string | null;
   ["mediaUrl"]?: string;
   ["thumbnailUrl"]?: string;

   ["__product"]?: Product | null;
   // ["$price"]?: any | null;
   // ["$priceAfterDiscount"]?: any | null;
}

export default class ProductVariant extends Model<IProductVariant> implements IProductVariant {
   public ["info"]: IProductVariantInfo;
   public ["options"]!: {
      ["status"]?: EStatus;
      ["tax"]?: ETax;
      ["stockStatus"]?: EStockStatus;
   };
   public ["productId"]!: string | null;
   public ["mediaUrl"]?: string;
   public ["thumbnailUrl"]?: string;

   public ["__product"]?: Product;

   constructor(data?: IProductVariant) {
      super(data);

      Object.assign(this, data);

      if (this.__product) {
         this.__product = new Product(this.__product);
      }
      // const getPrice = (locales: string | string[] | undefined = "vn", options?: Intl.NumberFormatOptions | undefined) => {
      //     return !this?.info?.price ? "" : new Intl.NumberFormat(locales, options).format(this.info.price) + '₫';
      // }
      // const getPriceAfterDiscount = (locales: string | string[] | undefined = "vn", options?: Intl.NumberFormatOptions | undefined) => {
      //     return !this?.info?.priceAfterDiscount ? "" : new Intl.NumberFormat(locales, options).format(this.info.priceAfterDiscount) + '₫';
      // }
      // this.$price = getPrice();
      // this.$priceAfterDiscount = getPriceAfterDiscount();
   }

   public static async getProductVariantById(id: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product/variant`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: id
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["productVariant"]: IProductVariant;
         }>;

         return new ProductVariant(payload.data.productVariant);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async getProductVariantByIds(ids: string[]) {
      try {
         const response = await fetch(`${apiDomain}/www/product/variants/by-ids`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["ids"]: ids.toString()
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["productVariants"]: IProductVariant[];
         }>;

         return payload.data.productVariants.map(variantJSON => new ProductVariant(variantJSON));
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async getProductVariantsByProductId(productId: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product/variants`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: productId
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["productVariants"]: IProductVariant[];
         }>;

         return payload.data.productVariants.map(variantJSON => new ProductVariant(variantJSON));
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async getVariantInCart(variantId: string) {
      try {
         const response = await fetch(`${apiDomain}/www/product/variant/cart`, {
            ["method"]: "GET",
            ["cache"]: "no-store",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["id"]: variantId
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["cartVariant"]: IProductVariant;
         }>;

         return new ProductVariant(payload.data.cartVariant);
      } catch (error) {
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   // public $price(locales: string | string[] | undefined = "vn", options?: Intl.NumberFormatOptions | undefined) {
   //     return !this?.info?.price ? "" : new Intl.NumberFormat(locales, options).format(this.info.price) + '₫';
   // }
   // public $priceAfterDiscount(locales: string | string[] | undefined = "vn", options?: Intl.NumberFormatOptions | undefined) {
   //     return !this?.info?.priceAfterDiscount ? "" : new Intl.NumberFormat(locales, options).format(this.info.priceAfterDiscount) + '₫';
   // }
   // get $persentDiscount() {
   //     return (100 - ((this.info.priceAfterDiscount ? this.info.priceAfterDiscount * 100 : 0) / (this.info.price ? this.info.price : 0)))
   // }
}
