interface Firebase {
   appId: string;
   apiKey: string;
   projectId: string;
   authDomain: string;
   measurementId: string;
   storageBucket: string;
   messagingSenderId: string;
   FIREBASE_USEPUBLICVAPIDKEY: string;
}

const config: Firebase = {
   apiKey: process.env.REACT_APP_FIREBASE_API_KEY as string,
   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDER_ID as string,
   appId: process.env.REACT_APP_FIREBASE_APP_ID as string,
   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
   FIREBASE_USEPUBLICVAPIDKEY: process.env.REACT_APP_FIREBASE_USERPUBLIC_VAPIDKEY as string
};

export const cacheFirebaseLocalToken = "firebaseToken";

export default config
