import { IUTMParams } from "../reducer/utmParams";
import { UTM_PARAMS } from "../type";

export function utmParams(data: IUTMParams) {
   return {
      type: UTM_PARAMS,
      payload: data
   };
}




