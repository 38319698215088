/* eslint-plugin-disable react */
import { isEmpty } from "utils";
import { apiDomain, appDomain, tokenKey } from "./__variables";
/*eslint-disable */

export const errorCode = {
   200: {
      code: 200,
      message: "Success",
   },
   401: {
      code: 401,
      message: "Not found",
   },
};

export enum ESort {
   desc = "desc",
   asc = "asc",
}
export enum ESortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt",
   viewCount = "info.viewCount",
   price = "price",
}

const errorAuth = {
   httpCode: 401,
   message: "401 Unauthorized error",
   code: 401,
};

export const initPaginate: TPaginate<any> = {
   ["totalDocs"]: 0,
   ["totalPages"]: 1,
   ["totalRefDocs"]: 1,
   ["currentPage"]: 1,
   ["docs"]: [],
   // ["nextPage"]: null,
   // ["previousPage"]: null,
   // ["limit"]: null
};

export type TPaginate<T> = {
   ["docs"]: T[];
   ["totalDocs"]: number;
   ["totalPages"]: number;
   ["totalRefDocs"]: number;
   ["currentPage"]: number;
   ["nextPage"]?: number;
   ["previousPage"]?: number;
   ["limit"]?: number;
};
export class Paginate<T extends Model, K> implements TPaginate<T> {
   public readonly ["totalDocs"]: number;
   public readonly ["totalPages"]: number;
   public readonly ["currentPage"]: number;
   public readonly ["totalRefDocs"]: number;
   public readonly ["docs"]: T[];
   public readonly ["nextPage"]?: number;
   public readonly ["previousPage"]?: number;
   public readonly ["limit"]?: number;

   constructor(data?: TPaginate<K>, type?: { new(data?: K): T }) {
      Object.assign(this, {
         ...initPaginate,
         ...data,
         ...data?.docs && type ? {
            ["docs"]: data.docs.map((doc) => new type(doc))
         } : undefined,
      });

      if (isEmpty(data?.totalDocs)) {
         this.totalDocs = data?.docs?.length || 0;
      }
   }
}

export type TPayload<T = any> = {
   ["httpCode"]: number;
   ["message"]: string;
   ["data"]: T;
};

export class Payload<T = any> implements TPayload<T> {
   public readonly ["httpCode"]!: number;
   public readonly ["message"]!: string;
   public readonly ["data"]!: T;
   constructor(data: TPayload) {
      Object.assign(this, data);
   }
}

export type TModelError = {
   ["httpCode"]: number;
   ["message"]: string;
   ["firstMessageErr"]?: string;
   ["error"]?: any;
   ["errors"]?: {
      [key: string]: {
         ["code"]?: string | number;
         ["message"]?: string;
      }[];
   };
   ["mapErrors"]?: {
      [key: string]: string;
   };
};

export class ModelError implements TModelError {
   public readonly ["httpCode"]!: number;
   public readonly ["message"]!: string;
   public readonly ["firstMessageErr"]?: string;
   public readonly ["error"]?: any;
   public readonly ["errors"]: {
      [key: string]: {
         ["code"]: string | number;
         ["message"]: string;
      }[];
   };
   public mapErrors!: {
      [key: string]: string;
   };

   constructor(data: TModelError) {
      Object.assign(this, data);

      let message = data?.message || "Có lỗi xảy ra, vui lòng thử lại sau.!";
      for (let item of Object.values(this.errors ?? {})) {
         if (item?.[0]?.message) {
            message = item[0].message;
            break;
         }
      }
      this.firstMessageErr = message;

      if (this.errors) {
         let objError = {};
         Object.entries(this.errors || {}).forEach(([key, value]) => {
            if (value?.[0]?.message) {
               objError[key] = value?.[0]?.message || "";
            }
         });
         this.mapErrors = objError;
      }
   }

   static async notFound(key: string, message?: string, code?: number) {
      return new ModelError({
         httpCode: 400,
         message: message ?? "",
         errors: {
            [key]: [{ code, message }],
         },
      });
   }
}

export interface IModel {
   ["_id"]?: string;
   ["createdAt"]?: string;
   ["updatedAt"]?: string;
   ["__v"]?: number;
   ["$createdAt_DDMMYYYY"]?: any | null;
   ["$updatedAt_DDMMYYY"]?: any | null;
}

export class Model<T extends IModel = IModel> {
   public readonly ["_id"]!: string;
   public readonly ["createdAt"]!: string;
   public ["updatedAt"]!: string;
   public ["__v"]?: number;
   public ["$createdAt_DDMMYYYY"]?: any | null;
   public ["$updatedAt_DDMMYYY"]?: any | null;

   // protected readonly __default__?: T;

   constructor(data?: T) {
      if (!data) {
         return;
      }

      // this.__default__ = Object.freeze(JSON.parse(JSON.stringify(data)));
      const getDDMMYYYY = () => {
         // const createdTime = new Date(this.__default__?.createdAt ?? "");
         const createdTime = new Date(data.createdAt ?? "");
         const date = createdTime.getDate() < 10 ? `0${createdTime.getDate()}` : createdTime.getDate();
         const month = createdTime.getMonth() < 9 ? `0${createdTime.getMonth() + 1}` : createdTime.getMonth() + 1;
         return `${date}-${month}-${createdTime.getFullYear()}`;
      };
      this.$updatedAt_DDMMYYY = () => {
         if (!this.createdAt) {
            return null;
         }

         const updatedAt = new Date(this.updatedAt ?? "");
         // const hours = updatedAt.getHours() < 10 ? `0${updatedAt.getHours()}` : updatedAt.getHours();
         // const minutes = updatedAt.getMinutes() < 10 ? `0${updatedAt.getMinutes()}` : updatedAt.getMinutes();
         const date = updatedAt.getDate() < 10 ? `0${updatedAt.getDate()}` : updatedAt.getDate();
         const month = updatedAt.getMonth() < 9 ? `0${updatedAt.getMonth() + 1}` : updatedAt.getMonth() + 1;
         return `${date}/${month}/${updatedAt.getFullYear()}`;
      };
      this.$createdAt_DDMMYYYY = data.createdAt ? getDDMMYYYY() : null;
   }

   private static returnError(error: any) {
      console.error(error);
      return new ModelError({
         httpCode: 500,
         message: error.message as string,
         errors: {
            process: [
               {
                  code: "process.error.5000",
                  message: "Process error on handling.",
               },
            ],
         },
      });
   }

   private static returnAuth() {
      return new ModelError({
         httpCode: 401,
         message: "401 Unauthorized error",
         errors: {
            process: [
               {
                  code: "401",
                  message: "401 Unauthorized error",
               },
            ],
         },
      });
   }
   public static async fetchGet(
      request: { [key: string]: string } | null,
      headers: { [key: string]: any },
      query: string,
      isRequiredAuthorization: boolean = false
   ) {
      const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
      if (isRequiredAuthorization && !token) {
         return this.returnAuth();
      }
      try {
         const response = await fetch(`${apiDomain}/www/${query}`, {
            method: "GET",
            cache: "default",
            headers: {
               ["Origin"]: "https://spacet.vn",
               "X-Requested-With": "XMLHttpRequest",
               ...(!token ? {} : { Authorization: `Bearer ${token}` }),
               ...headers,
            },
            ...(!request ? {} : request),
         });

         if (!response.ok) {
            throw (await response.json()) as TModelError;
         }

         return response;
      } catch (error) {
         return this.returnError(error);
      }
   }
   public static async fetchPost(
      request: { [key: string]: string } | null,
      headers: { [key: string]: string } | null,
      body: { [key: string]: string },
      query: string,
      isRequiredAuthorization: boolean = false
   ) {
      const token = localStorage.getItem(tokenKey);
      if (isRequiredAuthorization && !token) {
         return this.returnAuth();
      }
      try {
         const response = await fetch(`${apiDomain}/www/${query}`, {
            method: "POST",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               Authorization: `Bearer ${token}`,
               "Content-Type": "application/json",
               ...(!headers ? {} : headers),
            },
            ...(!request ? {} : request),
            body: JSON.stringify(body),
         });

         if (!response.ok) {
            throw (await response.json()) as TModelError;
         }
         return response;
      } catch (error) {
         return this.returnError(error);
      }
   }
   public static async fetchDelete(
      request: { [key: string]: string } | null,
      headers: { [key: string]: string } | null,
      query: string,
      isRequiredAuthorization: boolean = false
   ) {
      const token = localStorage.getItem(tokenKey);
      if (!token && isRequiredAuthorization) {
         return this.returnAuth();
      }
      try {
         const response = await fetch(`${apiDomain}/www/${query}`, {
            method: "DELETE",
            headers: {
               "X-Requested-With": "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               "Content-Type": "application/json",
               ...(!headers ? {} : headers),
            },
            ...(!request ? {} : request),
         });

         if (!response.ok) {
            throw (await response.json()) as TModelError;
         }
         return response;
      } catch (error) {
         return this.returnError(error);
      }
   }
   public static async fetchData({
      method,
      headers,
      body,
      url,
      userIp,
      endPoint,
      isRequiredAuthorization = false,
      token,
   }: {
      method: "GET" | "POST" | "DELETE" | "PATCH";
      headers?: { [key: string]: string } | null;
      body?: { [key: string]: any } | Model | null;
      userIp?: string;
      url?: string;
      endPoint?: string;
      isRequiredAuthorization?: boolean;
      token?: string;
   }) {
      const clientToken = token ? token : typeof window !== "undefined" ? localStorage.getItem(tokenKey) : undefined;
      if (isRequiredAuthorization && !clientToken) {
         return this.returnError(errorAuth);
      }
      try {
         const response = await fetch(url ? url : `${apiDomain}/www/${endPoint}`, {
            method: method,
            headers: {
               ["Origin"]: appDomain,
               "X-Requested-With": "XMLHttpRequest",
               "Content-Type": "application/json",
               ...(!userIp ? undefined : { ["x-secret-for"]: userIp }),
               ...(!clientToken ? {} : { Authorization: `Bearer ${clientToken}` }),
               ...(!headers ? {} : headers),
            },
            ...(!body ? {} : { body: JSON.stringify(body) }),
         });
         if (!response.ok) {
            throw (await response.json()) as TModelError;
         }
         return response
      } catch (error) {
         return new ModelError(error);
      }
   }
   public static async fetch<T>({
      method,
      headers,
      body,
      url,
      userIp,
      endPoint,
      isRequiredAuthorization = false,
      token,
   }: {
      method: "GET" | "POST" | "DELETE" | "PATCH";
      headers?: { [key: string]: string } | null;
      body?: BodyInit;
      url?: string;
      userIp?: string;
      endPoint?: string;
      isRequiredAuthorization?: boolean;
      token?: string;
   }) {
      const clientToken = token ? token : typeof window !== "undefined" ? localStorage.getItem(tokenKey) : undefined;
      if (isRequiredAuthorization && !clientToken) {
         return this.returnError(errorAuth);
      }
      try {
         const response = await fetch(url ? url : `${apiDomain}/www/${endPoint}`, {
            method: method,
            headers: {
               ["Origin"]: appDomain,
               "X-Requested-With": "XMLHttpRequest",
               ...((!body || typeof body === "string") ? { "Content-Type": "application/json" } : {}),
               ...(!userIp ? undefined : { ["x-secret-for"]: userIp }),
               ...(!clientToken ? {} : { Authorization: `Bearer ${clientToken}` }),
               ...(!headers ? {} : headers),
            },
            ...(!body ? {} : { body }),
         });
         if (response.status === 404) {
            return new ModelError({
               httpCode: 404,
               message: "Api not found",
            });
         }
         if (!response.ok) {
            const res = await response.json()
            return res as ModelError;
         }
         const res = await response.json() as TPayload<T>;
         return res
      } catch (error) {
         return new ModelError(error);
      }
   }
   public static priceFormat(value: number, locales: string | string[] | undefined = "vn", options?: Intl.NumberFormatOptions | undefined) {
      return new Intl.NumberFormat(locales, options).format(value) + "₫";
   }
}

export default Model;
