import React from "react";
import Svg from "../Svg";

const Icon = props => (
   <Svg viewBox="0 0 16 16" fill="none" {...props}>
      <ellipse cx="8.5624" cy="8.03532" rx="6.55458" ry="7.03532" fill="#BFA01D" />
      <ellipse cx="7.55458" cy="8.03532" rx="6.55458" ry="7.03532" fill="#FFEAAD" />
      <ellipse cx="7.55421" cy="8.03451" rx="5.24367" ry="5.62826" fill="#FFD630" />
      <path
         d="M9.65197 9.45815C9.65197 9.76329 9.57974 10.0501 9.43529 10.3186C9.29661 10.5872 9.0886 10.813 8.81125 10.9961C8.53967 11.1792 8.2161 11.289 7.84052 11.3256V12.0671H7.27715V11.3256C6.734 11.2707 6.29486 11.0907 5.95973 10.7855C5.6246 10.4804 5.45703 10.0776 5.45703 9.57715H6.51443C6.53177 9.81517 6.60399 10.0135 6.73111 10.1722C6.85823 10.3248 7.04024 10.4224 7.27715 10.4651V8.45118C6.89001 8.34743 6.5751 8.24368 6.33242 8.13993C6.08974 8.03008 5.88173 7.8592 5.70838 7.6273C5.54081 7.39539 5.45703 7.07804 5.45703 6.67525C5.45703 6.16872 5.6246 5.75067 5.95973 5.42112C6.29486 5.08546 6.734 4.89017 7.27715 4.83525V4.09375H7.84052V4.83525C8.34899 4.88407 8.75635 5.05495 9.0626 5.34788C9.37462 5.63472 9.55085 6.0314 9.5913 6.53794H8.5339C8.51656 6.34875 8.44722 6.18092 8.32588 6.03445C8.20454 5.88188 8.04275 5.77813 7.84052 5.72321V7.70053C8.22765 7.79818 8.54256 7.90192 8.78524 8.01178C9.02793 8.11552 9.23305 8.28335 9.40062 8.51526C9.56818 8.74107 9.65197 9.05536 9.65197 9.45815ZM6.46243 6.62033C6.46243 6.87054 6.53177 7.06583 6.67044 7.2062C6.8149 7.34657 7.01713 7.45947 7.27715 7.54491V5.69575C7.02869 5.72626 6.82934 5.82085 6.67911 5.97953C6.53466 6.1382 6.46243 6.3518 6.46243 6.62033ZM7.84052 10.4651C8.09476 10.4224 8.2941 10.3156 8.43856 10.1447C8.58301 9.96774 8.65524 9.75719 8.65524 9.51307C8.65524 9.26896 8.58301 9.07977 8.43856 8.94551C8.29988 8.80514 8.10053 8.69224 7.84052 8.6068V10.4651Z"
         fill="#FFFBDC"
      />
   </Svg>
);

export default Icon;
