import Link from "@Components/Link";
import { useRouter } from "next/router";
import React from "react";

const SearchMenu: React.PropsWithChildren<any> = () => {
   const router = useRouter();
   const pathname = router.pathname;
   
   return (
      <div className="search-container">
         <div className="search-tabs">
            <ul>
               <li className={pathname === "/search" ? "active" : ""}>
                  <Link href={`/search?data=${router.query.data || ""}`}>
                     <div>Tất cả</div>
                  </Link>
               </li>
               <li className={pathname === "/search/feed" ? "active" : ""}>
                  <Link href={`/search/feed?data=${router.query.data || ""}`}>Feed</Link>
               </li>
               <li className={pathname === "/search/user" ? "active" : ""}>
                  <Link href={`/search/user?data=${router.query.data || ""}`}>Tài khoản</Link>
               </li>
               <li className={pathname === "/search/anh" ? "active" : ""}>
                  <Link href={`/search/anh?data=${router.query.data || ""}`}>Ảnh</Link>
               </li>
               <li className={["/search/tat-ca-du-an","/search/thu-vien-thiet-ke", "/search/cong-trinh-thuc-te", "/search/to-am-cua-toi"].includes(pathname) ? "active" : ""}>
                  <Link href={`/search/tat-ca-du-an?data=${router.query.data || ""}`}>Dự án</Link>
               </li>
               <li className={pathname === "/search/blog" ? "active" : ""}>
                  <Link href={`/search/blog?data=${router.query.data || ""}`}>Kiến thức</Link>
               </li>
               <li className={pathname === "/search/shop" ? "active" : ""}>
                  <Link href={`/search/shop?data=${router.query.data || ""}`}>Shop</Link>
               </li>
            </ul>
         </div>
      </div>
   );
};

export default SearchMenu;
