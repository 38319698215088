import React from "react";
import { Dialog } from "@mui/material";
import UserNotifications from "models/userNotifications.model";
import { EBadge } from "models/user.model";
import { useRouter } from "next/router";

export const ModalBadge = ({
   visible,
   onClose,
   dataModal
}: {
   visible: boolean;
   onClose: () => void;
   dataModal?: {
      noti: UserNotifications;
   };
}) => {
   const router = useRouter();
   const noti = dataModal?.noti;
   if (!noti) return <></>;
   return (
      <Dialog open={Boolean(visible)} onClose={onClose} className={` modal-badge`}>
         <div className="modal-header">
            <div className="title">
               {(() => {
                  if (noti?.__user?.publicInfo?.badge === EBadge.creator) {
                     return (
                        <>
                           Xin chúc mừng!
                           <br />
                           Bạn đã trở thành Nhà Sáng Tạo nội dung trên Space T
                        </>
                     );
                  } else if (noti?.__user?.publicInfo?.badge === EBadge.expert) {
                     return (
                        <>
                           Xin chúc mừng!
                           <br />
                           Bạn đã trở thành Chuyên Gia trên Space T
                        </>
                     );
                  } else if (noti?.__user?.publicInfo?.badge === EBadge.enterprise) {
                     return (
                        <>
                           Xin chúc mừng!
                           <br />
                           Tài khoản của bạn được xác thực Doanh Nghiệp trên Space T
                        </>
                     );
                  }
                  return "";
               })()}
            </div>
         </div>
         <div className="modal-content">
            <div className="text">
               {(() => {
                  if (noti?.__user?.publicInfo?.badge === EBadge.creator) {
                     return <>Bạn nhận được Huy hiệu Nhà Sáng Tạo vì đã hoạt động sôi nổi và chia sẻ nhiều nội dung thú vị cho cộng đồng</>;
                  } else if (noti?.__user?.publicInfo?.badge === EBadge.expert) {
                     return <>Bạn nhận được Huy hiệu Chuyên Gia vì thường xuyên chia sẻ kiến thức, giải đáp thắc mắc và góp ý cho cộng đồng</>;
                  } else if (noti?.__user?.publicInfo?.badge === EBadge.enterprise) {
                     return <>Tài khoản nhận được Huy hiệu Doanh Nghiệp vì đã được xác thực GPKD, độ uy tín và tích cực hoạt động trong cộng đồng</>;
                  }
                  return "";
               })()}
            </div>
            <div className="group-action">
               <button
                  className="btn btn-primary"
                  onClick={() => {
                     router.push("/tai-khoan/cai-dat?type=badge");
                     onClose?.();
                  }}
               >
                  Xem Huy hiệu
               </button>
               <button
                  className="btn btn-white"
                  onClick={() => {
                     router.push("/feed");
                     onClose?.();
                  }}
               >
                  Xem Feed
               </button>
            </div>
         </div>
      </Dialog>
   );
};
