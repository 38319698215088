import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useGoogleLogin } from "react-google-login";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { ESigninType, EStep } from "../const";
import User from "models/user.model";
import { tokenKey } from "models/__variables";
import { useRouter } from "next/router";
import NextSeoCustom from "../../../nextSeo";
import { signin, toggleModalAuth } from "../../../../state/action/auth";
import ArchiSketch from "../../../../models/archisketch";

interface IFormInputs {
   account: string;
   authCode: string;
   // nickname: string;
   password: string;
   confirmPassword: string;
}

interface IProps {
   signinType: ESigninType;
   previousURL: string;
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
   onForgot: () => void;
   onSigninSuccess?: (arg?: any) => void;
}

const SigninForm: React.FC<IProps> = ({ signinType, onSigninSuccess, setStep, onForgot }) => {
   const router: any = useRouter();
   const { t } = useTranslation();
   const [error, setError] = useState<ModelError>();
   const [showPassword, setShowPassword] = useState<boolean>(false);
   const dispatch: any = useDispatch();

   useEffect(() => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
         router.push("/feed");
      }
   }, []);
   const schema = yup.object().shape({
      account: yup.string().required("Vui lòng nhập tên đăng nhập").min(6, "Tên đăng nhập tối thiểu 6 ký tự"),
      password: yup.string().required("Vui lòng nhập mật khẩu").min(6, "Vui lòng nhập tối thiểu 6 ký tự").max(32, "Tối đa chỉ 32 ký tự"),
   });

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
   });
   const param = new URLSearchParams(router?.query);

   // const historyPush = (nickname?: string | null, type?: string | null) => {
   //    // dispatch(toggleModalAuth({ visible: false }));

   //    // if (type === "internal") {
   //    //    sessionStorage.removeItem("previous_url");
   //    //    if (router.asPath !== "/feed") {
   //    //       router.reload(`${router.pathname}`);
   //    //    }
   //    // } else {
   //    //    sessionStorage.removeItem("previous_url");
   //    //    if (router.asPath !== "/feed") {
   //    //       router.reload(`${router.pathname}?3rd_party_login=true`);
   //    //    }
   //    // }
   //    // localStorage.removeItem("popupAdsShowed");
   //    // localStorage.removeItem("popupMagazineShowed");
   // };

   const onSubmit = async (data: IFormInputs) => {
      const { account, password } = data;
      try {
         const login = await User.signin(account, password);
         if (login instanceof ModelError) {
            setError(login);
            throw Error(login?.message ?? "");
         } else {
            dispatch(signin(login));
            onSigninSuccess?.();
            gtag("event", "click", {
               event_category: "Click",
               event_label: "dang nhap",
            });
            gtag("event", "conversion", { send_to: "AW-680036316/B-_oCLHQ8tIYENyPosQC" });
         }
      } catch (error) {
         console.error(error);
      }
   };

   const archiSketchLogin = async () => {
      const login = await ArchiSketch.archiSketchLogin();
      if (login instanceof ModelError) {
         return;
      }
      window.location.assign(login.redirectUrl);
   };

   useEffect(() => {
      const token = localStorage.getItem(tokenKey);
      if (token) {
         const openerUrl = param.get("opener");
         if (!openerUrl || !openerUrl.startsWith("https://spacet.archisketch.com")) {
            router.push("/");
            return;
         }
         archiSketchLogin();
      }
   }, []);

   return (
      <div className="signin">
         <NextSeoCustom
            {...{
               title: "Đăng nhập tài khoản | Space T",
               description:
                  "Đăng nhập tài khoản Space T và đăng tải ý tưởng, hình ảnh yêu thích, mua sắm nội thất thỏa thích, đặt câu hỏi, đánh giá công ty nội thất...",
            }}
         />
         <h1 className="signin-phone-title">Đăng nhập</h1>
         <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form">
               <label className="label">{signinType === ESigninType.email ? "Email" : "Số điện thoại"}</label>
               <div className="input-wrap">
                  <input
                     type="text"
                     className={`input ${errors.account || error?.errors?.account ? "error" : ""}`}
                     placeholder={signinType === ESigninType.email ? "Email" : "Số điện thoại"}
                     {...register("account")}
                  />
               </div>
               {error?.errors?.account ? <span className="error-text">{t(`${error?.errors?.account[0]?.message ?? ""}`)}</span> : undefined}
               {errors && !error ? <span className="error-text">{errors?.account?.message ?? ""} </span> : undefined}
            </div>
            <div className="item-form password_input">
               <label className="label">Mật khẩu</label>
               <div className="input-wrap">
                  <input
                     type={showPassword ? "text" : "password"}
                     id={"password"}
                     className={`input ${errors.password || error?.errors?.password ? "error" : ""}`}
                     placeholder="Mật khẩu"
                     {...register("password")}
                  />
                  <img
                     onClick={() => setShowPassword((s) => !s)}
                     className="suffix icon_eye"
                     src={`/assets/img/icon/${showPassword ? "eyes.svg" : "eye-sleep.svg"}`}
                     width={21}
                     height={21}
                  />
               </div>
               {error?.errors?.password ? <span className="error-text">{t(`${error?.errors?.password[0]?.message ?? ""}`)}</span> : undefined}
               {errors && !error ? <span className="error-text">{errors?.password?.message ?? ""} </span> : undefined}
               <div
                  // href="/dat-lai-mat-khau"
                  className="forgot-pass"
                  onClick={onForgot}
               >
                  Quên mật khẩu?
               </div>
            </div>
            <button className="btn btn-auth" type="submit" style={{ backgroundColor: "#FF6347" }}>
               Đăng nhập
            </button>

            {/* <div className="gg-fb">
                  <div className="title-gg-fb">
                     <div className="text">Hoặc đăng nhập với</div>
                  </div>
                  <div className="btn-wrap">
                     <div className="btn-item" onClick={signInGoogle}>
                        <div className="img">
                           <img src="/assets/img/icon/login_G.svg" alt="Đăng nhập bằng GG" />
                        </div>
                     </div>
                     <div className="btn-item" onClick={FBLogin}>
                        <div className="img">
                           <img src="/assets/img/icon/login_FB.svg" alt="Đăng nhập bằng FB" />
                        </div>
                     </div>
                     <div className="btn-item" onClick={AppleLogin}>
                        <div className="img">
                           <img src="/assets/img/icon/login_Apple.svg" alt="Đăng nhập bằng ID-Apple" style={{ objectFit: "contain" }} />
                        </div>
                     </div>
                  </div>
               </div> */}

            <div className="register-now d-flex">
               Chưa có tài khoản?{" "}
               <div className="no-account ms-1" onClick={() => setStep({ step: EStep.signup })}>
                  Đăng ký ngay
               </div>
            </div>
            <div className="back-step" onClick={() => setStep({ step: EStep.signinStep1 })}>
               <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
            </div>
         </form>
      </div>
   );
};

export default SigninForm;
