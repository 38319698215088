/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { resizeImage } from "utils/resizeImage";
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";

export enum EStatus {
    enable = "enable",
    disable = "disable",
    delete = "delete"
}

export enum EStorageProvider {
    Amazon = "amazon",
    Microsoft = "microsoft",
    Others = "others"
}

export interface IPartnerReviewImage extends Model {
    ["info"]?: {
        ["container"]?: string | null;
        ["domain"]?: string | null;
        ["originalName"]?: string | null;
        ["protocol"]?: string | null;
        ["size"]?: number | null;
        ["extension"]?: string | null;
        ["fileType"]?: string | null;
    };
    ["options"]?: {
        ["status"]?: EStatus;
        ["storageProvider"]?: EStorageProvider;
    };
    ["partnerReviewId"]?: string | null;
    ["createdAt"]: string;
    ["updatedAt"]: string;

    ["$url"]?: any | null;
    ["$url1ColResizeMax"]?: any | null;
}

export class PartnerReviewImage extends Model<IPartnerReviewImage> implements IPartnerReviewImage {
    public ["info"]!: {
        ["container"]?: string | null;
        ["domain"]?: string | null;
        ["originalName"]?: string | null;
        ["protocol"]?: string | null;
        ["size"]?: number | null;
        ["extension"]?: string | null;
        ["fileType"]?: string | null;
        ["isMigrated"]?: boolean
    };
    public ["options"]!: {
        ["status"]?: EStatus;
        ["storageProvider"]?: EStorageProvider;
        ["isMigrated"]?: boolean
    };
    public ["partnerReviewId"]!: string | null;
    public ["createdAt"]!: string;
    public ["updatedAt"]!: string;
    
    public ["$url"]?: any | null;
    public ["$url1ColResizeMax"]?: any | null;

    constructor(data?: IPartnerReviewImage) {
        super(data);

        Object.assign(this, data);

        const { $url, $url1024ResizeMax } = resizeImage(
            this._id, 
            this.info, 
            this.info?.isMigrated ?? this.options?.isMigrated
        )
        this.$url = $url
        this.$url1ColResizeMax = $url1024ResizeMax
        // this.$url = `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
    }

    public static async createImage(file: File) {
        try {
            const token = localStorage.getItem(tokenKey);

            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "You don't have permission to access this API.",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng xuất không thành công."
                            }
                        ]
                    }
                });
            }

            const formData = new FormData();
            formData.append("file", file, file.name);

            const response = await fetch(`${apiDomain}/www/user/review/partner/image`, {
                ["method"]: "POST",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["Authorization"]: `Bearer ${token}`
                },
                ["body"]: formData
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["newPartnerReviewImage"]: IPartnerReviewImage;
            }>;

            return new PartnerReviewImage(payload.data.newPartnerReviewImage);
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }




    /**
    * Get full image URL
    */
    // get $url() {
    //     return `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
    // }
}

export default PartnerReviewImage;