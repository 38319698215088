/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Core(s)
 */
import Model from "./model";
import Product from "./product.model";

export enum EStatus {
    enable = "enable",
    disable = "disable",
    delete = "delete",
    draft = "draft"
}

export interface IProductType extends Model {
    ["info"]?: {
        ["name"]?: string | null;
    };
    ["options"]?: {
        ["status"]?: EStatus;
    };

    ["__products"]?: Product[];
}


export default class ProductType extends Model<IProductType> implements IProductType {

    public ["info"]!: {
        ["name"]?: string | null;
    };
    public ["options"]!: {
        ["status"]?: EStatus;
    };

    public ["__products"]!: Product[];

    constructor(data?: IProductType) {
        super(data);

        Object.assign(this, data);
    }

}