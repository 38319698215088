import React from "react";

export default function Checkboxv2({ className = "", label, checked, ...props }: { className?: string; label?: string; checked?: boolean; [t: string]: any }) {
   return (
      <div className={`wcheckbox ${className}`}>
         <div className="checkbox-input">
            <input type="checkbox" className="similer-checkbox" checked={checked} {...props} /> <span className="checkmark"></span>
         </div>
         {label && <span className="label">{label}</span>}
      </div>
   );
}
