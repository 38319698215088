export type TGlobalStore = "initProps" | "menus";

let store = {};

const globalStore: {
   set: (key: TGlobalStore, value) => any;
   get: (key: TGlobalStore) => any;
} = {
   set: () => null,
   get: () => null
};

globalStore.set = (key, value) => {
   store = { ...store, [key]: value };
};

globalStore.get = key => {
   return store[key];
};

export default globalStore;
