export class UserStorages {
   static set<T>(key, data: T, options: { expired?: number }) {
      if (typeof window === "undefined" || !key) return false;
      try {
         const cacheData = { data, ...options };
         localStorage.setItem(key, JSON.stringify(cacheData));
         return cacheData;
      } catch (error) {
         return false;
      }
   }
   static get<T>(key: string): T | null {
      if (typeof window === "undefined" || !key) return null;
      let oldCache: any = localStorage.getItem(key);
      if (!oldCache) return null
      try {
         const { data, expired } = oldCache ? JSON.parse(oldCache) : {} as any;
         if (expired && Date.now() > new Date(expired).getTime()) return null
         return data;
      } catch (error) {
         return null;
      }
   }

   static delete(key) {
      if (typeof window === "undefined" || !key) return false;
      try {
         localStorage.removeItem(key);
         return true;
      } catch (error) {
         return true;
      }
   }

   static setShowing(key, options: { show?: boolean; expired?: number }) {
      if (typeof window === "undefined" || !key) return false;

      let data: any = localStorage.getItem(key);
      try {
         data = data ? JSON.parse(data) : {};
         if (data) {
            localStorage.setItem(key, JSON.stringify({ show: Boolean(options?.show), expired: options?.expired }));
            return true;
         }
         return false;
      } catch (error) {
         return false;
      }
   }
   static getShowing(key) {
      if (typeof window === "undefined" || !key) return null;
      let data: any = localStorage.getItem(key);
      try {
         data = data ? JSON.parse(data) : {};
         if (data) {
            return Boolean(data?.show);
         }
         return null;
      } catch (error) {
         return null;
      }
   }

   static setShowedWithUserId(key, userId, options: { show?: boolean; expired?: number }) {
      if (typeof window === "undefined" || !userId || !key) return false;

      let data: any = localStorage.getItem(key);
      try {
         data = data ? JSON.parse(data) : {};
         if (data) {
            localStorage.setItem(
               key,
               JSON.stringify({
                  ...data,
                  [userId]: { show: Boolean(options?.show), expired: options?.expired }
               })
            );
            return true;
         }
         return false;
      } catch (error) {
         return false;
      }
   }
   static isShowedWithUserId(key, userId, expiredTime?: number) {
      if (typeof window === "undefined" || !userId || !key) return false;

      let data: any = localStorage.getItem(key);
      if (!data) return null;

      try {
         data = data ? JSON.parse(data) : {};
         const user = data[userId];
         if (user) {
            const { expired, show } = user;
            const currentTime = new Date().getTime();
            if (expiredTime && currentTime - Number(expired) > expiredTime) {
               return false;
            } else {
               return Boolean(show);
            }
         }
         return false;
      } catch (error) {
         data = {};
         localStorage.removeItem(key);
         return null;
      }
   }
}
