import { FunctionComponent, memo, useEffect } from "react";
import styles from "./index.module.scss";
import classnames from "classnames";
import Image from "next/image";
import { useSelector } from "react-redux";
import { RootState } from "state";

interface LoadingProps {
   loading?: boolean;
}

const Loading: FunctionComponent<LoadingProps> = (props: any) => {
   const { loading } = useSelector((state: RootState) => state.global);

   const loadingClassName = classnames([
      styles.globalLoading,
      {
         [styles.active]: loading
      }
   ]);

   useEffect(() => {
      if (loading) {
         document.body.classList.add("overflow-hidden");
      } else {
         document.body.classList.remove("overflow-hidden");
      }
   }, [loading]);

   if (!loading) return <></>;
   return (
      <div className={loadingClassName}>
         <Image width={25} height={25} sizes="25" src={"/assets/img/icon/loading.svg"} alt="Loading" />
      </div>
   );
};

export default memo<LoadingProps>(Loading);
