import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import styles from "./DialogNotification.module.scss";

const StylesDialog = styled(Dialog)(() => ({
   ".MuiPaper-root": {
      width: 328,
      background: "#FFFFFF",
      boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.16)",
      borderRadius: 16,
      padding: 24,
   },
}));

interface IProps {
   open: boolean;
   title: string | React.ReactNode;
   message?: string;
   textButton?: string;
   onClose?: () => void;
}
export default function DialogNotification({ open, title, message, textButton, onClose }: IProps) {
   return (
      <StylesDialog open={open}>
         <p className={styles["title"]}>{title}</p>
         {message && <p className={styles["message"]}></p>}
         <div className={styles["dialog-action"]}>
            <button className={styles["submit-btn"]} onClick={onClose}>
               {textButton ?? "Xác nhận"}
            </button>
         </div>
      </StylesDialog>
   );
}
