/* eslint-plugin-disable react */
/*eslint-disable */

/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Model(s)
 */
import ArticleCategory from "./articleCategory.model";
import ArticleMedia from "./articleMedia.model";
import { IModel, Model, ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
// import ArticleGroup from "./articleGroup.model";
import ArticleReaction from "./articleReaction.model";
import Keyword from "./keyword.model";
import { EReactionTypes, IReaction } from "./reaction.model";
import UserBookmark, { IUserBookmark } from "./userBookmark.model";
import ProductCategory from "./productCategory.model";
import ProductTaggingImage from "./productTaggingImage.model";

export enum EStatus {
   enable = "enable",
   disable = "disable",
   delete = "delete"
}

export enum ECategoryIds {
   interiorStyle = "6153942fdff7654aaf8bb1d6", // Phong cách nội thất
   furnitureKnowledge = "615394d2dff7654aaf8bb1d7", // Kiến thức nội thất
   inspiration = "61539598dff7654aaf8bb1d8", // Nguồn cảm hứng
   news = "615395b9dff7654aaf8bb1d9", // Tin tức
   lifeStyle = "615395f1dff7654aaf8bb1da" // Phong cách sống
}

export const getTypeMagazine = (categoryId: string): string => {
   switch (categoryId) {
      case ECategoryIds.interiorStyle:
         return "phong-cach-noi-that";
      case ECategoryIds.furnitureKnowledge:
         return "kien-thuc-noi-that";
      case ECategoryIds.inspiration:
         return "nguon-cam-hung";
      case ECategoryIds.news:
         return "tin-tuc";
      case ECategoryIds.lifeStyle:
         return "phong-cach-song";
      default:
         return "kien-thuc";
   }
};

interface ITableOfContent {
   id: string;
   _id: string;
   text: string;
   children: ITableOfContent[]
}

export interface IArticle extends IModel {
   ["info"]?: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["sapo"]?: string | null;
      ["content"]?: string | null;
      ["slug"]?: string | null;
      ["imageFeatured"]?: string | null;
      ["viewCount"]?: number | null;
   };
   ["options"]?: {
      ["status"]?: EStatus | null;
   };
   ["categoryId"]?: string | null;
   ["tableOfContent"]?: ITableOfContent[] | null;
   ["groupIds"]?: string[] | null;
   ["keywordIds"]?: string[] | null;
   ["__media"]?: ArticleMedia | null;
   ["__medias"]?: ArticleMedia[];
   ["__category"]?: ArticleCategory | null;
   ["__keywords"]?: Keyword[] | null;
   ["__bookmarkCount"]?: number | null;
   ["__productCategories"]?: ProductCategory[]
   ["__productTaggings"]?: ProductTaggingImage[]
   ["__reactionCount"]?: { like: number, comment: number };
}

export class Article extends Model<IArticle> implements IArticle {
   public ["info"]!: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["sapo"]?: string | null;
      ["content"]?: string | null;
      ["slug"]?: string | null;
      ["imageFeatured"]?: string | null;
      ["viewCount"]?: number | null;
      ["publishedAt"]?: string;
   };
   public ["options"]?: {
      ["status"]?: EStatus | null;
      ["isMigrated"]?: boolean;
   };
   public ["tableOfContent"]?: ITableOfContent[] | null;
   public ["categoryId"]!: string | null;
   public ["groupIds"]!: string[] | null;
   public ["keywordIds"]!: string[] | null;

   public ["__media"]!: ArticleMedia | null;
   public ["__medias"]?: ArticleMedia[];
   public ["__category"]!: ArticleCategory | null;
   public ["__keywords"]!: Keyword[] | null;
   public ["__bookmarkCount"]?: number | null;
   public ["__currentUserBookmark"]!: UserBookmark;
   public ["__productCategories"]?: ProductCategory[]
   public ["__productTaggings"]?: ProductTaggingImage[]
   public ["__reactionCount"]?: { like: number, comment: number };

   constructor(data?: IArticle) {
      super(data);

      Object.assign(this, data);

      if (this.__category && !(this.__category instanceof ArticleCategory)) {
         this.__category = new ArticleCategory(this.__category);
      }
      if (this.__media && !(this.__media instanceof ArticleMedia)) {
         this.__media = new ArticleMedia(this.__media);
      }
      if (this.__productTaggings && !(this.__productTaggings instanceof ArticleMedia)) {
         this.__productTaggings = this.__productTaggings.map(it => new ProductTaggingImage(it));
      }
   }

   get typeCategory() {
      switch (this.categoryId) {
         case ECategoryIds.interiorStyle:
            return "phong-cach-noi-that";
         case ECategoryIds.furnitureKnowledge:
            return "kien-thuc-noi-that";
         case ECategoryIds.inspiration:
            return "nguon-cam-hung";
         case ECategoryIds.news:
            return "tin-tuc";
         case ECategoryIds.lifeStyle:
            return "phong-cach-song";
         default:
            return "kien-thuc";
      }
   }

   /**
    *
    * @param keywords
    * @param categories
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async paginate({
      keywords,
      page,
      limit,
      sort,
      sortBy,
      categories,
      categorySlugs,
      cookiesToken
   }: {
      keywords?: string;
      categories?: string;
      categorySlugs?: string;
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt" | "info.title";
      cookiesToken?: string;
   }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/article/paginate?offcontent=true`, {
            ["method"]: "GET",
            ["cache"]: "default",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "createdAt",
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(!keywords ? undefined : { ["keywords"]: encodeURI(keywords) }),
               ...(!categories ? undefined : { ["categories"]: encodeURI(categories) }),
               ...(!categorySlugs ? undefined : { ["category-slugs"]: encodeURI(categorySlugs) })
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IArticle>;
            userBookmarks?: IUserBookmark[];
         }>;

         const paginate = new Paginate(payload.data.paginator, Article);

         return { paginate, userBookmarks: payload.data.userBookmarks };
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   /**
    *
    * @param slug
    * @returns
    */
   public static async getbySlug(slug?: string, id?: string, cookiesToken?: string, categorySlug?: string) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const response = await fetch(`${apiDomain}/www/article`, {
            ["method"]: "GET",
            ["cache"]: "default",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest",
               ...(!id ? undefined : { ["id"]: encodeURI(id) }),
               ...(!slug ? undefined : { ["slug"]: encodeURI(slug) }),
               ...(!categorySlug ? undefined : { ["category-slug"]: encodeURI(categorySlug) }),
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` })
            }
         });
         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["article"]: IArticle;
            ["bookmark"]?: IUserBookmark;
            ["currentUserReactions"]: {
               [EReactionTypes.like]: IReaction;
            };
         }>;

         return {
            ["article"]: new Article(payload.data.article),
            ["bookmark"]: !payload.data.bookmark ? null : new UserBookmark(payload.data.bookmark),
            ["currentUserReactions"]: {
               [EReactionTypes.like]: payload.data?.currentUserReactions?.like ? new ArticleReaction(payload.data.currentUserReactions.like) : null
            }
         };
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }
   public static async getAllBlogs() {
      try {
         const response = await fetch(`${apiDomain}/www/general/article-slugs`, {
            ["method"]: "GET",
            ["cache"]: "default",
            ["headers"]: {
               ["Origin"]: "https://spacet.vn",
               ["X-Requested-With"]: "XMLHttpRequest"
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = await response.json();

         const paginate = new Paginate(payload.data, Article);

         return paginate;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   /**
    *
    * @param categorySlugs
    * @returns
    */
   public static async getMagazine({
      categorySlugs,
      limit,
      sort,
      sortBy
   }: {
      categorySlugs?: string;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt" | "info.title";
   }) {
      try {
         const response = await Model.fetchData({
            method: "GET",
            endPoint: "article/magazine",
            headers: {
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "createdAt",
               ...(categorySlugs ? { "category-slugs": categorySlugs } : undefined),
               ...(limit ? { limit: String(limit) } : undefined)
            }
         });

         if (response instanceof ModelError) {
            return response;
         }

         const payload = (await response.json()) as TPayload<{
            [key: string]: Paginate<Article, IArticle>;
         }>;

         const result: { [key: string]: Paginate<Article, IArticle> } = {};

         await Object.keys(payload.data).forEach(key => {
            result[key] = new Paginate(payload.data[key], Article);
         });

         return result;
      } catch (error) {
         return error;
      }
   }
   public static async suggestions({ id, page, offset, limit, cookiesToken }: { id: string; page?: number; offset?: number; limit?: number; cookiesToken?: string }) {
      try {
         const token = typeof window !== "undefined" && localStorage.getItem(tokenKey);
         const lastToken = cookiesToken || token;
         const res = await Model.fetchData({
            method: "GET",
            endPoint: "article/suggestions?offcontent=true",
            headers: {
               id,
               ...(!lastToken ? undefined : { ["Authorization"]: `Bearer ${lastToken}` }),
               ...(page ? { page: String(page) } : undefined),
               ...(offset ? { offset: String(offset) } : undefined),
               ...(limit ? { limit: String(limit) } : undefined)
            }
         });
         if (res instanceof ModelError) {
            return res;
         }
         const payload = (await res.json()) as TPayload<{
            ["suggestion"]: TPaginate<Article>;
            userBookmarks?: IUserBookmark[];
         }>;
         const paginate = new Paginate(payload?.data?.suggestion, Article);

         return { paginate, userBookmarks: payload.data.userBookmarks };
      } catch (error) {
         return new ModelError(error);
      }
   }
}

export default Article;
