/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";
import Product from "./product.model";

export enum EStatus {
    enable = "enable",
    disable = "disable",
    delete = "delete"
}



export interface IProductOption extends Model {
    ["info"]: {
        ["name"]?: string | null;
        ["position"]?: number | null;
    };
    ["options"]: {
        ["status"]?: EStatus;
    };
    ["productId"]: string | null;

    ["__product"]?: Product | null;
}


export default class ProductOption extends Model<IProductOption> implements IProductOption {

    public ["info"]!: {
        ["name"]?: string | null;
        ["position"]?: number | null;
    };
    public ["options"]!: {
        ["status"]?: EStatus;
    };
    public ["productId"]!: string | null;

    public ["__product"]!: Product | null;

    constructor(data?: IProductOption) {
        super(data);

        Object.assign(this, data);

        if (this.__product) {
            this.__product = new Product(this.__product);
        }
    }

    public static async getProductOptionById(id: string) {
        try {
            const response = await fetch(`${apiDomain}/www/product/options`, {
                ["method"]: "GET",
                ["cache"]: "no-store",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["id"]: id
                }
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["productOptions"]: IProductOption[];
            }>;

            return payload.data.productOptions.map(producOptionJSON => new ProductOption(producOptionJSON));

        }
        catch (error: any) {
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }
}