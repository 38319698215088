import { CATEGORY } from '../type';
const initState = {
    categoriesName: null,
}
export default function categoriesReducer(state = initState, action: any) {
    switch (action.type) {
        case CATEGORY:
            return {
                ...state,
                categoriesName: action.payload
            }
        default:
            return state;
    }

}
