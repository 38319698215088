import Menu from "models/menu.model";
import MenuItem from "models/menuItem.model";
import { memo, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { IMenu } from "models/menu.model";
import Link from "next/link";
import classNames from "classnames";
import { isIOS } from "react-device-detect";

interface IProps {
   visible?: boolean;
   menus: MenuItem[];
   onClose?: () => void;
}

function ShopMenuDrawerMobile({ visible, onClose, menus }: IProps) {
   const router = useRouter();
   const catalogSlug = router.query?.catalogSlug as string;

   const [level2, setLevel2] = useState<MenuItem[]>(menus[0].__childs);
   const [activeLv1, setActiveLv1] = useState<string>(menus[0]._id);
   const [activeLv2, setActiveLv2] = useState<string>(menus[0].__childs[0]._id);

   useEffect(() => {
      let foundItem = Menu.findItemWithSlug(menus, catalogSlug);
      if (foundItem) {
         const [lv0, lv1, lv2, lv3, lv4] = foundItem.xpath.split(";;");

         setActiveLv1(lv1);
         setActiveLv2(lv2);

         const fmenulv2 = Menu.findItemWithId(menus, lv1);
         setLevel2(fmenulv2?.__childs || []);

         setTimeout(() => {
            document.getElementById(lv2)?.scrollIntoView();
         }, 50);
      }
   }, [catalogSlug, menus]);

   const handleClose = () => {
      onClose?.();
   };

   const handleItemClick = (menu: MenuItem) => {
      onClose?.();
   };

   // For drawer scroll
   const [init, setIsInit] = useState(true);
   useEffect(() => {
      if (init) {
         setIsInit(false);
         return;
      }
      if (visible) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "";
      }
   }, [init, visible]);

   return (
      <div className={`drawer-menu-shop ${visible ? "showMenu" : "hideMenu"}`}>
         <div className="shop-menu-content">
            <div className="menu-header">
               <div className="close-icon" onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                     <path
                        d="M0.227512 14.2715C-0.0815384 13.9713 -0.0727084 13.4592 0.227512 13.1589L5.87872 7.4989L0.227512 1.84769C-0.0727084 1.54747 -0.0727084 1.04416 0.227512 0.735111C0.527732 0.41723 1.0487 0.42606 1.34892 0.735111L7.00013 6.38632L12.6513 0.735111C12.9516 0.42606 13.4637 0.42606 13.7727 0.735111C14.0818 1.03533 14.073 1.54747 13.7727 1.84769L8.12154 7.4989L13.7727 13.1589C14.073 13.4592 14.073 13.9625 13.7727 14.2715C13.4725 14.5806 12.9516 14.5717 12.6513 14.2715L7.00013 8.62031L1.34892 14.2715C1.0487 14.5717 0.536562 14.5717 0.227512 14.2715Z"
                        fill="white"
                     />
                  </svg>
               </div>
               <span>Danh mục</span>
            </div>
            <ul className="sub-category">
               {menus.map((menu) => {
                  return (
                     <li key={menu._id} className={activeLv1 === menu._id ? "active" : ""}>
                        <a
                           onClick={() => {
                              setLevel2(menu.__childs);
                              setActiveLv1(menu._id);
                              setActiveLv2(menu.__childs?.[0]?._id);
                           }}
                        >
                           {menu.info.title}
                        </a>
                     </li>
                  );
               })}
            </ul>
            <div className="menu-list-wrap">
               <div className="cate-left">
                  {level2.map((menu, i) => {
                     return (
                        <div
                           className={`cate-item ${activeLv2 === menu._id ? "active" : ""}`}
                           key={menu._id}
                           onClick={() => {
                              document.getElementById(menu._id)?.scrollIntoView({ behavior: "smooth" });
                              setActiveLv2(menu._id);
                           }}
                        >
                           {menu.info.title}
                        </div>
                     );
                  })}
               </div>
               <div className={classNames("right-content", isIOS ? "isIOS" : "isOTHER")}>
                  {level2.map((menu, i) => {
                     return (
                        <div className="submenu" id={menu._id} key={menu._id}>
                           <div className="submenu-head">
                              <div className="menu-label">{menu.info.title}</div>
                              <Link href={`/shop/${menu.info.slug}`} title={menu.info.title} onClick={() => handleItemClick(menu)}>
                                 Xem tất cả{" "}
                                 <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <path
                                       d="M3.99967 10.3754C4.0992 10.3759 4.19473 10.3362 4.26467 10.2654L8.26467 6.26541C8.4109 6.119 8.4109 5.88182 8.26467 5.73541L4.26467 1.73541C4.11692 1.59773 3.88667 1.60179 3.74386 1.7446C3.60105 1.88741 3.59699 2.11766 3.73467 2.26541L7.46967 6.00041L3.73467 9.73541C3.58844 9.88182 3.58844 10.119 3.73467 10.2654C3.80461 10.3362 3.90014 10.3759 3.99967 10.3754Z"
                                       fill="#697F90"
                                    />
                                 </svg>
                              </Link>
                           </div>
                           <div className="submenu-list">
                              {menu.__childs.map((submenu, isubmenu) => {
                                 return (
                                    <Link
                                       key={submenu._id}
                                       className="submenu-item"
                                       href={`/shop/${submenu.info.slug}`}
                                       title={submenu.info.title}
                                       onClick={() => handleItemClick(submenu)}
                                    >
                                       <div className="submenu-icon">
                                          <img src={submenu.__icon} alt={submenu.info.title} />
                                       </div>
                                       <span className="label">{submenu.info.title}</span>
                                    </Link>
                                 );
                              })}
                           </div>
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
      </div>
   );
}

export default memo(ShopMenuDrawerMobile, (prev, next) => {
   return prev.visible === next.visible;
});
