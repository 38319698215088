import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { EStep } from "../const";
import User from "models/user.model";
import { tokenKey } from "models/__variables";
import store from "../../../../state";
import { useRouter } from "next/router";
import { signin, toggleModalAuth } from "state/action/auth";

interface IFormInputs {
   account: string;
   authCode: string;
   // nickname: string;
   password: string;
   confirmPassword: string;
}

interface IProps {
   previousURL?: string;
   formValue: {
      authCode: string;
      phone: string;
   };
   onLogin: () => void;
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
   onBack?: () => void;
}

const FormNewPassword: React.FC<IProps> = ({ formValue, previousURL, onLogin, setStep, onBack }) => {
   const router: any = useRouter();
   const { t } = useTranslation();
   const [error, setError] = useState<ModelError>();
   const [showPassword, setShowPassword] = useState<boolean>(false);

   const schema = yup.object().shape({
      // account: yup.string().required("Vui lòng nhập tên đăng nhập"),
      // authCode: yup.string().required("Vui lòng nhập mã xác thực").min(6, "Mã xác thực là 6 ký tự").max(6, "Mã xác thực là 6 ký tự"),
      // nickname: yup.string().required("Vui lòng nhập nickname").min(3, "Vui lòng nhập tối thiểu 3 ký tự").max(24, "Tối đa chỉ 24 ký tự"),
      password: yup
         .string()
         .required("Vui lòng nhập mật khẩu")
         .min(6, "Vui lòng nhập tối thiểu 6 ký tự")
         .max(32, "Tối đa chỉ 32 ký tự")
      // confirmPassword: yup.string().required("Vui lòng nhập lại mật khẩu").oneOf([yup.ref('password'), null], 'Nhập lại mật khẩu không chính xác')
   });
   const {
      register,
      handleSubmit,
      getValues,
      formState: { errors }
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema)
   });

   const onSubmit = async () => {
      const password = getValues("password");
      try {
         const res = await User.signup(
            formValue.phone,
            Number(formValue.authCode ?? 123456),
            // nickname,
            password,
            password, // confirm password
            previousURL
         );
         if (res instanceof ModelError) {
            setError(res);
            throw Error(res.message);
         } else {
            setError(undefined);
            const login = await User.signin(formValue.phone, password);
            if (login instanceof ModelError) {
               throw Error(login.message);
            } else {
               //   gtag('event', 'click', {
               //     event_category: 'Click',
               //     event_label: 'dang ky',
               // });
               // gtag('event', 'conversion', {'send_to': 'AW-680036316/B-_oCLHQ8tIYENyPosQC'})
               // historyPush(login?.userInfo?.publicInfo?.nickname, 'internal');
               setStep({ step: EStep.signupSuccess, dataStep: login });
            }
         }
      } catch (error) {
         console.error(error);
      }
   };

   return (
      <div className="form-new-password">
         <h1 className="signup-phone-title">Tạo mật khẩu</h1>
         <p className="signup-phone-des">Tạo mật khẩu mới an toàn và dễ nhớ!</p>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form password_input">
               <label className="label">Mật khẩu</label>
               <div className="input-wrap">
                  <img className="prefix" src="/assets/img/icon/lock.png" alt="" />
                  <input
                     type={showPassword ? "text" : "password"}
                     id={"password"}
                     {...register("password")}
                     className={`input ${errors.password ? "error" : ""}`}
                     placeholder="Mật khẩu"
                  />
                  <img
                     onClick={() => setShowPassword(prev => !prev)}
                     className="suffix"
                     src={`/assets/img/icon/${showPassword ? "eyes.svg" : "eye-sleep.svg"}`}
                     width={21}
                     height={21}
                  />
               </div>
               {errors?.password?.message ? <span className="error-text">{errors?.password?.message} </span> : undefined}
               {error?.errors?.password?.[0]?.message ? <span className="error-text">{t(error?.errors?.password?.[0]?.message)} </span> : undefined}
               {error?.errors?.account?.[0]?.message ? <span className="error-text">{t(error?.errors?.account?.[0]?.message)} </span> : undefined}
               {error?.errors?.authCode?.[0]?.message ? <span className="error-text">{t(error?.errors?.authCode?.[0]?.message)} </span> : undefined}
            </div>

            <button className="btn btn-auth" type="submit" style={{ backgroundColor: "#FF6347" }}>
               Xác nhận
            </button>

            <div className="login-helper" style={{ marginTop: 30, textAlign: "center" }}>
               {/* Đã có tài khoản? <span onClick={onLogin}>Đăng nhập ngay</span> */}
            </div>

            <div className="back-step" onClick={onBack}>
               <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
            </div>
         </form>
      </div>
   );
};

export default FormNewPassword;
