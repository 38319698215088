/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { Paginate, ModelError, TModelError, TPayload, TPaginate } from "./model";
import ProductVariant from "./productVariant.model";
import PartnerReviewImage from "./partnerReviewImage.model";

export enum EStatus {
    pending = "pending",
    enable = "enable",
    disable = "disable"
}
export enum EUsageStatus {
    pending = "pending",
    applied = "applied"
}
export interface IPartnerReview extends Model {
    ["info"]: {
        ["point"]: number;
        ["content"]: string | null;
        ["name"]: string,
        ["phone"]: string,
        ["project"]: string,
    };
    ["options"]: {
        ["status"]: EStatus;
        ["usageStatus"]: EUsageStatus;
    };
    ["ratingPoints"]: {
        ["cost"]: number;
        ["quality"]: number;
        ["time"]: number;
    };
    ["bonusPoint"]: number;
    ["partnerId"]: string,
    ["userId"]: string;
    ["publishedAt"]: string;
    ["updatedAt"]: string;
    ["imageIds"]: string[]
}
export class PartnerReview extends Model<IPartnerReview> implements IPartnerReview {
    public ["info"]!: {
        ["point"]: number;
        ["content"]: string | null;
        ["name"]: string;
        ["phone"]: string;
        ["project"]: string;
    };
    public ["options"]!: {
        ["status"]: EStatus;
        ["usageStatus"]: EUsageStatus;
    };
    public ["ratingPoints"]!: {
        ["cost"]: number;
        ["quality"]: number;
        ["time"]: number;
    };
    public ["bonusPoint"]!: number;
    public ["partnerId"]!: string;
    public ["userId"]!: string;
    public ["publishedAt"]!: string;
    public ["updatedAt"]!: string;
    public ["imageIds"]!: string[];


    constructor(data?: IPartnerReview) {
        super(data);

        Object.assign(this, data);


    }

    public static async review({
        partnerId, content, cost, quality, time, imageIds, name, phone, project
    }: {
        partnerId: string;
        content: string;
        name: string,
        phone: string,
        project: string,
        cost: number;
        quality: number;
        time: number;
        imageIds: string[]
    }) {
        const token = localStorage.getItem(tokenKey);
        if (!token) {
            return new ModelError({
                ["httpCode"]: 401,
                ["message"]: "Unauthorization",
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.401",
                            ["message"]: "Đăng nhập để tiếp tục."
                        }
                    ]
                }
            });
        }
        const response = await fetch(`${apiDomain}/www/user/review/partner`, {
            ["method"]: "POST",
            ["headers"]: {
                ["X-Requested-With"]: "XMLHttpRequest",
                ["Content-Type"]: "application/json",
                ["Authorization"]: `Bearer ${token}`,
            },
            ["body"]: JSON.stringify({
                ["info"]: {
                    ["content"]: content,
                    ["name"]: name,
                    ["phone"]: phone,
                    ["project"]: project
                },
                ["ratingPoints"]: {
                    ["cost"]: cost,
                    ["quality"]: quality,
                    ["time"]: time,
                },
                ["partnerId"]: partnerId,
                ["imageIds"]: imageIds
            })
        });

        if (!response.ok) {
            return new ModelError(await response.json() as TModelError);
        }
        const payload = await response.json() as TPayload<{
            ["newPartnerReview"]: IPartnerReview;
        }>;
        return new PartnerReview(payload.data.newPartnerReview)
    }
}
export default PartnerReview;