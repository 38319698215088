import { initPaginate } from "models/model";
import * as types from "./types";
import SocialFeed, { ISocialFeed } from "models/socialFeed.model";

interface ISearchState {
   feeds: SocialFeed[];
}

const initState: ISearchState = {
   feeds: []
};

export default function socialFeedReducer(state = initState, action): ISearchState {
   const { type, payload } = action;
   switch (type) {
      case types.SET_SOCIAL_FEEDS_RESULT:
         return {
            ...state,
            feeds: payload || initPaginate
         };

      default:
         return state;
   }
}
