import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { EStep } from "../const";
import User from "models/user.model";
import { emailRegex } from "core/yupPatern";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

interface IFormInputs {
   account: string;
   authCode: string;
   // nickname: string;
   password: string;
   confirmPassword: string;
}

interface IProps {
   previousURL?: string;
   onLogin: () => void;
   setStep: ({ step, dataStep }: { step: EStep; dataStep: any }) => void;
   onBack: () => void;
}

const SignupWithEmailForm: React.FC<IProps> = ({ previousURL, onLogin, setStep, onBack }) => {
   // const router: any = useRouter();
   const { t } = useTranslation();
   const [error, setError] = useState<ModelError>();
   const [showPassword, setShowPassword] = useState<boolean>(false);

   const schema = yup.object().shape({
      account: yup.string().required("Vui lòng nhập tên đăng nhập").min(3, "Vui lòng nhập tối thiểu 3 ký tự").matches(emailRegex, "Email không hợp lệ"),
      // authCode: yup.string().required("Vui lòng nhập mã xác thực").min(6, "Mã xác thực là 6 ký tự").max(6, "Mã xác thực là 6 ký tự"),
      // nickname: yup.string().required("Vui lòng nhập nickname").min(3, "Vui lòng nhập tối thiểu 3 ký tự").max(24, "Tối đa chỉ 24 ký tự"),
      password: yup.string().required("Vui lòng nhập mật khẩu").min(6, "Vui lòng nhập tối thiểu 6 ký tự").max(32, "Tối đa chỉ 32 ký tự"),
      // confirmPassword: yup.string().required("Vui lòng nhập lại mật khẩu").oneOf([yup.ref('password'), null], 'Nhập lại mật khẩu không chính xác')
   });
   const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
   } = useForm<IFormInputs>({
      resolver: yupResolver(schema),
   });

   const onSubmit = async (data: IFormInputs) => {
      const {
         account,
         authCode,
         // nickname,
         password,
         confirmPassword,
      } = data;
      try {
         const captchat = await executeRecaptcha();
         const res = await User.signup(
            account,
            Number(authCode ?? 123123),
            // nickname,
            password,
            confirmPassword,
            (previousURL = typeof window !== undefined ? window.location.href : undefined),
            captchat
         );
         if (res instanceof ModelError) {
            setError(res);
            return;
         } else {
            setError(undefined);
            const login = await User.signin(account, password);
            if (login instanceof ModelError) {
               setError(login);
               return;
            }
            // store.dispatch(signin(login))
            //   gtag('event', 'click', {
            //     event_category: 'Click',
            //     event_label: 'dang ky',
            // });
            // gtag('event', 'conversion', {'send_to': 'AW-680036316/B-_oCLHQ8tIYENyPosQC'})
            // historyPush(login?.userInfo?.publicInfo?.nickname, 'internal');
            setStep({ step: EStep.signupSuccess, dataStep: login });
         }
      } catch (error) {
         console.error(error);
      }
   };

   return (
      <div className="signup-email">
         <h1 className="title_signup_form">Đăng ký với email</h1>
         <form className="form-register" onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form">
               <label className="label">{"Nhập email"}</label>
               <div className="input-wrap">
                  <input
                     type="text"
                     className={`input input-account ${errors.account || error?.errors?.account ? "error" : ""}`}
                     {...register("account")}
                     placeholder="Email"
                  />
               </div>
               {error?.errors?.account ? <span className="error-text">{t(`${error?.errors?.account[0]?.message}`)}</span> : undefined}
               {error?.errors?.OTP && error?.errors?.OTP.length > 0 ? <span className="error-text">{t(`${error?.errors?.OTP[0].message}`)}</span> : undefined}
               {errors?.account?.message ? <span className="error-text">{errors?.account?.message}</span> : undefined}
            </div>

            <div className="item-form password_input">
               <label className="label">Mật khẩu</label>
               <div className="input-wrap">
                  <input
                     type={showPassword ? "text" : "password"}
                     id={"password"}
                     {...register("password")}
                     className={`input ${errors.password ? "error" : ""}`}
                     placeholder="Mật khẩu"
                  />
                  <img
                     style={{ cursor: "pointer" }}
                     onClick={() => setShowPassword((prev) => !prev)}
                     className="suffix"
                     src={`/assets/img/icon/${showPassword ? "eyes.svg" : "eye-sleep.svg"}`}
                     width={21}
                     height={21}
                  />
               </div>
               {errors?.password?.message ? <span className="error-text">{errors?.password?.message} </span> : undefined}
            </div>

            <button className="btn btn-auth mt-5" type="submit" style={{ backgroundColor: "#FF6347" }}>
               Đăng ký
            </button>

            <div className="login-helper d-flex justify-content-center">
               Đã có tài khoản?{" "}
               <div className="ms-1" onClick={onLogin}>
                  Đăng nhập ngay
               </div>
            </div>

            <div className="back-step" onClick={onBack}>
               <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
            </div>
         </form>
      </div>
   );
};

export default SignupWithEmailForm;
