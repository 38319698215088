import React from "react";
import { NextSeo, NextSeoProps } from "next-seo";
import { useRouter } from "next/router";
import { appDomain } from "../../models/__variables";

export const PAGE_NOT_CANONICAL: string[] = [
   // "/shop/danh-gia",
   "/shop/san-pham-ban-chay",
   "/shop/san-pham-moi"
]

export default function NextSeoCustom(props: NextSeoProps) {
   const router = useRouter();
   const canonicalCustom = () => {
      if (PAGE_NOT_CANONICAL.includes(router.asPath.split(/\?|#/)[0])) {
         return undefined
      }

      let routerPath = `${appDomain}${router.asPath.split(/\?|#/)[0]}`;
      if (router.query.id && router.pathname.includes("[findIdea")) {
         routerPath = routerPath + `${routerPath.includes("?") ? "&" : "?"}id=${router.query.id}`;
      }
      if (!routerPath) {
         return window.location.href.split(/\?|#/)[0]
      }
      return routerPath;
   };

   const canonical = canonicalCustom()

   return (
      <NextSeo
         canonical={canonical}
         nofollow={appDomain !== "https://spacet.vn"}
         noindex={appDomain !== "https://spacet.vn"}
         {...props}
      />
   );
}
