/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from './__variables';

/**
 * Core(s)
 */
import Model, { ModelError, Paginate, TModelError, TPaginate, TPayload } from './model';

import Community from './community.model';
import Order from './order.model';
import PartnerReview from './partnerReview.model';
import ProductReview from './productReview.model';
import QnAComment from './qnaComment.model';
import SocialFeed from './socialFeed.model';
import User from './user.model';



export type TUserPoint = {
    ['totalPoint']: number,
    ['usedPoint']: number,
    ['notExpiredPoint']: number,
    ['useablePoint']: number
};

export enum EStatus {
    enable = 'enable',
    disable = 'disable',
    delete = 'delete'
}

export enum EOpenTypes {
    used = 'used',
    order = 'order',
    reviewProduct = 'reviewProduct',
    reviewPartner = 'reviewPartner',
    commentQnA = 'commentQnA',
    registration = 'registration',
    orderRefund = 'orderRefund',
    communityPost = 'communityPost',
    socialFeed ='socialFeed',
    reviewPartnerViaSpaceT = 'reviewPartnerViaSpaceT'
}


export interface IUserPoint extends Model {
    ['info']?: {
        ['message']: string;
        ['value']: number;
        ['openId']: string;
        ['openType']: EOpenTypes;
        ['expiredAt']: string | null;
    },
    ['options']: {
        ['status']: EStatus;
    }
    ['userId']: string;

    ['__user']?: User;

    ['__qnaComment']?: QnAComment;
    ['__order']?: Order;
    ['__productReview']?: ProductReview;
    ['__partnerReview']?: PartnerReview;
    ['__communityPost']?: Community;
}
export class UserPoint extends Model<IUserPoint> implements IUserPoint {
    public ['info']!: {
        ['message']: string;
        ['value']: number;
        ['openId']: string;
        ['openType']: EOpenTypes;
        ['expiredAt']: string | null;
    };
    public ['options']!: {
        ['status']: EStatus;
    };
    public ['userId']!: string;

    public ['__user']!: User;

    public ['__qnaComment']!: QnAComment;
    public ['__order']!: Order;
    public ['__productReview']!: ProductReview;
    public ['__partnerReview']!: PartnerReview;
    public ['__communityPost']!: Community;
    public ['__socialFeed']!: SocialFeed;

    constructor(data?: IUserPoint) {
        super(data);

        Object.assign(this, data);

        if (this.__qnaComment || this.__partnerReview || this.__productReview || this.__order || this.__communityPost) {
            switch (this.info.openType) {
                case EOpenTypes.order:
                    this.__order = new Order(this.__order);
                    break;
                case EOpenTypes.commentQnA:
                    this.__qnaComment = new QnAComment(this.__qnaComment);
                    break;
                case EOpenTypes.reviewPartner:
                    this.__partnerReview = new PartnerReview(this.__partnerReview);
                    break;
                case EOpenTypes.reviewProduct:
                    this.__productReview = new ProductReview(this.__productReview);
                    break;
                case EOpenTypes.communityPost:
                    this.__communityPost = new Community(this.__communityPost);
                    break;
                default:
                    break;
            }
            return;
        }
    }
    public static async paginate({
        page, limit, sort, sortBy, types
    }: {
        types?: string;
        page?: number;
        limit?: number;
        sort?: 'desc' | 'asc';
        sortBy?: 'createdAt' | 'info.expiredAt' | 'info.value'
    }) {
        try {
            const token = localStorage.getItem(tokenKey);
            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "Unauthorization",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng nhập để tiếp tục."
                            }
                        ]
                    }
                });
            }
            const response = await fetch(`${apiDomain}/www/user/point/paginate`, {
                ["method"]: "GET",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ['Authorization']: `Bearer ${token}`,
                    ["page"]: !page ? String(1) : String(page),
                    ["limit"]: !limit ? String(20) : String(limit),
                    ["sort"]: sort ?? "asc",
                    ["sort-by"]: sortBy ?? "createdAt",
                    ...!types ? undefined : { ["types"]: types },
                }
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["paginator"]: TPaginate<IUserPoint>;
            }>;

         return new Paginate(payload.data.paginator, UserPoint);
        }
        catch (error: any) {
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }

}



export default UserPoint;