import { Dialog, DialogContent, DialogTitle, Drawer } from "@mui/material";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "state";
import icon_appstore from "../../../public/assets/img/icon/appdownload-appstore.svg";
import icon_googleplay from "../../../public/assets/img/icon/appdownload-googleplay.svg";
import { toggleModalDownloadDesktop, toggleModalDownloadMobile } from "state/global/actions";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Loadingv2 from "@Components/Loading/v2";
import { redirectToAppWithAsPath } from "utils/redirectToApp";

const DOWNLOAD_POPUP_DISABLED_PAGE = ["/blog/[categorySlug]/[magazineSlug]"];

export default function FixedDownloadApp() {
   const router = useRouter();
   const dispatch = useDispatch();
   const [loading, setLoading] = useState(false);
   const { visible } = useSelector<RootState>((state) => state.global.modalDownloadDesktop) as { visible: boolean };
   const { visible: visibleMobile } = useSelector<RootState>((state) => state.global.modalDownloadMobile) as { visible: boolean };

   useEffect(() => {
      if (window.innerWidth > 767) return;
      try {
         function showModal() {
            const currentTime = new Date().getTime();
            dispatch(toggleModalDownloadMobile({ visible: true }));
            localStorage.setItem(
               cacheKey,
               JSON.stringify({
                  show: true,
                  lastTime: currentTime,
               })
            );
         }

         const cacheKey = "download:cache";
         const cacheDownloadModal = localStorage.getItem(cacheKey);
         if (cacheDownloadModal) {
            const { lastTime } = JSON.parse(cacheDownloadModal);
            const currentTime = new Date().getTime();
            if (currentTime - Number(lastTime) > 1000 * 60 * 60 * 24) {
               showModal();
            }
         } else {
            showModal();
         }
      } catch (error) {}
   }, []);

   const handleRedirectToApp = () => {
      redirectToAppWithAsPath(router).then((status) => {
         if (!status) {
            dispatch(toggleModalDownloadMobile({ visible: false }));
         }
      });
   };

   if (DOWNLOAD_POPUP_DISABLED_PAGE.includes(router.route)) return <></>;
   return (
      <div className="download-fixed">
         {router.asPath?.includes("/feed") && (
            <button className="btn-download" onClick={() => dispatch(toggleModalDownloadDesktop({ visible: true }))}>
               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M4.41379 2C3.08069 2 2 3.08069 2 4.41379V8.55172C2 9.88483 3.08069 10.9655 4.41379 10.9655H8.55172C9.88483 10.9655 10.9655 9.88483 10.9655 8.55172V4.41379C10.9655 3.08069 9.88483 2 8.55172 2H4.41379ZM4.06897 4.41379C4.06897 4.22335 4.22335 4.06897 4.41379 4.06897H8.55172C8.74217 4.06897 8.89655 4.22335 8.89655 4.41379V8.55172C8.89655 8.74217 8.74217 8.89655 8.55172 8.89655H4.41379C4.22335 8.89655 4.06897 8.74217 4.06897 8.55172V4.41379ZM6.48276 7.86207C7.24453 7.86207 7.86207 7.24453 7.86207 6.48276C7.86207 5.72099 7.24453 5.10345 6.48276 5.10345C5.72099 5.10345 5.10345 5.72099 5.10345 6.48276C5.10345 7.24453 5.72099 7.86207 6.48276 7.86207Z"
                     fill="black"
                  />
                  <path
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M4.41379 13.0345C3.08069 13.0345 2 14.1152 2 15.4483V19.5862C2 20.9193 3.08069 22 4.41379 22H8.55172C9.88483 22 10.9655 20.9193 10.9655 19.5862V15.4483C10.9655 14.1152 9.88483 13.0345 8.55172 13.0345H4.41379ZM4.06897 15.4483C4.06897 15.2578 4.22335 15.1035 4.41379 15.1035H8.55172C8.74217 15.1035 8.89655 15.2578 8.89655 15.4483V19.5862C8.89655 19.7767 8.74217 19.931 8.55172 19.931H4.41379C4.22335 19.931 4.06897 19.7767 4.06897 19.5862V15.4483ZM6.48276 18.8966C7.24453 18.8966 7.86207 18.279 7.86207 17.5172C7.86207 16.7555 7.24453 16.1379 6.48276 16.1379C5.72099 16.1379 5.10345 16.7555 5.10345 17.5172C5.10345 18.279 5.72099 18.8966 6.48276 18.8966Z"
                     fill="black"
                  />
                  <path
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M15.447 2C14.1139 2 13.0332 3.08069 13.0332 4.41379V8.55172C13.0332 9.88483 14.1139 10.9655 15.447 10.9655H19.5849C20.918 10.9655 21.9987 9.88483 21.9987 8.55172V4.41379C21.9987 3.08069 20.918 2 19.5849 2H15.447ZM15.1022 4.41379C15.1022 4.22335 15.2566 4.06897 15.447 4.06897H19.5849C19.7754 4.06897 19.9298 4.22335 19.9298 4.41379V8.55172C19.9298 8.74217 19.7754 8.89655 19.5849 8.89655H15.447C15.2566 8.89655 15.1022 8.74217 15.1022 8.55172V4.41379ZM17.516 7.86207C18.2777 7.86207 18.8953 7.24453 18.8953 6.48276C18.8953 5.72099 18.2777 5.10345 17.516 5.10345C16.7542 5.10345 16.1367 5.72099 16.1367 6.48276C16.1367 7.24453 16.7542 7.86207 17.516 7.86207Z"
                     fill="black"
                  />
                  <path
                     fillRule="evenodd"
                     clipRule="evenodd"
                     d="M20.9407 15.6123C21.2937 15.2592 21.2937 14.6868 20.9407 14.3338C20.5876 13.9807 20.0152 13.9807 19.6621 14.3338L18.8098 15.1861C18.4567 15.5392 18.4567 16.1116 18.8098 16.4646C19.1628 16.8177 19.7353 16.8177 20.0883 16.4646L20.9407 15.6123ZM14.5476 14.3338C14.9007 13.9807 15.4731 13.9807 15.8261 14.3338L16.6785 15.1861C17.0316 15.5392 17.0316 16.1116 16.6785 16.4647C16.3254 16.8177 15.753 16.8177 15.4 16.4647L14.5476 15.6123C14.1946 15.2593 14.1946 14.6868 14.5476 14.3338ZM19.0224 18.8086C19.3754 18.4556 19.9478 18.4556 20.3009 18.8086L21.1532 19.661C21.5063 20.014 21.5063 20.5864 21.1532 20.9395C20.8002 21.2926 20.2278 21.2926 19.8747 20.9395L19.0224 20.0871C18.6693 19.7341 18.6693 19.1617 19.0224 18.8086ZM16.466 18.8086C16.819 19.1617 16.819 19.7341 16.466 20.0871L15.6136 20.9395C15.2606 21.2925 14.6882 21.2925 14.3351 20.9395C13.982 20.5864 13.982 20.014 14.3351 19.661L15.1875 18.8086C15.5405 18.4556 16.1129 18.4556 16.466 18.8086ZM17.5928 18.4968C18.0921 18.4968 18.4969 18.092 18.4969 17.5927C18.4969 17.0934 18.0921 16.6887 17.5928 16.6887C17.0935 16.6887 16.6888 17.0934 16.6888 17.5927C16.6888 18.092 17.0935 18.4968 17.5928 18.4968Z"
                     fill="black"
                  />
               </svg>
               <div className="text">Tải ứng dụng</div>
            </button>
         )}

         {/* Desktop modal */}
         <Dialog open={Boolean(visible)} className="download-dialog" onClose={() => dispatch(toggleModalDownloadDesktop({ visible: false }))}>
            <div className="close-icon" onClick={() => dispatch(toggleModalDownloadDesktop({ visible: false }))}>
               <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path
                     d="M0.170733 16.3399C0.244167 16.4133 0.328615 16.461 0.424079 16.4831C0.519543 16.5051 0.615007 16.5051 0.71047 16.4831C0.805934 16.461 0.894054 16.4133 0.974831 16.3399L8.00243 9.30126L15.041 16.3399C15.1512 16.4427 15.2834 16.4941 15.4376 16.4941C15.5918 16.5014 15.724 16.45 15.8341 16.3399C15.9443 16.2224 15.9994 16.0865 15.9994 15.9323C15.9994 15.7781 15.9443 15.6459 15.8341 15.5358L8.80653 8.49717L15.8341 1.46957C15.9443 1.35942 15.9994 1.22724 15.9994 1.07303C16.0067 0.911472 15.9516 0.77562 15.8341 0.66547C15.724 0.555319 15.5918 0.500244 15.4376 0.500244C15.2834 0.500244 15.1512 0.555319 15.041 0.66547L8.00243 7.70408L0.974831 0.66547C0.894054 0.592036 0.805934 0.544304 0.71047 0.522274C0.615007 0.500244 0.519543 0.500244 0.424079 0.522274C0.328615 0.544304 0.244167 0.592036 0.170733 0.66547C0.0972996 0.746247 0.045896 0.834367 0.0165226 0.929831C-0.00550752 1.02529 -0.00550752 1.12076 0.0165226 1.21622C0.045896 1.31169 0.0972996 1.39613 0.170733 1.46957L7.19833 8.49717L0.170733 15.5358C0.0972996 15.6092 0.045896 15.6937 0.0165226 15.7891C-0.00550752 15.8846 -0.00550752 15.9801 0.0165226 16.0755C0.045896 16.1783 0.0972996 16.2664 0.170733 16.3399Z"
                     fill="#252A2B"
                  />
               </svg>
            </div>
            <DialogTitle> Tải ứng dụng Space T</DialogTitle>
            <div className="dialog-content">
               <div className="text-top">Hãy quét mã QR để tải Space T về máy</div>
               <div className="qr-code">
                  <Image width={160} height={160} src="/assets/img/icon/QR_Code_spacet.png" alt="QR Code Space T" />
               </div>
               <div className="text-bot">Tải về từ cửa hàng ứng dụng</div>
               <div className="wrap-download">
                  <a
                     href="https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398"
                     target="_blank"
                     rel="noreferrer"
                  >
                     <Image src={icon_appstore} alt="Space T Application" />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.spacet.mobile" target="_blank" rel="noreferrer">
                     <Image src={icon_googleplay} alt="Space T Application" />
                  </a>
               </div>
            </div>
         </Dialog>

         {/* Mobile drawer */}
         <Drawer
            className="download-drawer-mobile"
            anchor="bottom"
            open={Boolean(visibleMobile)}
            onClose={() => {
               dispatch(toggleModalDownloadMobile({ visible: false }));
            }}
         >
            <div className="drawer-header">
               <div className="drawer-title">Tận hưởng trải nghiệm đầy đủ trong ứng dụng</div>
               <div className="drawer-description">Khám phá điều mới mẻ mỗi ngày</div>
            </div>
            <div className="drawer-content">
               <div className="drawer-item">
                  <div className="d-flex align-items-center">
                     <img className="item-logo" src="/assets/img/icon/spacet-app.png" alt="" />
                     <span className="text">SpaceT App</span>
                  </div>
                  <a
                     className="btn-open-app d-none d-md-block"
                     href={
                        typeof navigator !== "undefined" && navigator?.userAgent?.toLowerCase()?.includes("android")
                           ? "https://play.google.com/store/apps/details?id=com.spacet.mobile"
                           : "https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398"
                     }
                     target="_blank"
                     rel="noreferrer"
                  >
                     Mở Ứng dụng
                  </a>
                  <a
                     className="btn-open-app d-block d-md-none"
                     //href="https://spacet.page.link/open-app" target="_blank" rel="noreferrer"
                     onClick={handleRedirectToApp}
                  >
                     Mở Ứng dụng
                  </a>
               </div>
               <div className="drawer-item">
                  <div className="d-flex align-items-center">
                     <img className="item-logo" src="/assets/img/icon/spacet-browser.png" alt="" />
                     <span className="text">Trình duyệt</span>
                  </div>
                  <button
                     className="btn-continue"
                     onClick={() => {
                        // setLoading(true);
                        dispatch(toggleModalDownloadMobile({ visible: false }));
                        // setTimeout(() => {
                        //    setLoading(false);
                        // }, 2000);
                     }}
                  >
                     Tiếp tục {loading && <Loadingv2 scale="small" />}
                  </button>
               </div>
            </div>
         </Drawer>
      </div>
   );
}
