/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import Comment, { ECommentSortBy, IComment } from "./comment.model";

/**
 * Core(s)
 */
import { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import { QnA } from "./qna.model";
import User from "./user.model";
export interface IQnAComment extends IComment {
    "__replies"?: QnAComment[];
    ["qnaId"]?: string | null;

    ["__qna"]?: QnA | null;
}

export class QnAComment extends Comment<IQnAComment> implements IQnAComment {
    public ["qnaId"]!: string | null;
    public ["__qna"]!: QnA | null;
    public "__replies"?: QnAComment[];

    constructor(data?: IQnAComment) {
        super(data);
        Object.assign(this, data);
        this.__user && (this.__user = new User(this.__user));
        if (this?.__replies) {
            this.__replies = this.__replies.map((reply) => new QnAComment(reply));
        }
        if (this.__qna && !(this.__qna instanceof QnA)) {
            this.__qna = new QnA(this.__qna);
        }
    }



    public static async paginate({
        id, commentId, page, limit, sort, sortBy, offset, cookiesToken
    }: {
        id: string;
        commentId?: string;
        page?: number;
        limit?: number;
        sort?: "asc" | "desc";
        sortBy?: ECommentSortBy,
        offset?: number,
        cookiesToken?: string

    }) {
        try {
            const token = typeof window !== 'undefined' && window.localStorage.getItem(tokenKey);
            const lastToken = cookiesToken || token
            const response = await fetch(`${apiDomain}/www/qna/comment/paginate`, {
                ["method"]: "GET",
                // ["cache"]: "no-store",
                "headers": {
                    ["Origin"]: 'https://spacet.vn',
                    "X-Requested-With": "XMLHttpRequest",
                    "id": id,
                    ...!lastToken ? undefined : {
                        ["Authorization"]: `Bearer ${lastToken}`
                    },
                    ...!page ? undefined : { "page": String(page) },
                    ...!offset ? undefined : { "offset": String(offset) },
                    "limit": !limit ? String(20) : String(limit),
                    "sort": !sort ? "desc" : encodeURI(sort),
                    "sort-by": !sortBy ? ECommentSortBy.createdAt : encodeURI(sortBy),
                    ...!commentId ? undefined : { "qna-comment-id": commentId },
                }
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["paginator"]: TPaginate<IQnAComment>;
            }>;

            return new Paginate(payload.data.paginator, QnAComment);
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }
    public static async newQnAComment(qnaId: string, content: string, qnaCommentId?: string) {
        try {
            const token = localStorage.getItem(tokenKey);

            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "You don't have permission to access this API.",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng xuất không thành công."
                            }
                        ]
                    }
                });
            }

            const response = await fetch(`${apiDomain}/www/qna/comment`, {
                ["method"]: "POST",
                // ["cache"]: "no-store",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["Content-Type"]: "application/json",
                    ["Authorization"]: `Bearer ${token}`
                },
                ["body"]: JSON.stringify({
                    ["qnaId"]: qnaId,
                    ["content"]: content,
                    qnaCommentId: qnaCommentId ?? null
                })
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["newQnAComment"]: QnAComment;
            }>;

            return new QnAComment(payload.data.newQnAComment);
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }

    /**
* 
* @param socialFeedCommentId
* @param file
* @returns 
*/
    public static async commentImage(file: File, qnaCommentId: string) {
        try {
            const token = localStorage.getItem(tokenKey);

            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "You don't have permission to access this API.",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng xuất không thành công."
                            }
                        ]
                    }
                });
            }

            const data = new FormData();
            data.append('file', file);
            data.append('qnaCommentId', qnaCommentId);
            const res = await fetch(`${apiDomain}/www/qna/comment/image`, {
                "method": "POST",
                "headers": {
                    "Authorization": `Bearer ${token}`,
                    "X-Requested-With": "XMLHttpRequest",
                },
                "body": data
            });
            if (res instanceof ModelError) {
                return res;
            }
            const payload = await res.json() as TPayload<{
                comment: QnAComment
            }>
            return new QnAComment(payload.data.comment);
        }
        catch (error) {
            return new ModelError(error);
        }
    }

    /**
* 
* @param id
* @returns 
*/
    public static async delete(id: string) {
        const token = localStorage.getItem(tokenKey);
        if (!token) {
            return new ModelError({
                "httpCode": 401,
                "message": "401 Unauthorized error",
                "errors": {
                    "process": [
                        {
                            "code": "401",
                            "message": "401 Unauthorized error"
                        }
                    ]
                }
            });
        }
        try {
            const response = await fetch(`${apiDomain}/www/qna/comment`, {
                "method": "DELETE",
                "headers": {
                    "X-Requested-With": "XMLHttpRequest",
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": 'application/json',
                    "ids": id
                },

            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            return;
        }
        catch (error: any) {
            return new ModelError({
                "httpCode": 500,
                "message": error.message as string,
                "errors": {
                    "process": [
                        {
                            "code": "process.error.5000",
                            "message": "Process error on handling."
                        }
                    ]
                }
            });
        }
    }


}

export default QnAComment;