import React, { PropsWithChildren } from "react";

const Svg = ({ children, ...props }): React.ReactElement<PropsWithChildren> => {
   return <svg {...props}>{children}</svg>;
};

Svg.defaultProps = {
   width: "20px",
   xmlns: "https://www.w3.org/2000/svg"
   // spin: false,
};

export default Svg;
