import { createContext, FunctionComponent, useCallback, useContext, useEffect, useState } from "react";

// Utilities
import axios, { AxiosInstance } from "axios";
import Cookie from "cookie";
import Cookies from "js-cookie";
import { GetServerSidePropsContext } from "next";
import { ConfigInterface, keyInterface, mutate, SWRConfig } from "swr";
import { apiDomain } from "@Models/__variables";

// Contexts

// Config

export const createHttp = (token?: any, baseURL: string = apiDomain): AxiosInstance => {
   const http = axios.create({
      baseURL: baseURL,
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
      },
   });

   if (token) {
      http.defaults.headers.Authorization = `Bearer ${token}`;
   } else {
      http.interceptors.request.use((config: any) => {
         const cookiesToken = Cookies.get("userToken");
         if (cookiesToken) {
            config.headers["Authorization"] = `Bearer ${cookiesToken}`;
         }

         return config;
      });
   }

   if (process.env.NODE_ENV === "development") {
      http.interceptors.request.use((config) => {
         return config;
      });
   }

   // TODO: Check when call api with invalid token
   // http.interceptors.response.use(
   //     (response) => response,
   //     (error: Error) => {
   //         return Promise.reject(error);
   //     },
   // );

   // http.defaults.paramsSerializer = (params: any) => {
   //     return QueryString.stringify(params);
   // };

   return http;
};

export const http: AxiosInstance = ((): AxiosInstance => {
   return createHttp();
})();

export const reinitialize = (token?: string, baseURL: string = apiDomain): AxiosInstance => {
   return createHttp(token, baseURL);
};

export const getServerSideHttp = (context: GetServerSidePropsContext, baseURL = apiDomain): AxiosInstance => {
   const cookies = Cookie.parse(context.req.headers.cookie || "");
   return createHttp(cookies.token, baseURL);
};

interface ContextType {
   token: any;
   userHttp: AxiosInstance;
   setToken(token: string): void;
}

const CONTEXT_VALUE: ContextType = {
   token: null,
   setToken: () => null,
   userHttp: createHttp(null, apiDomain),
};

export const HttpContext = createContext(CONTEXT_VALUE);

export const useHttpContext = () => useContext(HttpContext);

interface HttpWrapperProps {
   token: string;
   onError?(error: Error, key: keyInterface): void;
   children: any;
}

export const HttpWrapper: FunctionComponent<HttpWrapperProps> = (props) => {
   let userHttp = createHttp(props.token, apiDomain);

   const [token, setToken] = useState<string>(props.token);

   useEffect(() => {
      handleSetToken(props.token);
   }, [props.token]);

   useEffect(() => {
      if (typeof window !== "undefined") {
         const token = Cookies.get("userToken");
         handleSetToken(token);
      }
   }, []);

   const handleSetToken = useCallback((token: any) => {
      setToken(token);

      userHttp = createHttp(token, apiDomain);
   }, []);

   const handleHttpError = useCallback((error: any, key: any) => {
      if (props.onError) {
         props.onError(error, key);
      }

      if (error?.response?.status === 401 && Cookies.get("userToken")) {
         Cookies.remove("userToken");
         handleSetToken(null);
         mutate(key);
      }

      alert({
         type: "error",
         title: "Đã xảy ra lỗi",
         text: "132",
      });
   }, []);

   const httpContextValue: ContextType = {
      token,
      userHttp,
      setToken: handleSetToken,
   };

   return (
      <SWRConfig
         value={{
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            onError: handleHttpError,
         }}
      >
         <HttpContext.Provider value={httpContextValue}>{props.children}</HttpContext.Provider>
      </SWRConfig>
   );
};

// export const getHttpErrorMessage = (error: AxiosError) => {
//     const defaultMsg = 'Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ đến đội ngũ phát triển.';

//     try {
//         const response: any = error.response || null;
//         const data: any = response.data || {};

//         return data.userMessage || defaultMsg;
//     } catch (error) {
//         return defaultMsg;
//     }
// };

export interface HttpOptions extends ConfigInterface {
   auth?: boolean;
}

// export const useHttp = (
//     key: keyInterface,
//     fetcher?: fetcherFn<any>,
//     options?: HttpOptions,
// ): responseInterface<any, any> => {
//     const { token, userHttp, cmsHttp, doctorHttp } = useHttpContext();
//     const { auth = false, ...swrOptions } = options || {};

//     const isAuthenticated = useMemo(() => {
//         if (!auth) return true;

//         return !!token;
//     }, [token]);

//     return useSWR(!isAuthenticated ? null : key, fetcher.bind(null, { userHttp, cmsHttp, doctorHttp }), swrOptions);
// };
