/**
 * Model(s)
 */
import React from "react";
import { ModelError } from "../../models/model";
import ProductVariant, { EStockStatus } from "../../models/productVariant.model";

interface ICartStorage {
    readonly tokenCartKey: string;
}

export type TCartItem = {
    readonly variantId: string;
    quantity: number;
}

export type TVirtualCartItem = {
    readonly variant: ProductVariant;
    quantity: number;
    price: number;
    priceAfterDiscount: number;
    ["$cartItems"]?: any | null;
    ["$virtualCartItems"]?: any | null;
    ["$totalVirtualPrice"]?: any | null;
}

export class CartStorage implements ICartStorage {

    public readonly tokenCartKey: string = "cart";
    private _listeners: React.Component[] = [];

    private _cartStorage: TCartItem[] = [];
    private _virtualCartStorage: TVirtualCartItem[] = [];
    public ["$cartItems"]?: any | null;
    public ["$virtualCartItems"]?: any | null;
    public ["$totalVirtualPrice"]?: any | null;

    constructor() {
        this.refreshCart();
        // Update cart when localstorage change
        if (typeof window !== 'undefined') {

            window.addEventListener("storage", (e: StorageEvent) => {
                // Refresh cart
                this.refreshCart();

                // Call components update
                for (let i = 0; i < this._listeners.length; i++) {
                    this._listeners[i].setState({ ...this._listeners[i].state });
                }
            }, true);
        }
        this.$cartItems = this._cartStorage
        this.$virtualCartItems = this._virtualCartStorage
        this.$totalVirtualPrice = () => {
            let totalPrice: any = null;

            for (let i = 0; i < this._virtualCartStorage.length; i++) {
                if (totalPrice === null) {
                    totalPrice = 0;
                }
                if (!this._virtualCartStorage[i].price && !this._virtualCartStorage[i].priceAfterDiscount) {
                    return
                } else if (this._virtualCartStorage[i].price && !this._virtualCartStorage[i].priceAfterDiscount) {
                    totalPrice += this._virtualCartStorage[i].price * this._virtualCartStorage[i].quantity;
                } else if (this._virtualCartStorage[i].priceAfterDiscount && this._virtualCartStorage[i].price) {
                    totalPrice += this._virtualCartStorage[i].priceAfterDiscount * this._virtualCartStorage[i].quantity;
                } else {
                    return
                }
            }

            return totalPrice;
        }
    }

    /**
     * Add item to cart; if exists, plus item.quantity to current value
     * @param item
     * @returns
     */
    public addToCart(item: TCartItem, isSet: boolean = false) {
        try {
            if (item.quantity < 1 && isSet) {
                return;
            }
            const cartItemIndex = this._cartStorage.findIndex(cartItem => cartItem.variantId === item.variantId);
            if (cartItemIndex < 0) {
                this._cartStorage.push(item);
            }
            else {
                // Check cart
                for (let i = 0; i < this._cartStorage.length; i++) {
                    if (this._cartStorage[i].variantId !== item.variantId) {
                        continue;
                    }
                    if (!isSet) {
                        if (this._cartStorage[i].quantity + item.quantity < 1) {
                            continue;
                        }
                        this._cartStorage[i].quantity += item.quantity;
                        continue;
                    }

                    if (item.quantity < 1) {
                        continue;
                    }

                    this._cartStorage[i].quantity = item.quantity;
                }

                // Check virtual cart
                for (let i = 0; i < this._virtualCartStorage.length; i++) {
                    if (this._virtualCartStorage[i].variant._id !== item.variantId) {
                        continue;
                    }

                    if (!isSet) {
                        if (this._virtualCartStorage[i].quantity + item.quantity < 1) {
                            continue;
                        }

                        this._virtualCartStorage[i].quantity += item.quantity;
                        continue;
                    }

                    if (item.quantity < 1) {
                        continue;
                    }

                    this._virtualCartStorage[i].quantity = item.quantity;
                }
            }

            this.backup();
        }
        catch (error) {
            console.error(error);
        }
    }

    /**
     *
     * @param variantId
     */
    public removeFromCart(variantId: string) {
        try {
            const itemIndex = this._cartStorage.findIndex(item => item.variantId === variantId);
            const virtualItemIndex = this._virtualCartStorage.findIndex(item => item.variant._id === variantId);

            if (itemIndex > -1) {
                this._cartStorage.splice(itemIndex, 1);
            }

            if (virtualItemIndex > -1) {
                this._virtualCartStorage.splice(virtualItemIndex, 1);
            }

            this.backup();
        }
        catch (error) {
            console.error(error);
        }
    }

    /**
     * Backup cart data to localstorage
     */
    public backup() {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(this.tokenCartKey, JSON.stringify(this._cartStorage));
            window.dispatchEvent(new StorageEvent("storage"));
        }
    }

    /**
     *
     */
    public async fetchVirualCart() {
        try {
            const willRemoveItems: string[] = [];
            const temporaryVirtualCart: TVirtualCartItem[] = [];

            for (let i = 0; i < this._cartStorage.length; i++) {

                const variant = await ProductVariant.getVariantInCart(this._cartStorage[i].variantId);
                if (variant instanceof ModelError) {
                    if (variant.httpCode === 404) {
                        willRemoveItems.push(this._cartStorage[i].variantId);
                    }
                    continue;
                }
                // if (variant.options.stockStatus === EStockStatus.OutStock) {
                //     willRemoveItems.push(this._cartStorage[i].variantId);
                //     continue;
                // }
                if (!variant?.info?.price || variant.options.stockStatus === EStockStatus.OutStock) {
                    willRemoveItems.push(this._cartStorage[i].variantId);
                    continue;
                }

                temporaryVirtualCart.push({
                    ["variant"]: variant,
                    ["quantity"]: this._cartStorage[i].quantity,
                    ["price"]: variant.info.price,
                    ["priceAfterDiscount"]: !variant?.info?.priceAfterDiscount ? 0 : variant.info.priceAfterDiscount
                });
            }

            if (willRemoveItems.length > 0) {
                this._cartStorage = this._cartStorage.filter(item => !willRemoveItems.includes(item.variantId));
                this.backup();
            }
            this._virtualCartStorage = temporaryVirtualCart;
        }
        catch (error) {
            console.error(error);
        }
    }

    /**
     *
     */
    public refreshCart() {
        const cartData = typeof window !== 'undefined' && window.localStorage.getItem(this.tokenCartKey);

        this._cartStorage = !cartData ? [] : (() => {
            try {
                const cartParsed = JSON.parse(cartData);

                if (!Array.isArray(cartParsed)) {
                    return [];
                }

                return cartParsed;
            }
            catch (error: any) {
                return [];
            }
        })();

        if (!cartData) {
            this.backup();
        }
    }

    // public removeItemCart() {
    //     // this._cartStorage.splice(0, this._cartStorage.length);
    //     // this._virtualCartStorage.splice(0, this._virtualCartStorage.length);
    //     const i =
    //     this.backup();
    // }
    public resetCart() {
        this._cartStorage.splice(0, this._cartStorage.length);
        this._virtualCartStorage.splice(0, this._virtualCartStorage.length);
        this.backup();
    }


    /**
     * Register component(s) want to update on cart change
     * @param components
     */
    public subscribe(...components: React.Component[]) {
        for (let i = 0; i < components.length; i++) {
            if (!this._listeners.includes(components[i])) {
                this._listeners.push(components[i]);
            }
        }
    }

    /**
     * Unregister component(s) listening on cart change
     * @param components
     */
    public unsubscribe(...components: React.Component[]) {
        for (let i = 0; i < components.length; i++) {
            if (!this._listeners.includes(components[i])) {
                continue;
            }

            const componentIndex = this._listeners.findIndex(listener => listener === components[i]);

            if (componentIndex < 0) {
                continue;
            }

            this._listeners.splice(componentIndex, 1);
        }
    }

    /**
     * Get total quantity in cart
     */
    // get $totalQuantity() {
    //     let totalQuantity: number | null = null;

    //     for (let i = 0; i < this._cartStorage.length; i++) {
    //         if (totalQuantity === null) {
    //             totalQuantity = 0;
    //         }

    //         totalQuantity += this._cartStorage[i].quantity;
    //     }

    //     return totalQuantity;
    // }

    /**
     * Get all cart items
     */
    // get $cartItems() {
    //     return this._cartStorage;
    // }

    /**
     *
     */
    // get $virtualCartItems() {
    //     return this._virtualCartStorage;
    // }

    /**
     *
     */
    // get $totalVirtualPrice() {
    //     let totalPrice = null;

    //     for (let i = 0; i < this._virtualCartStorage.length; i++) {
    //         if (totalPrice === null) {
    //             totalPrice = 0;
    //         }
    //         if (!this._virtualCartStorage[i].price && !this._virtualCartStorage[i].priceAfterDiscount) {
    //             return
    //         } else if (this._virtualCartStorage[i].price && !this._virtualCartStorage[i].priceAfterDiscount) {
    //             totalPrice += this._virtualCartStorage[i].price * this._virtualCartStorage[i].quantity;
    //         } else if (this._virtualCartStorage[i].priceAfterDiscount && this._virtualCartStorage[i].price) {
    //             totalPrice += this._virtualCartStorage[i].priceAfterDiscount * this._virtualCartStorage[i].quantity;
    //         } else {
    //             return
    //         }
    //     }

    //     return totalPrice;
    // }

    /**
     *
     * @param value
     * @param locales
     * @param options
     * @returns
     */
    public static formatPrice(value: number, locales: string | string[] | undefined = "vi", options?: Intl.NumberFormatOptions | undefined) {
        return new Intl.NumberFormat(locales, options).format(value) + '₫';
    }
}

const cartStorageInstance = new CartStorage();

export default cartStorageInstance;