/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { Paginate, ModelError, TModelError, TPayload, TPaginate } from "./model";
import { User } from "./user.model";
import { QnAImage } from "./qnaImage.model";
import { QnAComment } from "./qnaComment.model";
import UserSubscribe from "./userSubscribe.model";
import QnATopic from "./qnaTopic.model";
import UserBookmark from "./userBookmark.model";

export enum EStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected",
    deleted = "deleted"
}
export enum EOptionStatus {
    owned = "owned",
    answered = "answered"
}

export enum EFilterQnA {
    recent = "recent",
    popular = "popular",
    unanswered = "unanswered"
}


export interface IQnA extends Model {
    ["info"]?: {
        ["title"]?: string | null;
        ["slug"]?: string | null;
        ["content"]?: string | null;
        ["publishedAt"]?: Date | null;
        ["lastCommentAt"]?: Date | null;
        ["commentCount"]?: number | null;
    };
    ["shortId"]?: string | null;
    ["options"]?: {
        ["status"]?: EStatus;

    };
    ["__reactionCount"]?: {
        dislike?: number
        like?: number
    }
    ["userId"]?: string | null;
    ["qnaTopicIds"]?: string[];
    ["__user"]?: User | null;
    ["__images"]?: QnAImage[];
    ["__comments"]?: QnAComment[];
    ["__subscribedByCurrentUser"]?: UserSubscribe;
    ["__qnaTopics"]?: QnATopic[];
    ["__currentUserReactions"]?: any;
    ['__currentUserBookmark']?: UserBookmark
}

export class QnA extends Model<IQnA> implements IQnA {
    public ["info"]!: {
        ["title"]?: string | null;
        ["slug"]?: string | null;
        ["content"]?: string | null;
        ["publishedAt"]?: Date | null;
        ["lastCommentAt"]?: Date | null;
        ["commentCount"]?: number | null;
    };
    public ["options"]!: {
        ["status"]?: EStatus;
    };
    public ["__reactionCount"]!: {
        ["dislike"]?: number;
        ["like"]?: number;
    };
    public ["userId"]!: string | null;
    public ["shortId"]?: string | null;
    public ["qnaTopicIds"]?: string[];
    public ["__user"]!: User | null;
    public ["__images"]!: QnAImage[];
    public ["__comments"]!: QnAComment[];
    public ["__subscribedByCurrentUser"]?: UserSubscribe;
    public ["__qnaTopics"]?: QnATopic[]
    public ['__currentUserBookmark']?: UserBookmark
    public ['__currentUserReactions']?: any

    constructor(data?: IQnA) {
        super(data);

        Object.assign(this, data);

        if (this.__user) {
            this.__user = new User(this.__user);
        }

        if (this.__images) {
            this.__images = this.__images.map(image => new QnAImage(image));
        }

        if (this.__subscribedByCurrentUser) {
            this.__subscribedByCurrentUser = new UserSubscribe(this.__subscribedByCurrentUser);
        }
        if (this.__qnaTopics) {
            this.__qnaTopics = this.__qnaTopics.map((topic) => new QnATopic(topic))
        }
        if (this.__currentUserBookmark) {
            this.__currentUserBookmark = new UserBookmark(this.__currentUserBookmark);
        }
    }

    public static async paginate({
        keywords, page, limit, sort, sortBy, creatorId, offset, filter, topicSlug, cookiesToken
    }: {
        keywords?: string,
        page?: number,
        limit?: number,
        sort?: "asc" | "desc",
        sortBy?: "info.publishedAt",
        creatorId?: string,
        offset?: number,
        filter?: EFilterQnA,
        topicSlug?: string,
        cookiesToken?: string
    }

    ) {
        try {
            const token = typeof window !== 'undefined' && window.localStorage.getItem(tokenKey);
            const lastToken = cookiesToken || token;
            const response = await Model.fetchData({
                method: 'GET',
                endPoint: 'qna/paginate',
                headers: {
                    limit: String(limit ?? 12),
                    ...!lastToken ? undefined : { Authorization: `Bearer ${lastToken}` },
                    ...!topicSlug ? undefined : { ['topic-slug']: topicSlug },
                    ...!page ? undefined : { page: String(page) },
                    ...offset === undefined || offset === null ? undefined : { offset: String(offset) },
                    ...!sort ? undefined : { sort },
                    ...!sortBy ? undefined : { 'sort-by': sortBy },
                    ...creatorId ? { ['creator-id']: creatorId } : undefined,
                    ...!keywords ? undefined : { ["keywords"]: encodeURI(keywords) },
                    ...!filter ? undefined : { ["filter"]: encodeURI(filter) }
                }
            })

            if (response instanceof ModelError) {
                return response;
            }

            const payload = await response.json() as TPayload<{
                ["paginator"]: TPaginate<IQnA>;
            }>;

            const paginate = new Paginate(payload.data.paginator, QnA);

            return paginate;
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }

    public static async getQnAById({ shortId, slug, id, cookiesToken }: { shortId: string, slug: string, id?: string, cookiesToken?: string }) {
        try {
            const token = typeof window !== 'undefined' && window.localStorage.getItem(tokenKey);
            const lastToken = cookiesToken || token

            const response = await fetch(`${apiDomain}/www/qna`, {
                ["method"]: "GET",
                // ["cache"]: "no-store",
                ["headers"]: {
                    ["Origin"]: 'https://spacet.vn',
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ...!lastToken ? undefined : {
                        ["Authorization"]: `Bearer ${lastToken}`
                    },
                    ["short-id"]: encodeURI(shortId),
                    ["slug"]: encodeURI(slug),
                    ...!id ? undefined : { ["id"]: encodeURI(id) },
                }
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["qnaPost"]: IQnA;
            }>;

            return new QnA(payload.data.qnaPost)
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }
    public static async getAllSlugQnA() {
        try {
            const response = await fetch(`${apiDomain}/www/general/questions-and-answer-slugs`, {
                ["method"]: "GET",
                ["cache"]: "no-store",
                ["headers"]: {
                    ["Origin"]: 'https://spacet.vn',
                    ["X-Requested-With"]: "XMLHttpRequest",
                }
            });
            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json();

            const paginate = new Paginate(payload?.data, QnA);

            return paginate;
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }

    public static async createQnAPost(data: {
        info: {
            title: string;
            content: string;
        },
        imageIds?: string[],
        qnaTopicIds: string[];
    }) {
        try {
            const token = localStorage.getItem(tokenKey);

            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "You don't have permission to access this API.",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng xuất không thành công."
                            }
                        ]
                    }
                });
            }

            const response = await fetch(`${apiDomain}/www/qna`, {
                ["method"]: "POST",
                // ["cache"]: "no-store",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["Content-Type"]: "application/json",
                    ["Authorization"]: `Bearer ${token}`
                },
                ["body"]: JSON.stringify(data)
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["newQnAPost"]: IQnA;
            }>;

            return new QnA(payload.data.newQnAPost);
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }






}

export default QnA;