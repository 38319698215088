import React from "react";
import Svg from "../Svg";

const Icon = props => (
   <Svg viewBox="0 0 164 120" fill="none" {...props}>
      <path d="M117.653 57.9973C117.653 75.8772 103.158 90.3716 85.2786 90.3716C67.3988 90.3716 52.9043 75.8772 52.9043 57.9973C52.9043 40.1175 67.3988 25.623 85.2786 25.623C103.158 25.623 117.653 40.1175 117.653 57.9973Z" stroke="#E2E4EB" strokeWidth="12"/>
      <path d="M106.457 89.0449L115.284 80.218L126.869 91.8027C129.306 94.2402 129.306 98.1921 126.869 100.63C124.431 103.067 120.479 103.067 118.042 100.63L106.457 89.0449Z" fill="#E2E4EB"/>
      <path d="M61.2705 77.9713L71.2108 70.5739L71.9043 64.7946L80.92 56.7036L81.6135 44.4516L86.2369 39.1347L84.6187 34.9736" stroke="#E0E0E0"/>
      <path d="M71.5445 70.3942L61.373 77.7916C73.2398 85.9597 97.5015 88.334 105.295 72.9371C112.939 57.8368 94.3534 37.7221 84.7213 34.7939L86.5706 39.1862L82.1784 43.8096L81.0225 56.7551L72.0069 64.6149L71.5445 70.3942Z" fill="#FCFCFC"/>
      <path d="M97.857 60.9297L95.3866 55.4995" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M99.5912 57.9267L93.6532 58.5024" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M90.1129 74.3428L87.6425 68.9126" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <path d="M91.8461 71.3408L85.9081 71.9165" stroke="black" strokeWidth="2" strokeLinecap="round"/>
      <ellipse cx="85.5577" cy="60.5017" rx="5.08575" ry="2.77405" transform="rotate(120 85.5577 60.5017)" fill="#334750"/>
      <ellipse cx="94.0855" cy="51.2787" rx="3.46756" ry="0.462341" transform="rotate(120 94.0855 51.2787)" fill="#D9D9D9"/>
      <ellipse cx="81.1402" cy="73.7015" rx="3.46756" ry="0.462341" transform="rotate(120 81.1402 73.7015)" fill="#D9D9D9"/>
      <path d="M32.5723 63.0838L34.884 65.8578L40.1165 64.5977L51.0659 70.0189L57.2626 60.3551L61.6997 62.3903L68.922 57.998L69.5595 58.4695" stroke="#E0E0E0" strokeLinecap="round"/>
      <path d="M52.0389 85.5076C33.3603 85.5076 31.1565 70.4045 32.3894 62.8529L34.9322 65.8581L40.018 64.7023L51.1142 70.0192L57.3558 60.5412L61.748 62.3906L68.9143 58.2295C69.0684 58.2295 69.5153 58.2757 70.0702 58.4607C70.0702 59.6165 75.3871 85.5076 52.0389 85.5076Z" fill="#FCFCFC"/>
   </Svg>
);

export default Icon;
