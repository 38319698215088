/**
 * Model(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import ActualWork from "./actualWork.model";
import ActualWorkReaction from "./actualWorkReaction.model";
import Comment, { ECommentSortBy, IComment } from "./comment.model";
import { ModelError, Paginate, TModelError, TPaginate, TPayload } from "./model";
import User from "./user.model";



export interface IActualWorkComment extends IComment {
   ["__reactionCount"]?: number;
   "actualWorkId": string | null;
   "actualWorkCommentId": string | null;
   "__actualWork"?: ActualWork | null;
   "__actualWorkComment"?: ActualWorkComment | null;
   "__reaction"?: ActualWorkReaction | null;
   "__replies"?: ActualWorkComment[];
}

export class ActualWorkComment extends Comment<IActualWorkComment> implements IActualWorkComment {
   public ["__reactionCount"]?: number;
   public "actualWorkId"!: string | null;
   public "actualWorkCommentId"!: string | null;

   public "__actualWork"!: ActualWork | null;
   public "__actualWorkComment"!: ActualWorkComment | null;
   public "__reaction"!: ActualWorkReaction | null;

   public "__replies"!: ActualWorkComment[];

   constructor (data?: IActualWorkComment) {
      super(data);
      Object.assign(this, data);
      if (this?.__reaction) {
         this.__reaction = new ActualWorkReaction(this.__reaction);
      }

      if (this?.__replies) {
         this.__replies = this.__replies.map((reply) => new ActualWorkComment(reply));
      }

      if (this?.__actualWork) {
         this.__actualWork = new ActualWork(this.__actualWork);
      }

      if (this?.__actualWorkComment) {
         this.__actualWorkComment = new ActualWorkComment(this.__actualWorkComment);
      }
      if (this?.__user) {
         this.__user = new User(this.__user);
      }

      if (this?.__replyToUsers) {
         this.__replyToUsers = this.__replyToUsers.map((replyToUser) => new User(replyToUser));
      }
   }


   public static async paginate({
      id, commentId, page, limit, sort, sortBy,
   }: {
      id: string;
      commentId?: string;
      page?: number;
      limit?: number;
      sort?: "asc" | "desc";
      sortBy?: ECommentSortBy,

   }) {
      try {
         const token = localStorage.getItem(tokenKey);
         const res = await fetch(`${apiDomain}/www/actualWork/comment/paginate`, {
            "method": "GET",
            "headers": {
               "X-Requested-With": "XMLHttpRequest",
               "id": id,
               ...!token ? undefined : { "Authorization": `Bearer ${token}` },
               "page": !page ? String(1) : String(page),
               "limit": !limit ? String(20) : String(limit),
               "sort": !sort ? "asc" : encodeURI(sort),
               "sort-by": !sortBy ? ECommentSortBy.createdAt : encodeURI(sortBy),
               ...!commentId ? undefined : { "comment-id": commentId },
            }
         });

         if (!res.ok) {
            return new ModelError(await res.json() as TModelError);
         }

         const payload = await res.json() as TPayload<{
            "paginator": TPaginate<IActualWorkComment>;
         }>;

         const paginate = new Paginate(payload.data.paginator, ActualWorkComment);
         return paginate;
      }
      catch (error: any) {
         console.error(error);
         return new ModelError({
            "httpCode": 500,
            "message": error.message as string,
            "errors": {
               "process": [
                  {
                     "code": "process.error.5000",
                     "message": "Process error on handling."
                  }
               ]
            }
         });
      }
   }

}

export default ActualWorkComment;