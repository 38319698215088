/* eslint-plugin-disable react */
/*eslint-disable */


/**
 * Model(s)
 */
import { Model, IModel, ModelError, TModelError, TPayload} from "./model";
import { apiDomain, tokenKey } from "./__variables";

export interface IArchiSketch extends IModel { }
export class ArchiSketch extends Model<IArchiSketch> implements IArchiSketch {
    constructor (data: IArchiSketch) {
        super(data);
        Object.assign(this, data);
    }

    public static async archiSketchLogin() {
        try {
            const token = localStorage.getItem(tokenKey);

            if (!token) {
                return new ModelError({
                    ["httpCode"]: 401,
                    ["message"]: "You don't have permission to access this API.",
                    ["errors"]: {
                        ["process"]: [
                            {
                                ["code"]: "process.error.401",
                                ["message"]: "Đăng nhập không thành công."
                            }
                        ]
                    }
                });
            }

            const res = await fetch(`${apiDomain}/www/archiSketch/signin`, {
                method: 'POST',
                headers: new Headers({
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["Authorization"]: `Bearer ${token}`,
                    ["Content-Type"]: "application/json"
                }),
            });

            if (!res.ok) {
                return new ModelError(await res.json() as TModelError);
            }

            const payload = await res.json() as TPayload<{
                ["channelId"]: string,
                ["externalUserId"]: string,
                ["accessToken"]: string,
                ["refreshToken"]: string,
                ["redirectUrl"]: string
            }>;

            return payload.data;
        }
        catch (error: any) {
            console.error(error)
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }
}

export default ArchiSketch;