import React, { createContext, FunctionComponent, Ref, useContext, useRef, useState } from "react";
import styles from "./style.module.scss";
import classnames from "classnames";
import Image from "next/image";
import ProgressBarRouter from "@Components/ProgressBarRouter";

export interface AppStoreContextType {
   loading: boolean;
   setLoading: (loading: boolean) => void;
   openDialog: boolean;
   setOpenDialog: (loading: boolean) => void;
   error(message?: string, title?: string): void;
   success(message: string, title?: string): void;
   warning: (message: string, title?: string, btnLabel?: string, onClick?: () => null) => void;
   confirm: (message: string, title?: string, btnLabel?: string, onClick?: () => null) => void;
   toggleProgressbar: () => void;
}
const initAppStoreContext: AppStoreContextType = {
   loading: false,
   setLoading: () => null,
   openDialog: true,
   setOpenDialog: () => null,
   error: () => null,
   success: () => null,
   warning: () => null,
   confirm: () => null,
   toggleProgressbar: () => null,
};

export const AppStoreContext = createContext(initAppStoreContext);

export const useAppStoreContext = () => useContext(AppStoreContext);

const DEFAULT_ERROR_MSG = "Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ đến đội ngũ phát triển.";
const initData = {
   type: "",
   icon: "",
   title: "",
   message: "",
   show: false,
   btnLabel: "",
   onClick: () => null,
};
const AppStoreWrapper: FunctionComponent<any> = (props) => {
   const [globalLoading, setGlobalLoading] = useState<boolean>(false);
   const [openDialog, setOpenDialog] = useState<boolean>(true);
   const [dataContext, setDataContext] = useState(initData);
   const [progress, setProgress] = useState<number>(0);

   const setLoading = (isLoading: boolean) => setGlobalLoading(isLoading);
   const setOpenDialogFn = (openDialog: boolean) => setOpenDialog(openDialog);
   const hide = () => setDataContext({ ...dataContext, show: false });
   const toggleProgressbar = () => {
      if (progress > 0 && progress < 100) {
         return;
      }
      let value = 0;
      const timer = setInterval(() => {
         setProgress((oldValue) => {
            const diff = Math.random() * 20;
            value = Math.min(value + diff, 100);
            if (value >= 100) {
               clearInterval(timer);
            }
            return value;
         });
      }, 130);
   };

   const error = (message = DEFAULT_ERROR_MSG, title = "Thất bại") => {
      setDataContext({
         ...dataContext,
         title,
         message,
         show: true,
         type: "error",
      });
   };
   const success = (message: string, title = "Thành công") => {
      setDataContext({
         ...dataContext,
         title,
         message,
         show: true,
         type: "success",
      });
   };
   const warning = (message: string, title = "Cảnh báo", btnLabel = "Xác nhận", onClick = () => null) => {
      setDataContext({
         ...dataContext,
         title,
         onClick: () => {
            if (onClick) {
               onClick();
            }
            return null;
            // hide();
         },
         message,
         show: true,
         type: "warning",
         btnLabel: btnLabel,
      });
   };
   const confirm = (message: string, title = "Xác nhận", btnLabel = "Xác nhận", onClick = () => null) => {
      setDataContext({
         ...dataContext,
         title,
         onClick: () => {
            if (onClick) {
               onClick();
            }
            hide();
            return null;
         },
         message,
         show: true,
         type: "confirm",
         btnLabel: btnLabel,
      });
   };

   // let Icon = dataContext.icon;
   // if (dataContext.type === "success") {
   //    Icon = "/assets/img/icon/icon_success_context.svg";
   // } else if (dataContext.type === "warning") {
   //    Icon = "/assets/img/icon/icon_warning_context.svg";
   // } else if (dataContext.type === "confirm") {
   //    Icon = "/assets/img/icon/icon_warning_context.svg";
   // } else if (dataContext.type === "error") {
   //    Icon = "/assets/img/icon/icon_error_context.svg";
   // }

   const providerValue: AppStoreContextType = {
      error: error,
      success: success,
      warning: warning,
      confirm: confirm,
      setLoading,
      loading: globalLoading,
      openDialog,
      setOpenDialog: setOpenDialogFn,
      toggleProgressbar,
   };

   const loadingClassName = classnames([
      styles.loading,
      {
         [styles.active]: props.loading || globalLoading,
      },
   ]);

   return (
      <AppStoreContext.Provider value={providerValue}>
         <ProgressBarRouter progress={progress} />
         {props.loading || globalLoading && <div className={loadingClassName}>
            <Image width={25} height={25} sizes="25" src={"/assets/img/icon/loading.svg"} alt="Loading" />
         </div>}
         {props?.children}
      </AppStoreContext.Provider>
   );
};

export const AppStoreContextWrapper = React.memo<FunctionComponent<any>>(AppStoreWrapper);
