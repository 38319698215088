/* eslint-disable @next/next/no-img-element */
import classNames from "classnames";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { gridBreakpoints } from "../../../../config";
import { Dialog, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import styles from "../../styles.module.scss";
import UserNotifications, { EUserNotificationActions } from "../../../../models/userNotifications.model";
import Report, { EReportDoc } from "../../../../models/report.model";
import { getTimeFromNow } from "../../../../utils";
import SwipeableViews from "react-swipeable-views";
import { ModelError } from "../../../../models/model";
import Notification from "../../../modal/Notification";

interface IProps {
   noti: UserNotifications;
   onSeen: () => void;
   onChangeNotification?: (noti: UserNotifications) => void;
}

export default function NotificationReport({ noti, onSeen, onChangeNotification }: IProps) {
   const [notificationReport, setNotificationReport] = useState<{ dialog: boolean; drawer: boolean }>({ dialog: false, drawer: false });
   const [appeal, setAppeal] = useState<boolean>(false);
   const [dialogNotification, setDialogNotification] = useState<boolean>(false);
   const [appealSuccess, setAppealSuccess] = useState<boolean>(false);
   const [statusAppeal, setStatusAppeal] = useState<string>("");

   useEffect(() => {
      setAppeal(false);
   }, [notificationReport]);

   const toogleDialogAppeal = () => {
      setAppeal(!appeal);
   };

   const handleOpenNotificationReport = () => {
      if (typeof window === "undefined") return;
      if (window.innerWidth < gridBreakpoints.md) {
         setNotificationReport({ dialog: false, drawer: true });
      } else {
         setNotificationReport({ dialog: true, drawer: false });
      }
   };

   const handleCloseNotificationReport = () => {
      setNotificationReport({ dialog: false, drawer: false });
   };

   const toogleDialogNotification = () => {
      setNotificationReport({ dialog: false, drawer: false });
      setDialogNotification(!dialogNotification);
      setAppealSuccess(false);
   };

   const handleAppeal = async (reason: string) => {
      if (!noti?.__report?._id) {
         return;
      }
      const res = await Report.appeal({ reason, id: noti.__report._id });
      if (res instanceof ModelError) {
         setNotificationReport({ dialog: false, drawer: false });
         setStatusAppeal("Không thể gửi khiểu nại. Vui lòng thử lại sau!");
         return;
      }
      toogleDialogNotification();
      setAppealSuccess(true);
      onChangeNotification &&
         onChangeNotification({ ...noti, __report: { ...noti.__report, info: { ...noti.__report?.info, userAppeal: res.info?.userAppeal } } as Report });
   };

   const handleChangeStatusAppeal = (status: string) => {
      setStatusAppeal(status);
   };

   return (
      <>
         <div
            className={classNames([styles["noti-item"], noti?.options?.action !== EUserNotificationActions.seen && styles["unread"], "c-pointer"])}
            onClick={() => {
               onSeen();
               handleOpenNotificationReport();
            }}
         >
            <div className={styles["img"]}>
               <Image width={40} height={40} src="/assets/img/icon/noti-report.svg" alt="SpaceT" />
            </div>
            <div className={styles["text"]}>
               {noti?.info?.title && <div className={classNames([styles["title"], "truncate-line-2"])}>{noti?.info?.title}</div>}
               <div className={classNames([styles["title"]])}>
                  <p className="mb-0">
                     {
                        {
                           [EReportDoc.socialFeed]: "Bài đăng",
                           [EReportDoc.qnaComment]: "Bình luận",
                           [EReportDoc.qna]: "Câu hỏi",
                           [EReportDoc.socialFeedComment]: "Bình luận",
                           "": "Bình luận",
                        }[noti?.__report?.target.targetDoc ?? ""]
                     }
                     <span> của bạn vi phạm các quy tắc của cộng đồng nên chỉ mình bạn có thể thấy.</span>
                  </p>

                  <span className={styles["highlight"]}>Xem các lựa chọn</span>
               </div>
               <span className={styles["time"]}>{getTimeFromNow(noti.createdAt ?? "")}</span>
            </div>
         </div>
         <Dialog
            onClose={handleCloseNotificationReport}
            sx={{
               ".MuiDialog-paper": {
                  borderRadius: "24px",
               },
            }}
            open={notificationReport?.dialog ?? false}
            classes={{ paper: styles["dialog"] }}
         >
            <div className={styles["header"]}>
               <span className={styles["title"]}>Báo cáo</span>
               <CloseIcon onClick={handleCloseNotificationReport} />
            </div>
            <NotiContent onClose={toogleDialogNotification} onAppeal={handleAppeal} appeal={appeal} toogleDialogAppeal={toogleDialogAppeal} noti={noti} />
         </Dialog>
         <Drawer
            open={notificationReport.drawer}
            onClose={handleCloseNotificationReport}
            classes={{ paper: styles["notification-modal"] }}
            sx={{
               ".MuiDrawer-paper": {
                  width: "100%",
               },
            }}
         >
            <div className={styles["header"]}>
               <Image
                  src="/assets/img/icon/arrow-forward.svg"
                  width={20}
                  height={20}
                  alt="SpaceT"
                  className={styles["icon-back"]}
                  onClick={() => (appeal ? toogleDialogAppeal() : handleCloseNotificationReport())}
               />
               <span className={styles["title"]}>Báo cáo</span>
            </div>
            <NotiContent onClose={toogleDialogNotification} onAppeal={handleAppeal} appeal={appeal} toogleDialogAppeal={toogleDialogAppeal} noti={noti} />
         </Drawer>
         <Dialog
            open={dialogNotification}
            onClose={toogleDialogNotification}
            classes={{ paper: styles["dialog"] }}
            sx={{
               ".MuiDialog-paper": {
                  borderRadius: "24px",
               },
            }}
         >
            <div className={classNames(styles["content"], styles["notification-agree"])}>
               <Image width={46} height={46} src="/assets/img/icon/success-status.svg" alt="SpaceT" />
               <span className={styles["title"]}>{appealSuccess ? "Cảm ơn bạn đã góp ý chúng tôi" : "Bạn đã đồng ý với quyết định của chúng tôi"}</span>
               <span>
                  {appealSuccess
                     ? "Ý kiến của bạn rất quan trọng để giúp cải thiện cộng đồng luôn an toàn và văn minh. Chúng tôi sẽ xem xết kĩ lưỡng khiếu nại của bạn"
                     : "Các quy tắc cộng đồng được thiết lập để khuyến khích mọi người bày tỏ ý kiến của họ và giao tiếp với nhau một cách thân thiện với tất cả mọi người."}
               </span>
            </div>
            <div className={styles["action"]}>
               <button className={styles["btn-submit"]} onClick={toogleDialogNotification}>
                  Xong
               </button>
            </div>
         </Dialog>
         {statusAppeal && <Notification notification={statusAppeal} handleClose={() => handleChangeStatusAppeal("")} active={true}></Notification>}
      </>
   );
}

interface IReationProps {
   onClose: () => void;
   onAppeal: (reason: string) => void;
   appeal: boolean;
   toogleDialogAppeal: () => void;
   noti: UserNotifications;
}

function NotiContent({ onClose, onAppeal, appeal, toogleDialogAppeal, noti }: IReationProps) {
   const [reasonAppeal, setReasonAppeal] = useState<string>();

   useEffect(() => {
      setReasonAppeal(undefined);
   }, [appeal]);

   const handleChangeReason = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setReasonAppeal(e.target.value);
   };

   const handleSubmitAppeal = () => {
      if (reasonAppeal === undefined) {
         setReasonAppeal("");
         return;
      }
      onAppeal(reasonAppeal ?? "");
   };

   return (
      <>
         <SwipeableViews index={+appeal}>
            <div hidden={appeal}>
               <div className={styles["content"]}>
                  <span className={styles["title"]}>Bài viết của bạn được báo cáo quy phạm tiêu chuẩn cộng đồng của Space T</span>
                  <span>
                     Bài viết sẽ tạm thời được ẩn. Nhằm bảo vệ quyền lợi của bạn, đồng thời góp phần xây dựng cộng động mạng xã hội văn minh, Space T hiện đang
                     xem xét báo cáo.
                  </span>
                  <span className={styles["title"]}>
                     <span>Nội dung báo cáo:</span>
                     <span className={styles["title"]}>{noti.__report?.info?.reasonReport}</span>
                  </span>
                  <span>Nếu bạn không đồng ý với quyết định này vui lòng gửi lý do cho Space T. Nếu đồng ý, chúng tôi sẽ xóa bài viết. Xin cảm ơn.</span>
               </div>
               <div className={styles["action"]}>
                  {noti.__report?.info?.userAppeal ? undefined : (
                     <button className={styles["btn-cancel"]} onClick={toogleDialogAppeal}>
                        <span>Không đồng ý</span>
                     </button>
                  )}
                  <button className={styles["btn-submit"]} onClick={onClose}>
                     OK
                  </button>
               </div>
            </div>

            <div className={styles["appeal"]} hidden={!appeal}>
               <div className={styles["content"]}>
                  <textarea
                     rows={4}
                     placeholder="Nhập lí do vì sao bạn không đồng ý với quyết định xoá bài đăng"
                     value={reasonAppeal}
                     onChange={handleChangeReason}
                  />
                  {!reasonAppeal && reasonAppeal !== undefined && <p className="text-error">Vui lòng nhập lí do</p>}
               </div>
               <div className={styles["action"]}>
                  <button type="submit" className={styles["btn-submit"]} onClick={handleSubmitAppeal}>
                     Gửi
                  </button>
               </div>
            </div>
         </SwipeableViews>
      </>
   );
}
