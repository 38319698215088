/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Core(s)
 */
import Model, { Paginate, ModelError, TModelError, TPayload, TPaginate, IModel } from "./model";
import Media, { IMedia } from "./modelMedia.model";
import OrderItem from "./orderItem.model";
import { IReactionCount, IReviewReward } from "./productReview.model";
import ProductVariant from "./productVariant.model";
import User, { IUser } from "./user.model";

export enum EStatus {
   initializing = "initializing",
   pending = "pending",
   enable = "enable",
   disable = "disable"
}
export enum EUsageStatus {
   pending = "pending",
   applied = "applied"
}
export interface IProductReview extends IModel {
   ["info"]: {
      ["point"]: number;
      ["content"]: string | null;
   };
   ["options"]: {
      ["status"]: EStatus;
      ["usageStatus"]: EUsageStatus;
   };
   ["bonusPoint"]: number;
   ["variantId"]: string;
   ["orderId"]: string;
   ["userId"]: string;
   ["publishedAt"]: string;
   ["expiredAt"]: string;

   ["__user"]?: IUser;
   ["__reactionCount"]?: IReactionCount;
   ["__productVariant"]?: ProductVariant;
   ["__productReviewImages"]?: Media[];
   ["__currentUserReactionsMap"]: {
      like?: IReactionCount;
   };
   ["__reviewReward"]?: IReviewReward;
   ["__orderItem"]?: OrderItem
}
export class ProductReview extends Model<IProductReview> implements IProductReview {
   public ["info"]!: {
      ["point"]: number;
      ["content"]: string | null;
   };
   public ["options"]!: {
      ["status"]: EStatus;
      ["usageStatus"]: EUsageStatus;
   };
   public ["bonusPoint"]!: number;
   public ["variantId"]!: string;
   public ["orderId"]!: string;
   public ["userId"]!: string;
   public ["publishedAt"]!: string;
   public ["expiredAt"]!: string;

   public ["__user"]?: User;
   public ["__reactionCount"]?: IReactionCount;
   public ["__productVariant"]?: ProductVariant;
   public ["__productReviewImages"]?: Media[];
   public ["__currentUserReactionsMap"]: {
      like?: IReactionCount;
   };
   public ["__reviewReward"]?: IReviewReward;
   public ["__orderItem"]?: OrderItem

   constructor(data?: IProductReview) {
      super(data);

      Object.assign(this, data);
      if (this.__user && !(this.__user instanceof User)) {
         this.__user = new User(this.__user);
      }
      if (this.__productVariant) {
         this.__productVariant = new ProductVariant(this.__productVariant);
      }
      if (this.__productReviewImages && !(this.__productReviewImages instanceof Media)) {
         this.__productReviewImages = this.__productReviewImages.map(item => new Media(item));
      }
   }

   /**
    *
    * @param page
    * @param limit
    * @param sort
    * @param sortBy
    * @returns
    */
   public static async productReviewPaginate({
      page,
      limit,
      sort,
      sortBy,
      status
   }: {
      status?: EStatus[];
      page?: number;
      limit?: number;
      sort?: "desc" | "asc";
      sortBy?: "createdAt" | "info.title";
   }) {
      try {
         const token = window.localStorage.getItem(tokenKey);
         if (!token) {
            return new ModelError({
               ["httpCode"]: 401,
               ["message"]: "401 Unauthorized error",
               ["errors"]: {
                  ["process"]: [
                     {
                        ["code"]: "401",
                        ["message"]: "401 Unauthorized error"
                     }
                  ]
               }
            });
         }

         const response = await fetch(`${apiDomain}/www/user/review/product/paginate`, {
            ["method"]: "GET",
            ["cache"]: "default",
            ["headers"]: {
               ["X-Requested-With"]: "XMLHttpRequest",
               ["Authorization"]: `Bearer ${token}`,
               ["page"]: !page ? String(1) : String(page),
               ["limit"]: !limit ? String(20) : String(limit),
               ["sort"]: sort ?? "desc",
               ["sort-by"]: sortBy ?? "createdAt",
               ...(!status || status.length < 1
                  ? undefined
                  : {
                       ["status"]: status.toString()
                    })
            }
         });

         if (!response.ok) {
            return new ModelError((await response.json()) as TModelError);
         }

         const payload = (await response.json()) as TPayload<{
            ["paginator"]: TPaginate<IProductReview>;
         }>;

         const paginate = new Paginate(payload.data.paginator, ProductReview);

         return paginate;
      } catch (error) {
         console.error(error);
         return new ModelError({
            ["httpCode"]: 500,
            ["message"]: error.message as string,
            ["errors"]: {
               ["process"]: [
                  {
                     ["code"]: "process.error.5000",
                     ["message"]: "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   public static async review({
      id,
      point,
      content,
      reviewerName,
      reviewerPhone,
      reviewerEmail
   }: {
      id: string;
      point: number;
      content?: string;
      reviewerName: string;
      reviewerPhone?: string;
      reviewerEmail?: string;
   }) {
      const token = localStorage.getItem(tokenKey);
      const response = await fetch(`${apiDomain}/www/product/review`, {
         ["method"]: "PATCH",
         ["headers"]: {
            ["X-Requested-With"]: "XMLHttpRequest",
            ["Content-Type"]: "application/json",
            ...(token ? { ["Authorization"]: `Bearer ${token}` } : undefined),
            ["id"]: id
         },
         ["body"]: JSON.stringify({
            ["info"]: {
               ["point"]: point,
               ["content"]: content,
               ...(reviewerName ? { reviewerName } : undefined),
               ...(reviewerPhone ? { reviewerPhone } : undefined),
               ...(reviewerEmail ? { reviewerEmail } : undefined)
            }
         })
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         ["updatedProductReview"]: IProductReview;
      }>;

      return new ProductReview(payload.data.updatedProductReview);
   }

   public static async createDraft(variantId: string) {
      const res = await Model.fetchData({
         method: "POST",
         endPoint: "product/review/draft",
         body: {
            variantId: variantId
         }
      });

      if (res instanceof ModelError) {
         return res;
      }

      const payload = (await res.json()) as TPayload<{
         productReview: any;
      }>;

      return payload.data.productReview.id as string;
   }

   /**
    *
    * @param file
    * @param id
    * @returns
    */
   public static async upload(file: File, id?: string) {
      const token = localStorage.getItem(tokenKey);

      const formData = new FormData();
      formData.append("file", file);

      const response = await fetch(`${apiDomain}/www/product/review/upload-image`, {
         method: "POST",
         headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : undefined),
            "X-Requested-With": "XMLHttpRequest",
            id: id ?? ""
         },
         body: formData
      });

      if (!response.ok) {
         return new ModelError((await response.json()) as TModelError);
      }

      const payload = (await response.json()) as TPayload<{
         newMedia: IMedia;
      }>;

      return new Media(payload.data.newMedia);
   }
   /**
    *
    * @param id
    * @param capchat
    * @returns
    */
   public static async delete(id: string, captchat: string) {
      const res = await Model.fetchData({
         method: "DELETE",
         endPoint: "product/review/upload-image",
         headers: {
            id: id || "",
            captchat: captchat || ""
         }
      });

      if (res instanceof ModelError) {
         return res;
      }

      const payload = (await res.json()) as TPayload<{
         data: boolean;
      }>;

      return payload.data;
   }
}

export default ProductReview;
