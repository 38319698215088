import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { Dialog, Drawer } from "@mui/material";
import { RootState } from "state";
import { toggleModalDownloadDesktop } from "state/global/actions";
import { toggleModalWelcome, togglePopoverIntroducing } from "state/action/auth";
import { openModalPost } from "state/action/socialFeed";
import { ESocialFeedType } from "models/socialFeed.model";
import useMatchBreakpoints from "hook/useMatchBreakpoints";

export const ShowModalAtURL = ["/feed", "/post", "/hoi-dap"];

export function isShowModalWelcome(pathname: string): boolean {
   let status = false;
   ShowModalAtURL.forEach(url => {
      if (pathname.includes(url) || pathname.includes(url) || pathname.includes(url)) {
         status = true;
      }
   });
   return status;
}

export default function ModalWelcome() {
   const dispatch = useDispatch();
   const router = useRouter();
   const { visible, showGreetingPost } = useSelector<RootState>(state => state.auth.modalWelcome) as { visible: boolean; showGreetingPost: boolean };

   const { isDesktop } = useMatchBreakpoints();

   const handleCreateGreeting = async () => {
      setTimeout(async () => {
         if (!router.asPath.includes("/feed")) {
            await router.push("/feed");
         }
         dispatch(toggleModalWelcome({ visible: false, showGreetingPost: false })); 
         dispatch(togglePopoverIntroducing({ visible: false }));
         dispatch(openModalPost({ visible: true, type: ESocialFeedType.socialFeed, showFirstHashtag: true }));
      }, 0);
   };
   const handleCloseModalWelcome = () => {
      dispatch(toggleModalWelcome({ visible: false, showGreetingPost: false }));
   };
   return (
      <>
         {isDesktop ? (
            <Dialog open={Boolean(visible && showGreetingPost)} className="post-creation" onClose={handleCloseModalWelcome}>
               <div
                  className="close-icon"
                  onClick={() => {
                     dispatch(toggleModalDownloadDesktop({ visible: false }));
                     handleCloseModalWelcome();
                  }}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                     <path
                        d="M0.170733 16.3399C0.244167 16.4133 0.328615 16.461 0.424079 16.4831C0.519543 16.5051 0.615007 16.5051 0.71047 16.4831C0.805934 16.461 0.894054 16.4133 0.974831 16.3399L8.00243 9.30126L15.041 16.3399C15.1512 16.4427 15.2834 16.4941 15.4376 16.4941C15.5918 16.5014 15.724 16.45 15.8341 16.3399C15.9443 16.2224 15.9994 16.0865 15.9994 15.9323C15.9994 15.7781 15.9443 15.6459 15.8341 15.5358L8.80653 8.49717L15.8341 1.46957C15.9443 1.35942 15.9994 1.22724 15.9994 1.07303C16.0067 0.911472 15.9516 0.77562 15.8341 0.66547C15.724 0.555319 15.5918 0.500244 15.4376 0.500244C15.2834 0.500244 15.1512 0.555319 15.041 0.66547L8.00243 7.70408L0.974831 0.66547C0.894054 0.592036 0.805934 0.544304 0.71047 0.522274C0.615007 0.500244 0.519543 0.500244 0.424079 0.522274C0.328615 0.544304 0.244167 0.592036 0.170733 0.66547C0.0972996 0.746247 0.045896 0.834367 0.0165226 0.929831C-0.00550752 1.02529 -0.00550752 1.12076 0.0165226 1.21622C0.045896 1.31169 0.0972996 1.39613 0.170733 1.46957L7.19833 8.49717L0.170733 15.5358C0.0972996 15.6092 0.045896 15.6937 0.0165226 15.7891C-0.00550752 15.8846 -0.00550752 15.9801 0.0165226 16.0755C0.045896 16.1783 0.0972996 16.2664 0.170733 16.3399Z"
                        fill="#252A2B"
                     />
                  </svg>
               </div>
               <div className="post-creation__content">
                  <div className="post-creation__left">
                     <Image src="/assets/img/banner/greeting_post.gif" width={10} height={10} alt="" />
                  </div>
                  <div className="post-creation__right">
                     <div className="post-creation__right__top">
                        <div className="post-creation__right__top__title">
                           Chào mừng
                           <br />
                           bạn đến Space T!
                        </div>
                        <img className="post-creation__right__top__icon" src="/assets/img/banner/visual.png" />
                     </div>
                     <div className="post-creation__right__bot">
                        <div className="post-creation__right__bot__title">
                           Bạn hãy giới thiệu một chút về mình và sở thích với cộng đồng yêu nhà Space T nhé.
                        </div>
                        <div className="post-creation__right__bot__des">Chúc bạn có những trải nghiệm thú vị và ý tưởng decor không gian sống của mình!</div>
                        <div className="post-creation__right__bot__actions">
                           <button className="other" onClick={handleCloseModalWelcome}>
                              Lúc khác
                           </button>
                           <button className="create-post" onClick={handleCreateGreeting}>
                              Tạo lời chào
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
            </Dialog>
         ) : (
            <Drawer className="post-creation-mobile" anchor="bottom" open={Boolean(visible && showGreetingPost)} onClose={handleCloseModalWelcome}>
               <div className="post-creation-mobile__content">
                  <div className="post-creation-mobile__header">
                     <div className="post-creation-mobile__header__title">
                        Chào mừng
                        <br />
                        bạn đến Space T!
                     </div>
                     <img className="post-creation-mobile__header__icon" src="/assets/img/banner/visual.png" />
                  </div>
                  <div className="post-creation-mobile__bot">
                     <div className="post-creation-mobile__bot__title">Bạn hãy giới thiệu một chút về mình và sở thích với cộng đồng yêu nhà Space T nhé.</div>
                     <div className="post-creation-mobile__bot__des">Chúc bạn có những trải nghiệm thú vị và ý tưởng decor không gian sống của mình!</div>
                     <Image className="post-creation-mobile__bot__img" src="/assets/img/banner/greeting_post.gif" width={10} height={10} alt="" />
                     <div className="post-creation-mobile__bot__actions">
                        <button className="create-post" onClick={handleCreateGreeting}>
                           Tạo lời chào
                        </button>{" "}
                        <button className="other" onClick={handleCloseModalWelcome}>
                           Lúc khác
                        </button>
                     </div>
                  </div>
               </div>
            </Drawer>
         )}
      </>
   );
}
