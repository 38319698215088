import React, { useState } from "react";
import { ModelError } from "../../../../models/model";
import { EStep } from "../const";
import User, { EMethod } from "models/user.model";
import { t } from "i18next";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

interface IProps {
   form: {
      account: string;
      email: string;
      phone: string;
   };
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
}

const OptionReceiverOTP: React.FC<IProps> = ({ form, setStep }) => {
   // const router: any = useRouter();
   const [error, setError] = useState<string>();
   const [method, setMethod] = useState<EMethod>(form.phone ? EMethod.sms : EMethod.email);
   // const [historyState, setHistoryState] = useState<IHistoryState>();
   // useEffect(() => {
   //    if (typeof window !== "undefined") {
   //       const historyState: any = localStorage.getItem("historyState");
   //       setHistoryState(JSON.parse(historyState));
   //    }
   // }, []);

   const onSubmit = async () => {
      if (!form?.account) {
         return;
      }
      const tokenCaptcha = await executeRecaptcha();
      const requestOTP = await User.resetPasswordOTPRequest(form?.account, method, tokenCaptcha);
      if (requestOTP instanceof ModelError) {
         return setError(requestOTP.firstMessageErr);
      }

      setStep({
         step: EStep.otpInputForgot,
         dataStep: {
            ...form,
            _id: requestOTP._id,
            // account: historyState.account,
            // phone: historyState.phone,
            // email: historyState.email,
            method: method,
         },
      });
   };

   return (
      <div className="forgot-password">
         <h1 className="signup-phone-title">Chọn cách bạn muốn nhận OTP</h1>
         <div className="form-forgot">
            {form?.email && (
               <div className="input-radio form-check">
                  <input
                     className="form-check-input"
                     type="radio"
                     name="method"
                     id="email-method"
                     checked={method === EMethod.email}
                     defaultChecked
                     onClick={() => {
                        setMethod(EMethod.email);
                     }}
                  />
                  <label htmlFor="email-method">{form?.email ?? "Địa chỉ Email"}</label>
               </div>
            )}
            {form?.phone && (
               <div className="input-radio form-check">
                  <input
                     className="form-check-input"
                     type="radio"
                     name="method"
                     id="phone-method"
                     checked={method === EMethod.sms}
                     onClick={() => {
                        setMethod(EMethod.sms);
                     }}
                  />
                  <label htmlFor="phone-method">{form?.phone ?? "Số điện thoại"} </label>
               </div>
            )}
            {error ? (
               <span className="error-text">
                  <>{t(error)} </>
               </span>
            ) : undefined}

            <button className="btn btn-auth" style={{ backgroundColor: "#FF6347" }} onClick={onSubmit}>
               Xác nhận
            </button>
         </div>

         <div className="login-helper d-flex justify-content-center" style={{ marginTop: 30, textAlign: "center" }}>
            {/* Đã có tài khoản? <div className="ms-1" onClick={() => setStep({ value: EStep.singinForm })}>Đăng nhập ngay</div> */}
         </div>

         <div className="back-step" onClick={() => setStep({ step: EStep.forgotPasswordStep1 })}>
            <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
         </div>
      </div>
   );
};

export default OptionReceiverOTP;
