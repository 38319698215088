import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactNode } from "react";

export interface IProps {
   open?: boolean;
   header?: ReactNode;
   children?: ReactNode;
   classes?: {
      header?: string;
      content?: string;
   };
   className?: string;
   onClose?: () => void;
   size?: "small" | "medium" | "large";
}

export default function CustomizeDialogV2({ size = "medium", open = false, header, children, className, classes, onClose }: IProps) {
   return (
      <Dialog onClose={onClose} open={open} className={`${className ?? ""} custom-dialogv2 dialogv2-${size}`}>
         <DialogTitle className={classes?.header}>
            {header}
            {onClose ? <div className="btn-close" onClick={onClose}></div> : undefined}
         </DialogTitle>
         <DialogContent className={classes?.content}>{children}</DialogContent>
      </Dialog>
   );
}
