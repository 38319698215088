import Article from "./article.model";
import ArticleComment from "./articleComment.model";
/**
 * Module(s)
 */

/**
 * Model(s)
 */
import Reaction, { IReaction } from "./reaction.model";
export enum EArticleReactionSortBy {
   id = "_id",
   createdAt = "createdAt",
   updatedAt = "updatedAt"
}

export enum EArticleReactionStatus {
   enable = "enable",
   disable = "disable"
}

export enum EArticleReactionTargets {
   post = "post",
   comment = "comment"
}

export enum EArticleReactionTypes {
   like = "like"
}
export interface IArticleReaction extends IReaction {
   ["articleId"]?: string | null;
   ["articleCommentId"]?: string | null;

   ["__article"]?: Article | null;
   ["__articleComment"]?: ArticleComment | null;
}


export class ArticleReaction extends Reaction<IArticleReaction> implements IArticleReaction {

   public ["articleId"]?: string | null;
   public ["articleCommentId"]?: string | null;

   public ["__article"]?: Article | null;
   public ["__articleComment"]?: ArticleComment | null;

   constructor (data?: IArticleReaction) {
      super(data);
      if (this?.__article) {
         this.__article = new Article(this.__article);
      }

      if (this?.__articleComment) {
         this.__articleComment = new ArticleComment(this.__articleComment);
      }
   }


}

export default ArticleReaction;