import React from "react";
import { EStep } from "../const";

interface IProps {
   redirect?: string;
   setStep?: (step: EStep) => void;
   onRegisterSuccess?: () => void;
}

const RegisterSuccess: React.FC<IProps> = ({ onRegisterSuccess }) => {
   const historyPush = async () => {
      sessionStorage.removeItem("previous_url");
      onRegisterSuccess?.();
   };

   return (
      <div className="register-email-success">
         <div className="register-success-img">
            <img src="/assets/img/auth/success-icon.png" alt="" />
         </div>
         <h1 className="register-success-title">Yay! Đăng ký thành công</h1>
         <p className="register-success-message">Hãy trải nghiệm những tính năng và tham gia thảo luận cùng cộng đồng Space T!</p>

         <button className="btn btn-auth" style={{ backgroundColor: "#FF6347" }} onClick={historyPush}>
            Đồng ý
         </button>
      </div>
   );
};

export default RegisterSuccess;
