import React, { PropsWithChildren, ReactNode } from "react";
import { Dialog } from "@mui/material";
import classNames from "classnames";

export interface IModalProps {
   open?: boolean;
   className?: string;
   title?: any;
   maxWidth?: string;
   header?: any;
   footer?: any;
   onClose?: () => void;
}

export const ModalHeader = ({
   onClose,
   children,
}: PropsWithChildren<{
   onClose?: () => void;
}>) => {
   return (
      <div className="modal-header">
         {onClose && (
            <div className={`img-close`} style={{ cursor: "pointer" }} onClick={onClose}>
               <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M1.09375 17.9805C0.96875 17.8711 0.886719 17.7383 0.847656 17.582C0.808594 17.4258 0.808594 17.2695 0.847656 17.1133C0.894531 16.957 0.976562 16.8242 1.09375 16.7148L8.27734 9.51953L1.09375 2.32422C0.976562 2.21484 0.898438 2.08203 0.859375 1.92578C0.820312 1.76953 0.820312 1.61719 0.859375 1.46875C0.898438 1.3125 0.976562 1.17578 1.09375 1.05859C1.20312 0.941406 1.33594 0.867188 1.49219 0.835938C1.64844 0.796875 1.80078 0.796875 1.94922 0.835938C2.09766 0.875 2.23438 0.949219 2.35938 1.05859L9.54297 8.25391L16.7383 1.05859C16.9102 0.886719 17.1211 0.800781 17.3711 0.800781C17.6211 0.800781 17.832 0.886719 18.0039 1.05859C18.1836 1.23047 18.2695 1.44531 18.2617 1.70312C18.2617 1.95312 18.1758 2.16016 18.0039 2.32422L10.8086 9.51953L18.0039 16.7148C18.1758 16.8789 18.2617 17.0859 18.2617 17.3359C18.2617 17.5859 18.1758 17.8008 18.0039 17.9805C17.832 18.1602 17.6211 18.2461 17.3711 18.2383C17.1211 18.2383 16.9102 18.1523 16.7383 17.9805L9.54297 10.7852L2.35938 17.9805C2.23438 18.0977 2.09766 18.1719 1.94922 18.2031C1.80078 18.2422 1.64844 18.2422 1.49219 18.2031C1.33594 18.1719 1.20312 18.0977 1.09375 17.9805Z"
                     fill="#666666"
                  />
               </svg>
            </div>
         )}
         {children && <div className="modal-title">{children}</div>}
      </div>
   );
};

export const Modal: React.FC<React.PropsWithChildren<IModalProps>> = ({ open, className, title, maxWidth, children, header, footer, onClose, ...props }) => {
   return (
      <Dialog
         open={Boolean(open)}
         className={classNames("modal-custom", className)}
         onClose={onClose}
         PaperProps={{
            style: { maxWidth },
         }}
         {...props}
      >
         {header ? header : title ? <ModalHeader onClose={onClose}>{title}</ModalHeader> : undefined}
         <div className="modal-body">{children}</div>
         {footer}
      </Dialog>
   );
};

export default Modal;
