import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ModelError } from "../../../../models/model";
import yup from "../../../../core/yupPatern";
import { EStep } from "../const";
import User, { EMethod } from "models/user.model";
import { useInterval } from "hook/useInterval";

interface IHistoryState {
   account?: string;
   phone?: string;
   email?: string;
   _id?: string;
   method: EMethod;
}

interface IProps {
   form: {
      account: string;
      authCode: string;
      email: string;
      method: EMethod;
      phone: string;
      _id: string;
   };
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
}

const FormNewPassword: React.FC<IProps> = ({ form, setStep }) => {
   const { t } = useTranslation();
   const [errorPassword, setErrorPassword] = useState<string | undefined>();
   const [errorOTP, setErrorOTP] = useState<string | undefined>();
   const [success, setSuccess] = useState<string | undefined>();
   const [s, setS] = useState(60);
   const [status, setStatus] = useState("start");
   const [showPassword, setShowPassword] = useState<boolean>(false);

   // const [historyState, setHistoryState] = useState<IHistoryState>();

   // useEffect(() => {
   //    if (typeof window !== "undefined") {
   //       const historyState: any = localStorage.getItem("historyState");
   //       setHistoryState(JSON.parse(historyState));
   //    }
   // }, []);
   const schema = yup.object().shape({
      password: yup
         .string()
         .required("Vui lòng nhập mật khẩu")
         .min(6, "Mật khẩu tối thiểu là 6 ký tự")
         .max(24, "Mật khẩu tối đa là 24 ký tự")
      // confirmPassword: yup
      //   .string()
      //   .required("Vui lòng nhập lại mật khẩu")
      //   .oneOf([yup.ref("password"), null], "Nhập lại mật khẩu không chính xác"),
      // otp: yup
      //   .string()
      //   .required("Vui lòng nhập mã xác thực")
      //   .matches(numberLengthRegex, "Mã xác thực phải là số và chỉ 6 ký tự")
      //   .max(6, "Mã xác thực phải là số và chỉ 6 ký tự"),
   });
   const {
      register,
      handleSubmit,
      formState: { errors }
   } = useForm<{ password: string; confirmPassword: string; otp: string }>({
      resolver: yupResolver(schema)
   });
   useInterval(
      () => {
         if (s > 0) {
            setS(s - 1);
         }
         // else {
         //     setStatus("stop");
         // }
      },
      status === "start" ? 1000 : null
      // passing null stops the interval
   );
   const onSubmit = async (data: { password: string }) => {
      const id = form._id || "123456";
      const otp = form.authCode || "123456";
      const { account, method } = form;
      const { password } = data;

      if (!password && !otp) {
         return;
      }
      try {
         const resetPassword = await User.resetPasswordOTP(password, id, parseInt(otp), account, method);
         if (resetPassword instanceof ModelError) {
            for (const [key, value] of Object.entries(resetPassword.errors ?? {})) {
               if (value.length > 0) {
                  if (key === "password") {
                     setErrorPassword(value[0].message);
                  }
                  if (key !== "password") {
                     setErrorOTP(value[0].message);
                  }
               }
            }
            return;
         }
         // setTimeout(() => {
            setStep({ step: EStep.forgotPasswordStep4 });
         // }, 1000);
         // setSuccess("Đặt lại mật khẩu thành công");
      } catch (error) {
         console.error(error);
      }
   };
   const onInputChange = () => {
      setErrorOTP(undefined);
      setErrorPassword(undefined);
   };

   return (
      <div className="form-new-password">
         <h1 className="signup-phone-title">Tạo mật khẩu</h1>
         <p className="signup-phone-des">Tạo mật khẩu mới an toàn và dễ nhớ!</p>
         <form className="form-register" onSubmit={handleSubmit(onSubmit)}>
            <div className="item-form">
               <label className="label">Mật khẩu mới</label>
               <div className="input-wrap">
                  <input
                     type={showPassword ? "text" : "password"}
                     className={`input ${errors.password || (errorPassword && !errors.password) ? "error" : ""}`}
                     placeholder="Nhập mật khẩu mới"
                     {...register("password", {
                        onChange: () => {
                           onInputChange();
                        }
                     })}
                  />
                  <img
                     onClick={() => setShowPassword(prev => !prev)}
                     className="suffix"
                     src={`/assets/img/icon/${showPassword ? "eyes.svg" : "eye-sleep.svg"}`}
                     width={21}
                     height={21}
                  />
               </div>
               {errorPassword && !errors.password ? (
                  <span className="error-text">
                     <>{t(errorPassword)} </>
                  </span>
               ) : (
                  undefined
               )}
               {errorOTP && !errors?.otp ? (
                  <span className="error-text">
                     <>{t(errorOTP)} </>
                  </span>
               ) : (
                  undefined
               )}
               {errors?.password?.message ? <span className="error-text">{t(errors?.password?.message)} </span> : undefined}
            </div>
            {success ? (
               <div className="w-100 text-end">
                  <span className="text-success text-end">{success ?? ""} </span>
               </div>
            ) : (
               undefined
            )}
            <button className="btn btn-auth" type="submit" style={{ backgroundColor: "#FF6347" }}>
               Xác nhận
            </button>

            <div className="login-helper" style={{ marginTop: 30, textAlign: "center" }}>
               {/* Đã có tài khoản? <span onClick={() => setStep({ value: EStep.singinForm })}>Đăng nhập ngay</span> */}
            </div>

            <div className="back-step" onClick={() => setStep({ step: EStep.forgotPasswordStep2 })}>
               <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
            </div>
         </form>
      </div>
   );
};

export default FormNewPassword;
