interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

export default function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;
   return (
      <div className="tab-content" role="tabpanel" hidden={value !== index} {...other}>
         {value === index && children}
      </div>
   );
}
