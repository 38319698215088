/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain, tokenKey } from "./__variables";

/**
 * Model(s)
 */
import Model from "./model";
import ProductVariant from "./productVariant.model";
import Order from "./order.model";
import Product from "./product.model";
import ProductReview from "./productReview.model";

export enum EStatus {
   enable = "enable",
   delete = "delete"
}

export interface IOrderItem extends Model {
   ["info"]?: {
      ["price"]: number;
      ["priceAfterDiscount"]: number;
      ["quantity"]: number;
   };
   ["options"]?: {
      ["status"]?: EStatus;
   };

   ["orderId"]?: string | null;
   ["variantId"]?: string | null;

   readonly ["__order"]?: Order | null;
   readonly ["__product"]?: Product;
   readonly ["__productReview"]?: ProductReview;
   readonly ["__productVariant"]?: ProductVariant | null;
}

export default class OrderItem extends Model<IOrderItem> implements IOrderItem {
   public ["info"]!: {
      ["price"]: number;
      ["priceAfterDiscount"]: number;
      ["quantity"]: number;
   };
   public ["options"]!: {
      ["status"]?: EStatus;
   };

   public ["orderId"]!: string | null;
   public ["variantId"]!: string | null;

   public readonly ["__order"]!: Order | null;
   public readonly ["__product"]!: Product;
   public readonly ["__productReview"]!: ProductReview;
   public readonly ["__productVariant"]!: ProductVariant;

   constructor(data?: IOrderItem) {
      super(data);

      Object.assign(this, data);

      if (this.__order && !(this.__order instanceof Order)) {
         this.__order = new Order(this.__order);
      }

      if (this.__product && !(this.__product instanceof Product)) {
         this.__product = new Product(this.__product);
      }

      if (this.__productVariant && !(this.__productVariant instanceof ProductVariant)) {
         this.__productVariant = new ProductVariant(this.__productVariant);
      }

      if (this.__productReview && !(this.__productReview instanceof ProductReview)) {
         this.__productReview = new ProductReview(this.__productReview);
      }
   }
}
