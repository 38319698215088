import { Model, ModelError, TPayload } from "./model";
import QnA from "./qna.model";
import QnAComment from "./qnaComment.model";
import SocialFeed from "./socialFeed.model";
import SocialFeedComment from "./socialFeedComment.model";
import User from "./user.model";

export enum EReportSortBy {
   createdAt = "createdAt",
   updatedAt = "updatedAt",
};

export enum EReportStatus {
   waiting = "waiting",
   approved = "approved",
   rejected = "rejected",
   appeal = "appeal",
   approveAppeal = "approveAppeal",
   rejectAppeal = "rejectAppeal",
};

export enum EReportDoc {
   socialFeed = "SocialFeed",
   socialFeedComment = "SocialFeedComment",
   qna = "QuestionAndAnswer",
   qnaComment = "QuestionAndAnswerComment",
}

export interface IReport extends Model {
   ["info"]?: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["reasonReport"]?: string | null;
      ["userAppeal"]?: string | null;
      ["reportDate"]?: Date | null;
      ["appealDate"]?: Date | null;
   };
   ["target"]: {
      ["targetId"]?: string;
      ["targetDoc"]?: EReportDoc;
   };
   ["options"]?: {
      ["status"]?: EReportStatus;
   };
   ["userId"]?: string;
   ["adminId"]?: string;

   ["__socialFeed"]?: SocialFeed;
   ["__socialFeedComment"]?: SocialFeedComment;
   ["__qna"]?: QnA;
   ["__qnaComment"]?: QnAComment;
   ["__user"]?: User
}


export default class Report<T extends IReport = IReport> extends Model {
   public ["info"]?: {
      ["title"]?: string | null;
      ["description"]?: string | null;
      ["reasonReport"]?: string | null;
      ["userAppeal"]?: string | null;
      ["reportDate"]?: Date | null;
      ["appealDate"]?: Date | null;
   };
   public ["target"]!: {
      ["targetId"]?: string;
      ["targetDoc"]?: EReportDoc;
   };
   public ["options"]?: {
      ["status"]?: EReportStatus;
   };
   public ["userId"]?: string;
   public ["adminId"]?: string;

   public ["__socialFeed"]?: SocialFeed;
   public ["__socialFeedComment"]?: SocialFeedComment;
   public ["__qna"]?: QnA;
   public ["__qnaComment"]?: QnAComment;
   public ["__user"]?: User
   constructor (data?: T) {
      super(data);
      Object.assign(this, data);
      this.__socialFeedComment && (this.__socialFeedComment = new SocialFeedComment(this.__socialFeedComment))
      this.__qna && (this.__qna = new QnA(this.__qna))
      this.__socialFeed && (this.__socialFeed = new SocialFeed(this.__socialFeed))
      this.__qnaComment && (this.__qnaComment = new QnAComment(this.__qnaComment))
      this.__user && (this.__user = new User(this.__user))
   }

   public static async createReport({ targetId, targetDoc, reason}: {targetId: string, targetDoc: EReportDoc, reason: string}) {
      try {
         const res = await Model.fetchData({
            method: 'POST',
            endPoint: 'report',
            isRequiredAuthorization: true,
            body: {
               targetId,
               targetDoc,
               reasonReport:reason
            
            }
         })
         if (res instanceof ModelError) {
            return res
         }

         const payload = await res.json() as TPayload<{
            report: Report
         }>

         return new Report(payload.data.report)
      }
      catch (error) {
         return new ModelError(error);
      }
   }

   public static async appeal({ reason, id }: { id: string, reason: string }) {
      try {
         const res = await Model.fetchData({
            method: 'PATCH',
            endPoint: 'report/appeal',
            isRequiredAuthorization: true,
            headers: {
               id
            },
            body: {
               userAppeal: reason
            }
         })
         if (res instanceof ModelError) {
            return res
         }

         const payload = await res.json() as TPayload<{
            report: Report
         }>

         return new Report(payload.data.report)
      }
      catch (error) {
         return new ModelError(error);
      }
   }
}
