import { EABConsultingForm, EABTestType } from "../../models/abTest.model";
import { ABTEST } from "../type";

const initState = {
   abTest: {
      [EABTestType.consulting]: EABConsultingForm
   }
};
export default function abTestReducer(state = initState, action: any) {
   switch (action.type) {
      case ABTEST:
         return action.payload;
      default:
         return state;
   }

}
