import Community from "./community.model";
import CommunityComment from "./communityComment.model";
/**
 * Module(s)
 */

/**
 * Model(s)
 */
import Reaction, { IReaction } from "./reaction.model";

export interface ICommunityReaction extends IReaction {
    ["communityPostId"]?: string | null;
    ["communityPostCommentId"]?: string | null;

    ["__communityPost"]?: Community | null;
    ["__communityPostComment"]?: CommunityComment | null;
}


export class CommunityReaction extends Reaction<ICommunityReaction> implements ICommunityReaction {

    public ["communityPostId"]?: string | null;
    public ["communityPostCommentId"]?: string | null;

    public ["__communityPost"]?: Community | null;
    public ["__communityPostComment"]?: CommunityComment | null;

    constructor(data?: ICommunityReaction) {
        super(data);
        if (this?.__communityPost) {
            this.__communityPost = new Community(this.__communityPost);
        }

        if (this?.__communityPostComment) {
            this.__communityPostComment = new CommunityComment(this.__communityPostComment);
        }
    }


}

export default CommunityReaction;