import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import styles from "./index.module.scss";
import Button from "@Components/button";

const StylesDialog = styled(Dialog)(() => ({
   ".MuiPaper-root": {
      width: 328,
      background: "#FFFFFF",
      boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.16)",
      borderRadius: 16,
      padding: 24
   }
}));

interface IProps {
   open?: boolean;
   title?: string;
   message?: string;
   textCancel?: string | React.ReactNode;
   textSubmit?: string | React.ReactNode;
   onConfirm?: () => void;
   onClose?: () => void;
}
export default function DialogConfirm({ open, title, message, textCancel, textSubmit, onConfirm, onClose }: IProps) {
   return (
      <StylesDialog open={!!open}>
         {title && <p className={styles["title"]}>{title}</p>}
         {message && <p className={styles["message"]}>{message}</p>}
         <div className={styles["dialog-action"]}>
            {onClose && (
               <Button width="100%" variant="bt-outline-primary" className={styles["cancel-btn"]} onClick={onClose}>
                  {textCancel ?? "Hủy"}
               </Button>
            )}
            <Button
               width="100%"
               className={styles["submit-btn"]}
               onClick={() => {
                  if (onConfirm) {
                     onConfirm?.();
                  } else {
                     onClose?.();
                  }
               }}
            >
               {textSubmit ?? "Xác nhận"}
            </Button>
         </div>
      </StylesDialog>
   );
}
