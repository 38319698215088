import UserBookmark from '../../models/userBookmark.model';
import { ADD_USER_BOOKMARK_BY_SPACE, APPEND_USER_BOOKMARK_BY_SPACE, DELETE_USER_BOOKMARK_BY_SPACE, UPDATE_USER_BOOKMARK_BY_SPACE, USER_BOOKMARK_BY_SPACE } from '../type';
const initState = {
   // generalMediaBySpace: [],
   userBookmarkMediasBySpace: []
}
export default function bookmarkMediasBySpaceReducer(state = initState, action: any) {
   switch (action.type) {
      // case MEDIAS_BY_SPACE:
      //    return {
      //       userBookmarkMediasBySpace: action.payload.userBookmarkMediasBySpace,
      //       generalMediaBySpace: action.payload.generalMediaBySpace,
      //    };
      // case GENERAL_MEDIAS_BY_SPACE:
      //    return {
      //       ...state,
      //       generalMediaBySpace: action.payload,
      //    };
      // case ADD_MEDIA_BY_SPACE:
      //    return {
      //       ...state,
      //       generalMediaBySpace: [...state.generalMediaBySpace, ...(Array.isArray(action.payload) ? action.payload : [action.payload])],
      //    };
      // case UPDATE_MEDIA_BY_SPACE:
      //    return {
      //       ...state,
      //       generalMediaBySpace: state.generalMediaBySpace.map((media: GeneralMediasBySpace) => media._id === action.payload._id ? action.payload : media)
      //    };
      // case DELETE_MEDIA_BY_SPACE:
      //    return {
      //       ...state,
      //       generalMediaBySpace: state.generalMediaBySpace.filter((media: GeneralMediasBySpace) => media._id !== action.payload._id)
      //    };
      case USER_BOOKMARK_BY_SPACE:
         return {
            ...state,
            userBookmarkMediasBySpace: action?.payload,
         };
      case ADD_USER_BOOKMARK_BY_SPACE:
         return {
            ...state,
            userBookmarkMediasBySpace: [...state.userBookmarkMediasBySpace, action?.payload]
         };
      case APPEND_USER_BOOKMARK_BY_SPACE:
         return {
            ...state,
            userBookmarkMediasBySpace: [...state.userBookmarkMediasBySpace, ...action?.payload]
         };
      case UPDATE_USER_BOOKMARK_BY_SPACE:
         return {
            ...state,
            userBookmarkMediasBySpace: state.userBookmarkMediasBySpace.map((bookmark: UserBookmark) => bookmark._id === action?.payload._id ? action?.payload : bookmark)
         };
      case DELETE_USER_BOOKMARK_BY_SPACE:
         return {
            ...state,
            userBookmarkMediasBySpace: state.userBookmarkMediasBySpace.filter((bookmark: UserBookmark) => bookmark._id !== action?.payload._id)
         };
      default:
         return state;
   }

}
