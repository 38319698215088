import PhoneIcon from "./phone.png";
import ZaloIcon from "./zalo.png";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useState } from "react";

export default function StickyRightButton({ router, className }: { router: any; className?: string }) {
   const [isShow, setIsShow] = useState(true);

   return (
      <div
         className={classNames(
            className,
            styles["sticky-right-button"],
            router.pathname.startsWith("/noithatgiasi") || router.pathname.startsWith("/referral-program") ? styles["noithatgiasi"] : ""
         )}
      >
         {isShow && (
            <div className={styles["info"]}>
               <svg className={styles["bg"]} xmlns="http://www.w3.org/2000/svg" width="119" height="35" viewBox="0 0 119 35" fill="none">
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M4 0C1.79086 0 0 1.79086 0 4V24C0 26.2091 1.79086 28 4 28H72.0621C72.792 32.9784 79.7233 35.6716 80.7011 34.8558C81.1413 34.4885 80.8102 33.7918 80.3676 32.8601C79.7663 31.5946 78.9591 29.8956 79.5985 28H115C117.209 28 119 26.2091 119 24V4C119 1.79086 117.209 0 115 0H4Z"
                     fill="#FFCFC6"
                  />
               </svg>
               <span className={styles["text"]}>Tư vấn miễn phí</span>

               <svg
                  onClick={() => setIsShow(false)}
                  className={styles["close-icon"]}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
               >
                  <path d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z" fill="white" />
                  <path
                     fill-rule="evenodd"
                     clip-rule="evenodd"
                     d="M6.00284 1.09094C8.71406 1.09094 10.9119 3.28882 10.9119 6.00003C10.9119 8.71125 8.71406 10.9091 6.00284 10.9091C3.29162 10.9091 1.09375 8.71125 1.09375 6.00003C1.09375 3.28882 3.29162 1.09094 6.00284 1.09094ZM3.67809 3.65846L3.66126 3.67528C3.44825 3.8883 3.44825 4.23366 3.66126 4.44667L5.21462 6.00003L3.66126 7.5534C3.44825 7.76641 3.44825 8.11177 3.66126 8.32478L3.67809 8.34161C3.8911 8.55463 4.23647 8.55463 4.44948 8.34161L6.00284 6.78825L7.5562 8.34161C7.76922 8.55463 8.11458 8.55463 8.32759 8.34161L8.34442 8.32478C8.55743 8.11177 8.55743 7.76641 8.34442 7.5534L6.79106 6.00003L8.34442 4.44667C8.55743 4.23366 8.55743 3.8883 8.34442 3.67528L8.32759 3.65846C8.11458 3.44544 7.76922 3.44544 7.5562 3.65846L6.00284 5.21182L4.44948 3.65846C4.23647 3.44544 3.8911 3.44544 3.67809 3.65846Z"
                     fill="#303233"
                  />
               </svg>
            </div>
         )}
         <a className={styles["contact-icon"]} href="https://zalo.me/1171954959320860843" target="_blank" rel="nofollow">
            <img src={ZaloIcon.src} alt="Space T zalo" />
         </a>
         <a className={styles["contact-icon"]} href="tel:+84848847938" rel="nofollow">
            <img src={PhoneIcon.src} alt="Space T phone" />
         </a>
      </div>
   );
}
