/* eslint-disable @next/next/no-img-element */
import { useRouter } from "next/router";
import Media, { IMedia } from "../../models/modelMedia.model";
import styles from "./LayoutMedia.module.scss";

interface IProps<T extends Media> {
   images: T[];
   onSelectImage?: (index: number) => void;
}
export default function LayoutMedia<T extends Media>({ images, onSelectImage }: IProps<T>) {
   const router = useRouter();
   const checkRatioImage = (image: T): "vertical" | "horizontal" => {
      return (image?.info?.width ?? 0) > (image?.info?.height ?? 0) ? "vertical" : "horizontal";
   };

   const handleCLickImage = (e: React.MouseEvent<HTMLElement, MouseEvent>, index: number) => {
      e.stopPropagation();
      onSelectImage && onSelectImage(index);
   };
   if (!images.length) return <></>;

   switch (images.length) {
      case 1:
         return (
            <div className={styles["grid-media-1"]}>
               <img src={images[0]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 0)} />
            </div>
         );
      case 2:
         return (
            <div className={styles[`grid-media-2-${checkRatioImage(images[0])}`]}>
               <img src={images[0]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 0)} />
               <img src={images[1]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 1)} />
            </div>
         );
      case 3:
         return (
            <div className={styles[`grid-media-3-${checkRatioImage(images[0])}`]}>
               <img src={images[0]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 0)} />
               <img src={images[1]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 1)} />
               <img src={images[2]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 2)} />
            </div>
         );
      case 4:
         return (
            <div className={styles[`grid-media-4-${checkRatioImage(images[0])}`]}>
               <img src={images[0]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 0)} />
               <img src={images[1]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 1)} />
               <img src={images[2]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 2)} />
               <img src={images[3]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 3)} />
            </div>
         );
      default:
         return (
            <div className={styles["grid-media-5"]}>
               <div className={styles["grid-media-row-1"]}>
                  <img src={images[0]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 0)} />
                  <img src={images[1]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 1)} />
               </div>
               <div className={styles["grid-media-row-2"]}>
                  <img src={images[2]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 2)} />
                  <img src={images[3]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 3)} />
                  <div className="position-relative">
                     <img src={images[4]?.$url2ColResizeMax} alt="Feed-SpaceT" className={styles["image-item"]} onClick={e => handleCLickImage(e, 4)} />
                     {images.length > 5 && (
                        <div className={styles["more-image"]} onClick={e => handleCLickImage(e, 4)}>
                           <span>
                              <span>+</span>
                              <span>{images.length - 5}</span>
                           </span>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         );
   }
}
