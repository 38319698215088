import { SetStateAction, useState } from "react";

export default function useCustomHookState<T>(initState: T) {
   const [state, setstate] = useState<T>(initState);

   function setState(newState: Partial<T> | SetStateAction<T>) {
      if (typeof newState === "function") {
         setstate(newState);
      } else {
         setstate((prev) => ({ ...prev, ...newState }));
      }
   }

   return { state, setState };
}
