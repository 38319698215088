/**
 * Module(s)
 */

/**
 * Model(s)
 */
import { apiDomain, tokenKey } from "./__variables";
import { ModelError, TModelError, TPayload } from "./model";
import Reaction, { IReaction } from "./reaction.model";

export interface IQnAReaction extends IReaction {

}


export class QnAReaction extends Reaction implements IQnAReaction {
   constructor (data?: IQnAReaction) {
      super(data);
   }

   /**
   * 
   * @param type
   * @param qnaId
   * @param qnaCommentId
   * @returns 
   */
   public static async create(type: string, qnaId: string, qnaCommentId?: string | null) {
      const token = localStorage.getItem(tokenKey);

      if (!token) {
         return new ModelError({
            "httpCode": 401,
            "message": "401 Unauthorized error",
            "errors": {
               "process": [
                  {
                     "code": "401",
                     "message": "401 Unauthorized error"
                  }
               ]
            }
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/qna/reaction`, {
            "method": "POST",
            "headers": {
               "X-Requested-With": "XMLHttpRequest",
               'Authorization': `Bearer ${token}`,
               "Content-Type": 'application/json',
            },
            "body": JSON.stringify({
               "type": type ?? 'like',
               qnaId,
               qnaCommentId
            })
         });

         if (!response.ok) {
            return new ModelError(await response.json() as TModelError);
         }

         const payload = await response.json() as TPayload<{
            "newQnaReaction": IQnAReaction
         }>;

         return new QnAReaction(payload.data.newQnaReaction);
      }
      catch (error: any) {
         return new ModelError({
            "httpCode": 500,
            "message": error.message as string,
            "errors": {
               "process": [
                  {
                     "code": "process.error.5000",
                     "message": "Process error on handling."
                  }
               ]
            }
         });
      }
   }

   /**
   * 
   * @param id
   * @returns 
   */
   public static async delete(id: string) {
      const token = localStorage.getItem(tokenKey);
      if (!token) {
         return new ModelError({
            "httpCode": 401,
            "message": "401 Unauthorized error",
            "errors": {
               "process": [
                  {
                     "code": "401",
                     "message": "401 Unauthorized error"
                  }
               ]
            }
         });
      }
      try {
         const response = await fetch(`${apiDomain}/www/qna/reaction`, {
            "method": "DELETE",
            "headers": {
               "X-Requested-With": "XMLHttpRequest",
               'Authorization': `Bearer ${token}`,
               "Content-Type": 'application/json',
               "ids": id
            },

         });

         if (!response.ok) {
            return new ModelError(await response.json() as TModelError);
         }
         return;
      }
      catch (error: any) {
         return new ModelError({
            "httpCode": 500,
            "message": error.message as string,
            "errors": {
               "process": [
                  {
                     "code": "process.error.5000",
                     "message": "Process error on handling."
                  }
               ]
            }
         });
      }
   }

}

export default QnAReaction;