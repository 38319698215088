import React from "react";
import Svg from "../Svg";

const Icon = props => (
   <Svg viewBox="0 0 10 15" {...props}>
      <path
         d="M9 7.25C9 7.36807 8.97743 7.48052 8.93228 7.58735C8.88713 7.68855 8.81658 7.78414 8.72064 7.8741L2.43069 14.0054C2.27266 14.1685 2.07795 14.25 1.84656 14.25C1.68289 14.25 1.53898 14.2135 1.41481 14.1404C1.29065 14.0673 1.18907 13.9661 1.11005 13.8367C1.03668 13.7131 1 13.5725 1 13.4151C1 13.1902 1.08466 12.9934 1.25397 12.8247L6.97672 7.25L1.25397 1.6753C1.08466 1.50663 1 1.30984 1 1.08494C1 0.921888 1.03668 0.778514 1.11005 0.654819C1.18907 0.531125 1.29065 0.432731 1.41481 0.359639C1.53898 0.286546 1.68289 0.25 1.84656 0.25C2.07795 0.25 2.27266 0.331526 2.43069 0.494578L8.72064 6.6259C8.81658 6.71586 8.88713 6.81426 8.93228 6.92108C8.97743 7.02229 9 7.13193 9 7.25Z"
         // fill="white"
         // stroke="white"
         strokeWidth="0.5"
      />
   </Svg>
);

export default Icon;
