import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./nodata.module.scss";

interface IProps {
   title?: string;
   message?: any;
   image?: ReactNode | string;
   noIcon?: string;
   className?: string;
}

export default class NoData extends React.Component<IProps> {
   render() {
      const { title, message, noIcon, image, className, ...props } = this.props;
      return (
         <div className={classNames([styles["empty"], className || ""])} {...props}>
            {!noIcon &&
               (image ? (
                  image
               ) : (
                  <div className={styles["img"]}>
                     <img src={image ? (image as string) : "/assets/img/icon/nodata.svg?v=1.1"} alt="Space T" />
                  </div>
               ))}
            {title && <h3 className={classNames([styles["title-empty"], "title-empty"])}>{title}</h3>}
            {message && <p className={classNames([styles["des-empty"], "des"])}>{message}</p>}
         </div>
      );
   }
}
