/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from "react";
import styles from "./ImageTagProduct.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import PopupTagging, { IFormInputs } from "./PopupTaggProduct";
import ProductTaggingImage from "../../models/productTaggingImage.model";
import { ModelError } from "../../models/model";
import DialogConfirm from "../dialogConfirm";
import Tagging from "./tagging/Tagging";
import SocialFeed from "../../models/socialFeed.model";
import { ShopIcon } from "@Components/Svg";
import DialogProductNotAccept from "@Components/dialog/DialogProductNotAccept";
import { useRouter } from "next/router";
import { useAppStoreContext } from "@Components/AppStoreContext";

interface IProps {
   productsTagging?: ProductTaggingImage[];
   imageSrc: string;
   socialFeedId: string;
   imageId: string;
   socialFeed?: SocialFeed;
   onDelete?: () => void;
   onUpdateTagging?: (productsTagging: ProductTaggingImage[]) => void;
   onAddNewTagging?: (productsTagging: ProductTaggingImage[]) => void;
}

export default function ImageTagProduct({ imageSrc, socialFeed, socialFeedId, imageId, productsTagging, onUpdateTagging, onAddNewTagging, onDelete }: IProps) {
   const [showTagging, setShowTagging] = useState<boolean | undefined>(true);
   const [tagging, setTagging] = useState(true);
   const [popover, setPopover] = useState<{ top: number; left: number }>();
   const [productTagging, setProductTagging] = useState<{ top: number; left: number }>();
   const [openDialogConfirm, setOpenDialogConfirm] = useState<boolean>(false);
   const [toggleDialogProductNotAccept, setToggleDialogProductNotAccept] = useState(false);

   const router = useRouter();
   const { toggleProgressbar } = useAppStoreContext();

   const handleTaggingProduct = (e: React.MouseEvent<HTMLImageElement>) => {
      try {
         if (!tagging) return;
         e.stopPropagation();
         e.preventDefault();
         const rectIamge = e.currentTarget.getBoundingClientRect();
         const coordinateXProduct = e.clientX - rectIamge.left;
         const coordinateYProduct = e.clientY - rectIamge.top;
         const coordinatesPopover = {
            left: coordinateXProduct - 140,
            top: coordinateYProduct + 20,
         };

         if (coordinateXProduct - 140 < 0) {
            if (coordinateXProduct + 280 <= rectIamge.width) {
               coordinatesPopover.left = coordinateXProduct;
            } else {
               coordinatesPopover.left = 0;
            }
         } else if (coordinateXProduct + 140 > rectIamge.width) {
            if (coordinateXProduct - 280 >= 0) {
               coordinatesPopover.left = coordinateXProduct - 280;
            } else {
               coordinatesPopover.left = rectIamge.width;
            }
         }

         if (coordinateYProduct + 330 > rectIamge.height) {
            if (coordinateYProduct - 330 >= 0) {
               coordinatesPopover.top = coordinateYProduct - 330;
            } else {
               coordinatesPopover.top = rectIamge.height - 330;
            }
         }

         setPopover({
            top: coordinatesPopover.top + rectIamge.top,
            left: coordinatesPopover.left + rectIamge.left,
         });
         setProductTagging({ top: (coordinateYProduct / rectIamge.height) * 100, left: (coordinateXProduct / rectIamge.width) * 100 });
      } catch (error) {}
   };

   const handleClosePopover = () => {
      setPopover(undefined);
   };

   const handleCreateTaggingProduct = async (
      taggingType: "internal" | "external",
      { form, product, similar }: { form?: IFormInputs; product?: any; similar?: boolean }
   ): Promise<ProductTaggingImage | ModelError | undefined> => {
      try {
         if (!socialFeedId) return;
         const { productName, price, url, file } = form || {};
         let resImage: ProductTaggingImage | null = null;
         const res = await ProductTaggingImage.createTagging({
            productType: taggingType,
            socialFeedId: socialFeedId,
            socialFeedImageId: imageId,
            ...(taggingType === "internal"
               ? {
                    productId: product?.productId,
                 }
               : {
                    productName: productName?.trim(),
                    productPrice: price,
                    productUrl: url,
                 }),
            topPercent: productTagging?.top ?? 0,
            leftPercent: productTagging?.left ?? 0,
            similar,
         });

         if (res instanceof ModelError) {
            return res;
         }
         if (file && file.length > 0 && taggingType === "external") {
            resImage = await ProductTaggingImage.createTaggingImage({
               productTaggingImageId: res._id,
               file: file[0],
            });
         }
         setProductTagging(undefined);
         onUpdateTagging && onUpdateTagging([...(productsTagging ?? []), resImage ?? res]);
         onAddNewTagging && onAddNewTagging([resImage ?? res]);
         return res;
      } catch (error) {
         return new ModelError(error);
      }
   };

   const toogleDialogConfirm = () => {
      setOpenDialogConfirm(!openDialogConfirm);
   };

   const handleDeleteTagging = async (productTagging: ProductTaggingImage) => {
      try {
         const del = await ProductTaggingImage.deleteTagging({ id: productTagging._id });
         if (del instanceof ModelError) return;
         onUpdateTagging && onUpdateTagging(productsTagging?.filter((tagging) => productTagging._id !== tagging._id) ?? []);
         // setProducts(products.filter((tagging) => productTagging._id !== tagging._id));
      } catch (error) {}
   };

   return (
      <>
         <a className={styles["btn-close"]} onClick={toogleDialogConfirm}>
            <CloseIcon />
         </a>
         <div className={styles["image-tagging"]}>
            <img src={imageSrc} onClick={handleTaggingProduct} className={styles["image"]} />
            {/* {productsTagging && productsTagging?.length > 0 && (
               <div
                  className={styles["show-icon"]}
                  style={{
                     background: showTagging ? "#fff" : "rgba(45, 45, 45, 0.85)"
                  }}
                  onClick={() => setShowTagging(prev => !prev)}
               >
                  <ShopIcon fill={showTagging ? "#FF6347" : "#fff"} width="16px" height="16px" />
               </div>
            )} */}
            {productsTagging && productsTagging.length > 0 && (
               <Tagging
                  router={router}
                  showTagging={showTagging}
                  products={productsTagging}
                  toggleProgressbar={toggleProgressbar}
                  onDeleteProductTagging={handleDeleteTagging}
                  setToggleDialogProductNotAccept={setToggleDialogProductNotAccept}
               />
            )}
            <>
               {/* {!tagging && !popover && (
                  <div className={styles["btn-tagging"]} onClick={toogleTagProduct}>
                     <Image width={22} height={22} src="/assets/img/icon/add-tag.svg" alt="SpaceT" />
                     Tag sản phẩm
                  </div>
               )} */}
            </>

            {popover && productTagging ? (
               <>
                  <div
                     className={styles["point-product"]}
                     style={{ top: `calc(${productTagging.top + "% - 7px"})`, left: `calc(${productTagging.left + "% - 7px"})` }}
                  />
                  <PopupTagging onSubmit={handleCreateTaggingProduct} onClose={handleClosePopover} anchorPosition={popover} />
               </>
            ) : undefined}
            {openDialogConfirm ? (
               <DialogConfirm
                  title={"Xóa ảnh này?"}
                  onClose={toogleDialogConfirm}
                  onConfirm={() => {
                     toogleDialogConfirm();
                     onDelete && onDelete();
                  }}
                  open={openDialogConfirm}
               />
            ) : undefined}
            <DialogProductNotAccept open={toggleDialogProductNotAccept} onClose={() => setToggleDialogProductNotAccept((prev) => !prev)} />
         </div>
         {tagging && !popover && (
            <div className={styles["bg-linear-bottom"]}>
               <span className={styles["noti"]}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                     <path
                        d="M10.4748 22.5C10.0081 22.5 9.57064 22.4 9.1623 22.2C8.75397 22 8.40814 21.7167 8.1248 21.35L2.6748 14.425L3.1498 13.925C3.48314 13.575 3.88314 13.3667 4.3498 13.3C4.81647 13.2333 5.2498 13.325 5.6498 13.575L7.4998 14.7V6.5C7.4998 6.21667 7.59564 5.97917 7.7873 5.7875C7.97897 5.59583 8.21647 5.5 8.4998 5.5C8.78314 5.5 9.0248 5.59583 9.2248 5.7875C9.4248 5.97917 9.5248 6.21667 9.5248 6.5V18.3L7.0998 16.8L9.6998 20.125C9.7998 20.2417 9.91647 20.3333 10.0498 20.4C10.1831 20.4667 10.3248 20.5 10.4748 20.5H15.9998C16.5498 20.5 17.0206 20.3042 17.4123 19.9125C17.804 19.5208 17.9998 19.05 17.9998 18.5V14.5C17.9998 14.2167 17.904 13.9792 17.7123 13.7875C17.5206 13.5958 17.2831 13.5 16.9998 13.5H11.5248V11.5H16.9998C17.8331 11.5 18.5415 11.7917 19.1248 12.375C19.7081 12.9583 19.9998 13.6667 19.9998 14.5V18.5C19.9998 19.6 19.6081 20.5417 18.8248 21.325C18.0415 22.1083 17.0998 22.5 15.9998 22.5H10.4748ZM4.1748 9C3.95814 8.63333 3.79147 8.2375 3.6748 7.8125C3.55814 7.3875 3.4998 6.95 3.4998 6.5C3.4998 5.11667 3.9873 3.9375 4.9623 2.9625C5.9373 1.9875 7.11647 1.5 8.4998 1.5C9.88314 1.5 11.0623 1.9875 12.0373 2.9625C13.0123 3.9375 13.4998 5.11667 13.4998 6.5C13.4998 6.95 13.4415 7.3875 13.3248 7.8125C13.2081 8.2375 13.0415 8.63333 12.8248 9L11.0998 8C11.2331 7.76667 11.3331 7.52917 11.3998 7.2875C11.4665 7.04583 11.4998 6.78333 11.4998 6.5C11.4998 5.66667 11.2081 4.95833 10.6248 4.375C10.0415 3.79167 9.33314 3.5 8.4998 3.5C7.66647 3.5 6.95814 3.79167 6.3748 4.375C5.79147 4.95833 5.4998 5.66667 5.4998 6.5C5.4998 6.78333 5.53314 7.04583 5.5998 7.2875C5.66647 7.52917 5.76647 7.76667 5.8998 8L4.1748 9Z"
                        fill="white"
                     />
                  </svg>
                  Chọn vị trí cần tag
               </span>
            </div>
         )}
      </>
   );
}
