/* eslint-plugin-disable react */
/*eslint-disable */
/**
 * Variable(s)
 */
import { apiDomain } from "./__variables";

/**
 * Core(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";
import VendorImage from "./vendorImage.model";

export enum EStatus {
    enable = "enable",
    disable = "disable",
    delete = "delete"
}

export interface IVendor extends Model {
    ["info"]?: {
        ["name"]?: string | null;
        ["description"]?: string | null;
        ["content"]?: string | null;
        ["slug"]?: string | null;
    };
    ["options"]?: {
        ["status"]?: EStatus;
    };

    ["__logo"]?: VendorImage | null;
}

export default class Vendor extends Model<IVendor> implements IVendor {
    public ["info"]!: {
        ["name"]?: string | null;
        ["description"]?: string | null;
        ["content"]?: string | null;
        ["slug"]?: string | null;
    };
    public ["options"]!: {
        ["status"]?: EStatus;
    };

    public ["__logo"]!: VendorImage | null;

    constructor(data?: IVendor) {
        super(data);

        Object.assign(this, data);
    }

    public static async getVendorById(id: string) {
        try {
            const response = await fetch(`${apiDomain}/www/vendor`, {
                ["method"]: "GET",
                ["cache"]: "no-store",
                ["headers"]: {
                    ["X-Requested-With"]: "XMLHttpRequest",
                    ["id"]: id
                }
            });

            if (!response.ok) {
                return new ModelError(await response.json() as TModelError);
            }

            const payload = await response.json() as TPayload<{
                ["vendor"]: IVendor;
            }>;

            return new Vendor(payload.data.vendor);
        }
        catch (error: any) {
            return new ModelError({
                ["httpCode"]: 500,
                ["message"]: error.message as string,
                ["errors"]: {
                    ["process"]: [
                        {
                            ["code"]: "process.error.5000",
                            ["message"]: "Process error on handling."
                        }
                    ]
                }
            });
        }
    }

    /**
  *
  * @param keywords
  * @param page
  * @param limit
  * @param sort
  * @param sortBy
  * @param ids
  * @returns
  */
    public static async paginate({
        offset, limit, ids
    }: {
       
        limit?: number;
            offset?: number,
        ids?: string
    }) {
        try {

            const res = await Model.fetchData({
                method: 'GET',
                endPoint: `vendor/paginate`, 
                "headers": {
                    "X-Requested-With": "XMLHttpRequest",
                    "limit": !limit ? String(20) : String(limit),
                    ...ids ? {ids} : undefined,
                    ...!offset ? undefined : { offset: String(offset) },
                }
            });

            if (res instanceof ModelError) {
                return res;
            }

            const payload = await res.json() as TPayload<{
                "vendors": Vendor[]
            }>;

            return payload.data.vendors.map((vendor) => new Vendor(vendor));
        }
        catch (error: any) {
          return new ModelError(error)
        }
    }
}