import { UTM_PARAMS } from "../type";
export interface IUTMParams {
   get_consult: string;
   utm_campaign: string;
   utm_source: string;
   utm_medium: string;
   utm_content: string;
   utm_term: string;
}
const initState = {
   get_consult: "",
   utm_campaign: "",
   utm_source: "",
   utm_medium: "",
   utm_content: "",
   utm_term: "",
};
export default function utmParamsReducer(state = initState, action: { type: string; payload: IUTMParams }) {
   switch (action.type) {
      case UTM_PARAMS:
         return {
            ...state,
            ...action.payload,
         };
      default:
         return state;
   }
}
