import { Link, Popover, Skeleton } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { ModelError } from "../../models/model";
import UserNotifications, { EUserNotificationActions, EUserNotificationOpenTypes } from "../../models/userNotifications.model";
import store, { RootState } from "../../state";
import { toggleModalAuth, verify } from "../../state/action/auth";
import NoData from "../NoData/NoData";
import Notification from "./components/notification";
import style from "./styles.module.scss";
import { useSelector } from "react-redux";
import User from "../../models/user.model";
import Image from "next/image";
import { EAuthType } from "@Components/modal/auth/const";

interface IReduxProps {
   auth: {
      user: User;
      notifications: {
         unseen: number;
         cartCount: number;
      };
   };
}

export default function NotificationsDialog({ onClickItem }: { onClickItem?: (type: EUserNotificationOpenTypes, noti: UserNotifications) => void }) {
   const [anchorPopoverNoti, setAnchorPopoverNoti] = useState<HTMLElement>();
   const { auth } = useSelector<RootState>((state) => state) as IReduxProps;

   const handlePopoverNoti = (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorPopoverNoti(event.currentTarget);
   };

   const handleClosePopoverNoti = () => {
      setAnchorPopoverNoti(undefined);
   };

   const handleNotiItemClick = (type: EUserNotificationOpenTypes, noti: UserNotifications) => {
      setAnchorPopoverNoti(undefined);
      onClickItem?.(type, noti);
   };

   return (
      <>
         <Popover
            onClose={handleClosePopoverNoti}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "center",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "center",
            }}
            className={"notification-paper"}
            anchorEl={anchorPopoverNoti}
            open={Boolean(anchorPopoverNoti)}
         >
            <Notifications onClickItem={handleNotiItemClick} />
         </Popover>
         {!auth?.user ? (
            <a
               onClick={() => {
                  store.dispatch(toggleModalAuth({ visible: true, type: EAuthType.signin }));
               }}
               className={classNames([style["user-button"], style["noti-button"]])}
               title="Tài khoản"
            >
               <Image width={26} height={26} src="/assets/img/icon/renovation/notification.svg" alt="Thông báo" />
            </a>
         ) : (
            <div onClick={handlePopoverNoti} className={classNames([style["user-button"], style["noti-button"]])}>
               <Image width={26} height={26} src="/assets/img/icon/renovation/notification.svg" alt="Thông báo" />
               {auth?.notifications?.unseen < 1 || !auth?.notifications?.unseen ? (
                  ""
               ) : (
                  <div className={style["noti-counter"]}>{auth?.notifications?.unseen > 100 ? "100+" : auth?.notifications?.unseen}</div>
               )}
            </div>
         )}
      </>
   );
}

export function Notifications({ onClickItem }: { onClickItem?: (type: EUserNotificationOpenTypes, arg: UserNotifications) => void }) {
   let _prevScrollpos = 0;
   const [loading, setLoading] = useState<boolean>(false);
   const [total, setTotal] = useState<number>();
   const [noti, setNoti] = useState<UserNotifications[]>([]);

   // const [modalBadge, setModalBadge] = useState<{ visible: boolean; dataModal?: any }>({ visible: false, dataModal: null });

   let _refNoti: any = null;
   useEffect(() => {
      paginateNoti();
      handleSeenAll();
   }, []);

   const paginateNoti = async (init?: boolean) => {
      try {
         if (!init) {
            if (loading || (total !== undefined && total <= noti.length)) return;
         }
         setLoading(true);
         const paginate = await UserNotifications.paginate({
            limit: 20,
            offset: init ? 0 : noti.length,
            sort: "desc",
            sortBy: "createdAt",
         });
         if (paginate instanceof ModelError) {
            throw Error(paginate.message);
         }
         init ? setNoti(paginate.docs) : setNoti([...noti, ...paginate.docs]);
         setTotal(paginate.totalDocs);
      } catch (error) {
         console.error(error);
      } finally {
         setLoading(false);
      }
   };

   const handleSeenAll = async () => {
      try {
         const seen = await UserNotifications.update({ id: undefined, action: EUserNotificationActions.seen });
         if (seen instanceof ModelError) {
            throw Error(seen?.message);
         } else {
            store.dispatch(verify());
         }
      } catch (error) {
         console.error(error);
      }
   };

   const handleOnDocumentBottom = () => {
      const currentScrollPos = window.pageYOffset;
      if (_prevScrollpos < currentScrollPos) {
         if (loading || (total !== undefined && total === noti.length)) return;
         paginateNoti();
      }
      _prevScrollpos = currentScrollPos;
   };

   const handleOnContainerBottom = () => {
      const currentScrollPos = _refNoti?.current?.clientHeight;
      if (_prevScrollpos < currentScrollPos) {
         if (loading || (total !== undefined && total === noti.length)) return;
         paginateNoti();
      }
      _prevScrollpos = currentScrollPos;
   };

   const onChange = (notiUpdate: UserNotifications) => {
      const notifications = noti.map((item) => (item._id === notiUpdate._id ? notiUpdate : item));
      setNoti(notifications);
   };
   return (
      <>
         <div className={style.notifications}>
            <div className={classNames([[style.header], "d-none d-md-flex"])}>
               <div className={style.title}>Thông báo</div>
               {/* {auth?.notifications?.unseen > 0 && (
                        <div className={style.read_all} onClick={handleSeenAll}>
                            Xem tất cả
                        </div>
                    )} */}
            </div>
            <div className={"d-md-none d-block"}>
               {/* {auth?.notifications?.unseen > 0 && (
                        <div className={style.read_all} onClick={handleSeenAll}>
                            Xem tất cả
                        </div>
                    )} */}
            </div>
            <div className="d-block d-none">
               <BottomScrollListener onBottom={handleOnDocumentBottom} />
            </div>
            <BottomScrollListener onBottom={handleOnContainerBottom}>
               {(scrollRef) => {
                  const ref = scrollRef as React.LegacyRef<HTMLDivElement>;
                  _refNoti = ref;
                  return (
                     <div className={style.list_notification} ref={ref}>
                        {loading
                           ? [...Array(10)].map((e, i) => {
                                return (
                                   <div key={i} className="mb-2 px-2">
                                      <Skeleton animation="wave" width="100%" height={70} />
                                   </div>
                                );
                             })
                           : undefined}
                        {!noti.length && !loading ? (
                           <NoData message="Chưa có thông báo nào" className={style["empty-center"]} />
                        ) : (
                           noti?.map((doc) => <Notification noti={doc} key={doc._id} onClickItem={onClickItem} onChangeNotification={onChange} />)
                        )}
                     </div>
                  );
               }}
            </BottomScrollListener>
         </div>
      </>
   );
}
