import { CorporateContactJsonLdProps, NextSeoProps } from "next-seo";
import { appDomain } from "../../models/__variables";

// Config

const description = "Space T là Nền tảng kết nối Chủ nhà với các Công ty Nội thất uy tín, cùng hàng ngàn ưu đãi cho hơn 8000+ sản phẩm Nội thất, Trang trí, Gia dụng.";

const BASIC: NextSeoProps = {
   description,
   title: "Space T - Nền tảng Nội thất kết nối Chủ nhà với Công ty Nội thất",
   // canonical: "https://spacet.vn",
   openGraph: {
      description,
      type: "website",
      title: "Space T - Nền tảng Nội thất kết nối Chủ nhà với Công ty Nội thất",
      images: [
         {
            url: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/seo/spacet-link-share.jpg"
         }
      ]
   },
   twitter: {
      site: "https://spacet.vn/",
      handle: "",
      cardType: "summary_large_image"
   }
};

const CONTACT_JSON_LD: CorporateContactJsonLdProps = {
   url: appDomain,
   name: "Space T",
   logo: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/logo.svg",
   contactPoint: [
      {
         "@type": "Person",
         contactType: "customer support",
         telephone: "084-884-7938",
         email: "info@spacet.vn"
      }
   ],
   telephone: "084-884-7938",
   address: "215 Nguyễn Gia Trí, Phường 25, Quận Bình Thạnh, Thành Phố Hồ Chí Minh, việt Nam",
   taxID: "0315679882",
   legalName: "Công ty TNHH Space T",
   leiCode: "0315679882",
   alternateName: " Space T - Nền tảng kết nối mọi nhu cầu nội thất",
   mainEntityOfPage: "https://spacet.vn/",
   sameAs: [
      "https://www.facebook.com/spacetvn",
      "https://www.instagram.com/spacet_vn",
      "https://zalo.me/1171954959320860843",
      "https://www.youtube.com/SpaceT",
      "https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398",
      "https://play.google.com/store/apps/details?id=com.spacet.mobile"
   ]
};

const WEBSITE_JSON_LD = {
   "@context": "https://schema.org",
   "@type": "WebSite",
   name: "Space T",
   url: appDomain,
   potentialAction: {
      "@type": "SearchAction",
      target: "https://spacet.vn/search?data={search_term_string}",
      "query-input": "required name=search_term_string"
   }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
   basic: (pathname: string, pageProps?: any): NextSeoProps => {
      const seo: NextSeoProps = { ...(pageProps?.seo || BASIC) };

      if (!seo.openGraph) {
         seo.openGraph = {};
      }

      seo.openGraph.url = `${appDomain}${pathname}`;

      return seo;
   },
   jsonLD: {
      website: WEBSITE_JSON_LD,
      contact: CONTACT_JSON_LD
   }
};
