import { Paginate, initPaginate } from "models/model";
import * as types from "./types";
import SocialFeed, { ISocialFeed } from "models/socialFeed.model";
import User, { IUser } from "models/user.model";
import Article, { IArticle } from "models/article.model";
import { IUserBookmark } from "models/userBookmark.model";
import Product, { IProduct } from "models/product.model";
import ActualWork, { IActualWork } from "models/actualWork.model";
import Community, { ICommunity } from "models/community.model";
import GeneralMediasBySpace, { IGeneralMediasBySpace } from "models/generalMediasBySpace.model";

interface ISearchState {
   feeds: Paginate<SocialFeed, ISocialFeed>;
   users: Paginate<User, IUser>;
   photos: Paginate<GeneralMediasBySpace, IGeneralMediasBySpace>;
   designLibrary: { paginate: Paginate<ActualWork, IActualWork>; userBookmarks: IUserBookmark[] };
   actualWorks: { paginate: Paginate<ActualWork, IActualWork>; userBookmarks: IUserBookmark[] };
   community: { paginate: Paginate<Community, ICommunity>; userBookmarks: IUserBookmark[] };
   blogs: { paginate: Paginate<Article, IArticle>; userBookmarks: IUserBookmark[] };
   products: { paginate: Paginate<Product, IProduct>; userBookmarks: IUserBookmark[] };
   finishedProject: Paginate<Product, IProduct>;
}

const initState: ISearchState = {
   feeds: initPaginate,
   users: initPaginate,
   photos: initPaginate,
   designLibrary: { paginate: initPaginate, userBookmarks: [] },
   actualWorks: { paginate: initPaginate, userBookmarks: [] },
   community: { paginate: initPaginate, userBookmarks: [] },
   blogs: { paginate: initPaginate, userBookmarks: [] },
   products: { paginate: initPaginate, userBookmarks: [] },
   finishedProject: initPaginate
};

export default function searchReducer(state = initState, action): ISearchState {
   const { type, payload } = action;
   switch (type) {
      case types.SET_SEARCH_FEEDS_RESULT:
         return {
            ...state,
            feeds: payload || initPaginate
         };

      case types.SET_SEARCH_USERS_RESULT:
         return {
            ...state,
            users: payload || initPaginate
         };

      case types.SET_SEARCH_PHOTOS_RESULT:
         return {
            ...state,
            photos: payload || initPaginate
         };

      case types.SET_SEARCH_DESIGN_LIBRARY_RESULT:
         return {
            ...state,
            designLibrary: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      case types.SET_SEARCH_ACTUAL_WORK_RESULT:
         return {
            ...state,
            actualWorks: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      case types.SET_SEARCH_COMMUNITY_POST_RESULT:
         return {
            ...state,
            community: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      case types.SET_SEARCH_BLOGS_RESULT:
         return {
            ...state,
            blogs: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      case types.SET_SEARCH_PRODUCTS_RESULT:
         return {
            ...state,
            products: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      case types.SET_SEARCH_FINISHED_PROJECT_RESULT:
         return {
            ...state,
            finishedProject: payload || { paginate: initPaginate, userBookmarks: [] }
         };

      default:
         return state;
   }
}
