import classNames from "classnames";
import LinearProgress from "@mui/material/LinearProgress";
import styles from "./index.module.scss";

export default function ProgressBarRouter({ progress, watchStr }: { progress?: any; watchStr?: string }) {

   // useEffect(() => {
   //    let value = 0;
   //    const timer = setInterval(() => {
   //       setProgress((oldProgress) => {
   //          if (oldProgress === 100 || oldProgress === undefined) {
   //             clearInterval(timer);
   //             return 0;
   //          }
   //          const diff = Math.random() * 20;
   //          value = Math.min(value + diff, 100);
   //          return value;
   //       });
   //    }, 130);

   //    return () => {
   //       clearInterval(timer);
   //    };
   // }, [watchStr]);

   return (
      <>
         {progress && progress !== 0 && progress < 100 ? (
            <LinearProgress variant="determinate" value={progress} className={classNames(styles["progress-bar"])} />
         ) : undefined}
      </>
   );
}
