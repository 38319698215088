import classNames from "classnames";
import ShopMenuItem from "./ShopMenuItem";
import MenuItem from "models/menuItem.model";

interface IProps {
   menus: MenuItem[];
   parentIds?: string[];
   setMenuItems?: (menus: MenuItem[] | undefined) => void;
   onSetClearItems?: () => void;
   isAvailableLevel?: boolean;
   onClickItem?: () => void;
}

export default function ShopMenuList({ menus, isAvailableLevel, parentIds, setMenuItems, onSetClearItems, onClickItem }: IProps) {
   return (
      <>
         <div className={classNames("shop-menu-list")} onMouseLeave={onSetClearItems}>
            <div className="shop-menu-list-content">
               {menus.map((menu) => {
                  if (!menu.info.slug) return;
                  return (
                     <ShopMenuItem
                        key={menu._id}
                        menu={menu}
                        active={parentIds?.includes(menu._id)}
                        onMouseEnter={() => {
                           // Do not display another level if it is under construction
                           if (menu.xpath.split(";;").includes("61a1332bd348d1719d8d1b32")) return;
                           setMenuItems?.(menu.__childs?.length > 0 ? menu.__childs : undefined);
                        }}
                        isAvailableLevel={isAvailableLevel}
                        onClick={onClickItem}
                     />
                  );
               })}
            </div>
         </div>
      </>
   );
}
