import React, { useState } from "react";
import { ModelError } from "../../../models/model";
import { EAuthType, EStep } from "./const";
import User, { EMethod, EOTPType } from "models/user.model";
import InputCode from "@Components/InputCode";
import { useInterval } from "hook/useInterval";
import { useTranslation } from "react-i18next";
import { executeRecaptcha } from "helpers/GoogleRecaptcha";

interface IProps {
   onLogin: () => void;
   setStep: ({ step, dataStep }: { step: EStep; dataStep?: any }) => void;
   form: {
      _id: string;
      phone: string;
      account: string;
      method: EMethod;
   };
   onBack: () => void;
   authType: EAuthType;
   otpType?: EOTPType;
}

let initialField = 6;

const OtpInput: React.FC<IProps> = ({ onLogin, setStep, form, authType, otpType, onBack }) => {
   const { t } = useTranslation();
   const [s, setS] = useState(60);
   const [error, setError] = useState<ModelError>();
   const [status, setStatus] = useState("start");
   const [isCodeError, setIsCodeError] = useState<boolean>(false);
   const [codeValue, setCodeValue] = useState("");

   const onSubmit = async () => {
      if (!codeValue || codeValue?.length < initialField) {
         setIsCodeError(true);
         return;
      }
      setIsCodeError(false);

      const res = await User.verifyOTP(
         form.phone || form.account,
         form._id,
         Number(codeValue),
         form.method,
         otpType ? otpType : authType === EAuthType.forgot ? EOTPType.update : authType === EAuthType.signup ? EOTPType.signup : EOTPType.delete
      );

      if (res instanceof ModelError) {
         setError(res);
         return;
      }

      setStep({
         step: authType === EAuthType.forgot ? EStep.forgotPasswordStep3 : EStep.signupWithPhoneAndPassword,
         dataStep: {
            ...form,
            authCode: codeValue,
         },
      });
   };

   const handleChangeCode = (value: any) => {
      setCodeValue(value);
      // const code = Object.values(value).filter(o => o);
      // if (code.length >= initialField) {
      // onSubmit({ codeValue: value });
      // }
   };

   const requestOTP = async () => {
      if (s > 0) {
         return;
      }
      try {
         if (!form?.phone && !form?.account) {
            return;
         }
         const tokenCaptcha = await executeRecaptcha();

         let verify;
         if (authType === EAuthType.forgot) {
            verify = await User.resetPasswordOTPRequest(form.account, form.method, tokenCaptcha);
         } else {
            verify = await User.signupVerify(form.phone || form.account, tokenCaptcha);
         }
         if (verify instanceof ModelError) {
            setError(verify);
            throw Error(verify.message);
         } else {
            setS(60);
            setStatus("start");
         }
      } catch (error) {
         console.error(error);
      }
   };

   useInterval(
      () => {
         if (s > 0) {
            setS(s - 1);
         } else {
            setStatus("stop");
         }
      },
      status === "start" ? 1000 : null
   );

   return (
      <div className="signup-phone-code">
         <h1 className="signup-phone-title">Nhập mã xác nhận</h1>
         <p className="signup-phone-des">Mã xác nhận gồm 6 số đang được gửi đến {form?.phone || form?.account || ""}</p>

         <div className="form">
            <div className="item-form">
               {/* <label className="label">Số điện thoại</label> */}
               <div className="authen-input">
                  <InputCode initialField={initialField} value={codeValue} isError={isCodeError} onChange={handleChangeCode} />
                  {status === "stop" ? (
                     <div className="resend-code">
                        <p>Không nhận được mã?</p>
                        <span onClick={requestOTP}>Gửi lại</span>
                     </div>
                  ) : (
                     <div className="count-down">
                        Gửi lại <span>{s}s</span>
                     </div>
                  )}
                  {error?.firstMessageErr && <div className="text-error text-center mt-3">{t(error?.firstMessageErr)}</div>}
                  {/* {error?.errors?.authCode?.[0]?.message && <div className="text-error text-center mt-3">{t(error?.errors?.authCode?.[0]?.message || "")}</div>}
                  {error?.errors?.authorization?.[0]?.message && <div className="text-error text-center mt-3">Mã xác nhận không chính xác</div>} */}
               </div>
               {/* {error?.errors.account ? <span className="error-text">{t(`${error?.errors?.account[0]?.message}`)}</span> : undefined} */}
               {/* {error?.errors.OTP && error?.errors.OTP.length > 0 ? <span className="error-text">{t(`${error?.errors.OTP[0].message}`)}</span> : undefined} */}
               {/* {errors?.account?.message ? <span className="error-text">{errors?.account?.message}</span> : undefined} */}
            </div>

            {/* <div>{errors?.password?.message ? <span className="error-text">{errors?.password?.message} </span> : undefined}</div> */}

            <button className="btn btn-auth btn-disable" disabled={!codeValue || codeValue?.length < initialField} onClick={onSubmit}>
               Tiếp tục
            </button>
         </div>

         <div className="login-helper" style={{ marginTop: 30, textAlign: "center" }}>
            {/* Đã có tài khoản? <span onClick={onLogin}>Đăng nhập ngay</span> */}
         </div>

         <div className="back-step" onClick={onBack}>
            <img width="14px" height="7px" src="/assets/img/icon/arrow-left-icon.svg" alt="" />
         </div>
      </div>
   );
};

export default OtpInput;
