import CustomizeDialogV2 from "../indexV2";

const DialogProductNotAccept = ({ open, onClose }: { open?: boolean, onClose?: () => void }) => {
   return (
      <CustomizeDialogV2 open={open} size="medium" onClose={onClose} className="product-not-accept" header={<div>Rất tiếc!</div>}>
         <p className="description">Hiện tại trang bán sản phẩm này đang không truy cập được.</p>
         <button onClick={onClose}>Ok</button>
      </CustomizeDialogV2>
   );
};

export default DialogProductNotAccept;
