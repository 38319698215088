import Community from "./community.model";
import Keyword from "./keyword.model";
/**
 * Module(s)
 */

/**
 * Model(s)
 */
import Model, { ModelError, TModelError, TPayload } from "./model";
import Media from "./modelMedia.model";
import { apiDomain, appDomain, tokenKey } from "./__variables";
import ProductTaggingImage from "./productTaggingImage.model";
export enum ECommunityPostMediaSortBy {
    createdAt = "createdAt",
    updatedAt = "updatedAt"
}

export enum ECommunityPostMediaStatus {
    enable = "enable",
    disable = "disable"
}

export enum ECommunityPostMediaStorageProvider {
    Amazon = "amazon",
    Microsoft = "microsoft",
    Others = "others"
}

export interface IPresignedPost {
    ["url"]: string,
    ["fields"]: {
        [key: string]: string
    }
}
export interface ICommunityMedia extends Media {
    ["communityPostId"]?: string | null;
    ["__communityPost"]?: Community | null;
}


export class CommunityMedia extends Media<ICommunityMedia> implements ICommunityMedia {
    public ["communityPostId"]!: string | null;
    public ["__communityPost"]!: Community | null;

    constructor (data?: ICommunityMedia) {
        super(data);

        Object.assign(this, data);
        
        if (this.__communityPost) {
            this.__communityPost = new Community(this.__communityPost);
        }

        if (this.__keywords) {
            this.__keywords = this.__keywords.map(keyword => new Keyword(keyword));
        }
    }
    private static async presignedPost(presignedPost: IPresignedPost, file: File, communityMedia: ICommunityMedia) {
        const formData = new FormData();
        formData.append("Content-Type", file.type);
        formData.append("key", `${communityMedia.info?.container}/${communityMedia._id}${communityMedia?.info?.extension}`);
        formData.append("file", file);

        for (const key in presignedPost.fields) {
            formData.append(key, presignedPost.fields[key]);
        }
        const response = await fetch(`${presignedPost.url}`, {
            "method": "POST",
            "headers": {
                "X-Requested-With": "XMLHttpRequest",
            },
            "body": formData
        });
        if (!response.ok) {
            return new ModelError(await response.json() as TModelError);
        }
        return response;
    }

    public static async create(file: File) {

        const token = localStorage.getItem(tokenKey);

        if (!token) {
            return new ModelError({
                "httpCode": 401,
                "message": "Unauthorization",
                "errors": {
                    "process": [
                        {
                            "code": "process.error.401",
                            "message": "Đăng nhập để tiếp tục."
                        }
                    ]
                }
            });
        }
        const extension = file.name.split('.').pop();
        const response = await fetch(`${apiDomain}/www/user/community/post/media`, {
            "method": "POST",
            "headers": {
                "Authorization": `Bearer ${token}`,
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                "info.originalName": file.name,
                "info.size": file.size, // Maximum 10485760
                "info.fileType": file.type, // ["image/apng", "image/avif", "image/gif", "image/jpeg", "image/png", "image/svg+xml", "image/webp"]
                "info.extension": `.${extension}`
            })
        });

        if (!response.ok) {
            return new ModelError(await response.json() as TModelError);
        }

        const payload = await response.json() as TPayload<{
            "newCommunityPostMedia": ICommunityMedia;
            "presignedPost": IPresignedPost
        }>;
        // if (!payload.data.presignedPost || !payload.data.newCommunityMedia) {
        //     return
        // }
        const presigned = await this.presignedPost(payload.data.presignedPost, file, payload.data.newCommunityPostMedia);
        // Object.assign(this, payload.data.newCommunityMedia);
        if (!presigned) {
            return new ModelError(await response.json() as TModelError);
        }
        return new CommunityMedia(payload.data.newCommunityPostMedia);
    }

    public static async upload({ file, communityPostId, isAvatar, position, spaceId, description }:{
        file: File, communityPostId: string, isAvatar: '1' | '0', position: number, spaceId?: string, description?: string
    }) {
        try {
            const token = localStorage.getItem(tokenKey);
            if (!token) {
                return new ModelError({
                    "httpCode": 401,
                    "message": "Unauthorization",
                    "errors": {
                        "process": [
                            {
                                "code": "process.error.401",
                                "message": "Đăng nhập để tiếp tục."
                            }
                        ]
                    }
                });
            }
            const formData = new FormData();
            formData.append('file', file);
            formData.append('communityPostId', communityPostId);
            formData.append('isAvatar', isAvatar);
            formData.append('position', position+'');
            spaceId && formData.append('spaceId', spaceId);
            description && formData.append('description', description);
            const response = await fetch(`${apiDomain}/www/user/community/post/media/upload`, {
                method: 'POST',
                "headers": {
                    ["Origin"]: appDomain,
                    "X-Requested-With": "XMLHttpRequest",
                    ...!token ? {} : { 'Authorization': `Bearer ${token}` },
                },
                body: formData
            })

            if (response instanceof ModelError) {
                return response
            }

            const payload = await response.json() as TPayload<{
                "newMedia": ICommunityMedia;
            }>;

            return new CommunityMedia(payload.data.newMedia);
        }
        catch (error) {
            return new ModelError(error);
        }

     
    }

    // get $url() {
    //     return `${this?.info?.protocol}://${this?.info?.domain}/${this?.info?.container}/${this._id}${this?.info?.extension}`;
    // }

}

export default CommunityMedia;