import Product from "models/product.model";
import { NextRouter } from "next/router";

export default function redirectToApp(url) {
   // console.log("redirect", url);
   const query = new URLSearchParams(window.location.search);
   const app_lauch = query.get("app");
   if (!app_lauch) return false;
   return redirectNow(url)
}
export function redirectNow(url) {
   try {
      var IS_IPAD = navigator.userAgent.match(/iPad/i) != null,
         IS_IPHONE = !IS_IPAD && (navigator.userAgent.match(/iPhone/i) != null || navigator.userAgent.match(/iPod/i) != null),
         IS_IOS = IS_IPAD || IS_IPHONE,
         IS_ANDROID = !IS_IOS && navigator.userAgent.match(/android/i) != null,
         IS_MOBILE = IS_IOS || IS_ANDROID;

      // alert(JSON.stringify({ query, app_lauch, IS_IOS, IS_MOBILE }));

      if (IS_IOS && IS_MOBILE) {
         window.location = url;
         setTimeout(function () {
            // If the user is still here, open the App Store
            if (!document.webkitHidden) {
               (window as any).location = "http://itunes.apple.com/app/id" + "id1608834398";
            }
         }, 25);
         return true
      } else if (IS_ANDROID && IS_MOBILE) {
         (window as any).location = "intent:/" + url?.split(":/")[1] + "#Intent;package=" + "com.spacet.mobile" + ";scheme=" + "spacet" + ";launchFlags=268435456;end;";
         return true
      }
   } catch (error) {
      return false
   }
}

// This code support for app, because the app not any solution for this redirect if transfer only the pathname
export enum ERedirectToApp {
   home = "home",
   feed = "feed",
   feedDetail = "feedDetail",
   baoGia = "baoGia",
   blog = "blog",
   blogCategory = "blogCategory",
   blogDetail = "blogDetail",
   shop = "shop",
   shopDetail = "shopDetail",
   shopCategory = "shopCategory",
   construction = "construction",
   funitureCompanies = "funitureCompanies",
   funitureCompaniesDetail = "funitureCompaniesDetail",
   surveyQuestion = "surveyQuestion",
   actualWork = "actualWork",
   actualWorkDetail = "actualWorkDetail",
   flashSale = "flashSale",
   brand = "brand",
   brandDetail = "brandDetail",
   account = "account",
   notification = "notification",
   imageLibrary = "imageLibrary",
   imageLibraryDetail = "imageLibraryDetail",
   designLibrary = "designLibrary",
   designLibraryDetail = "designLibraryDetail",
   blogCommunity = "blogCommunity",
   blogCommunityDetail = "blogCommunityDetail",
   productSelling = "productSelling",
   productNew = "productNew",
   design2d3d = "design2d3d",
}

export async function getCurrentPage(pathname, query) {
   if (pathname === "/" || pathname === "") {
      return ERedirectToApp.home
   } else if (pathname.startsWith("/[findIdeaSlug]/[socialFeedId]")) {
      return ERedirectToApp.feedDetail
   } else if (pathname.startsWith("/[findIdeaSlug]")) {
      return ERedirectToApp.feed
   } else if (pathname.startsWith("/bao-gia")) {
      return ERedirectToApp.baoGia
   } else if (pathname.startsWith("/blog/[categorySlug]/[magazineSlug]")) {
      return ERedirectToApp.blogDetail
   } else if (pathname.startsWith("/blog/[categorySlug]")) {
      return ERedirectToApp.blogCategory
   } else if (pathname.startsWith("/blog")) {
      return ERedirectToApp.blog
      //region [Shop]    
   } else if (pathname.startsWith("/shop/[catalogSlug]")) {
      const typeSlug = await Product.validateSlug(query?.catalogSlug as any)
      if (typeSlug.isCategory && !typeSlug.isProduct) {
         return ERedirectToApp.shopCategory
      } else {
         return ERedirectToApp.shopDetail
      }
   } else if (pathname.startsWith("/shop")) {
      return ERedirectToApp.shop
   } else if (pathname.startsWith("/shop/san-pham-ban-chay")) {
      return ERedirectToApp.productSelling
   } else if (pathname.startsWith("/shop/san-pham-moi")) {
      return ERedirectToApp.productNew
   } else if (pathname.startsWith("/flash-sale")) {
      return ERedirectToApp.flashSale
      //endregion 
   } else if (pathname.startsWith("/quy-trinh-ket-noi")) {
      return ERedirectToApp.construction
   } else if (pathname.startsWith("/cac-cong-ty-noi-that/[partnerSlug]")) {
      return ERedirectToApp.funitureCompaniesDetail
   } else if (pathname.startsWith("/cac-cong-ty-noi-that")) {
      return ERedirectToApp.funitureCompanies
   } else if (pathname.startsWith("/cau-hoi-khao-sat")) {
      return ERedirectToApp.surveyQuestion
   } else if (pathname.startsWith("/shop/thuong-hieu/[brandSlug]")) {
      return ERedirectToApp.brandDetail
   } else if (pathname.startsWith("/shop/thuong-hieu")) {
      return ERedirectToApp.brand
   } else if (pathname.startsWith("/tai-khoan/thong-bao")) {
      return ERedirectToApp.notification
   } else if (pathname.startsWith("/tai-khoan/")) {
      return ERedirectToApp.account
   } else if (pathname.startsWith("/thu-vien-anh/[id]")) {
      return ERedirectToApp.imageLibraryDetail
   } else if (pathname.startsWith("/thu-vien-anh")) {
      return ERedirectToApp.imageLibrary
   } else if (pathname.startsWith("/thu-vien-thiet-ke/[designLibrarySlug]")) {
      return ERedirectToApp.designLibraryDetail
   } else if (pathname.startsWith("/thu-vien-thiet-ke")) {
      return ERedirectToApp.designLibrary
   } else if (pathname.startsWith("/cong-trinh-thuc-te/[portfolioSlug]")) {
      return ERedirectToApp.actualWorkDetail
   } else if (pathname.startsWith("/cong-trinh-thuc-te")) {
      return ERedirectToApp.actualWork
   } else if (pathname.startsWith("/to-am-cua-toi/[communitySlug]")) {
      return ERedirectToApp.blogCommunityDetail
   } else if (pathname.startsWith("/to-am-cua-toi")) {
      return ERedirectToApp.blogCommunity
   } else if (pathname.startsWith("/thiet-ke-2d-3d")) {
      return ERedirectToApp.design2d3d
   }

}

export async function redirectToAppWithAsPath(router: NextRouter) {
   const query = router.query as any
   const currentPageActive = await getCurrentPage(router.pathname, router.query)

   switch (currentPageActive) {
      case ERedirectToApp.feed:
         //feed
         return redirectNow(`spacet://${query.findIdeaSlug as string}`);
      case ERedirectToApp.feedDetail:
         // feed detail
         return redirectNow(`spacet:/${router.asPath}`);
      case ERedirectToApp.baoGia:
         // bao gia
         return redirectNow(`spacet://construction/bao-gia`);
      case ERedirectToApp.blog:
         //blog
         return redirectNow(`spacet://construction/blog/all`);
      case ERedirectToApp.blogCategory:
         //blog  cate detail
         return redirectNow(`spacet://construction/blog/${query?.categorySlug}`);
      case ERedirectToApp.blogDetail:
         //blog detail
         return redirectNow(`spacet://construction/blog/${query?.categorySlug}/${query?.magazineSlug}`);
      case ERedirectToApp.funitureCompanies:
         //cac cong ty noi that
         return redirectNow(`spacet://construction/cac-cong-ty-noi-that`);
      case ERedirectToApp.funitureCompaniesDetail:
         //cac cong ty noi that detail
         return redirectNow(`spacet://construction/cac-cong-ty-noi-that/${query?.partnerSlug}`);
      case ERedirectToApp.surveyQuestion:
         //cau hoi khao sat
         return redirectNow(`spacet://cau-hoi-khao-sat`);
      case ERedirectToApp.actualWork:
         //du an hoan thien
         return redirectNow(`spacet://construction/du-an-hoan-thien/actualWork`);
      case ERedirectToApp.actualWork:
         //du an hoan thien detail
         return redirectNow(`spacet://construction/du-an-hoan-thien/actualWork/${query?.portfolioSlug}`);
      case ERedirectToApp.construction:
         //quy trinh ket noi
         return redirectNow(`spacet://construction/quy-trinh-ket-noi`);
      case ERedirectToApp.shop:
         //shop
         return redirectNow(`spacet://shop`);
      case ERedirectToApp.shopCategory:
         //shop category
         return redirectNow(`spacet://shop/category-shop/${query?.catalogSlug}`);
      case ERedirectToApp.shopDetail:
         // product detail
         return redirectNow(`spacet://shop/${query?.catalogSlug}`);
      case ERedirectToApp.flashSale:
         //flash sale
         return redirectNow(`spacet://shop/flash-sale`);
      case ERedirectToApp.brand:
         //thuong hieu detail
         return redirectNow(`spacet://shop/thuong-hieu/${query?.brandSlug}`);
      case ERedirectToApp.account:
         //tai khoan
         return redirectNow(`spacet://tai-khoan/${query?.userId}`);
      case ERedirectToApp.notification:
         //notification
         return redirectNow(`spacet://notification`);
      case ERedirectToApp.imageLibrary:
         //thu vien anh
         return redirectNow(`spacet://construction/thu-vien-anh`);
      case ERedirectToApp.imageLibraryDetail:
         //thu vien anh detail
         return redirectNow(`spacet://construction/thu-vien-anh/${query.id?.split("~")[1]}`);
      case ERedirectToApp.designLibrary:
         // du an hoan thien
         return redirectNow(`spacet://construction/du-an-hoan-thien/designLibrary`);
      case ERedirectToApp.designLibraryDetail:
         // du an hoan thien detail
         return redirectNow(`spacet://construction/du-an-hoan-thien/designLibrary/${query?.designLibrarySlug}`);
      case ERedirectToApp.blogCommunity:
         // to am cua toi
         return redirectNow(`spacet://construction/du-an-hoan-thien/community`);
      case ERedirectToApp.blogCommunityDetail:
         //to am cua toi detail
         return redirectNow(`spacet://construction/du-an-hoan-thien/community/${query?.communitySlug}`);
      case ERedirectToApp.productSelling:
         //san pham ban chay
         return redirectNow(`spacet://shop/san-pham-ban-chay`);
      case ERedirectToApp.productNew:
         //san pham moi
         return redirectNow(`spacet://shop/san-pham-moi`);

      default:
         window.location.href = typeof navigator !== "undefined" && navigator?.userAgent?.toLowerCase()?.includes("android")
            ? "https://play.google.com/store/apps/details?id=com.spacet.mobile"
            : "https://apps.apple.com/us/app/space-t-n%E1%BB%99i-th%E1%BA%A5t-cho-t%E1%BB%95-%E1%BA%A5m/id1608834398"

         break;
   }
}