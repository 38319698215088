import React, { PropsWithChildren, ReactNode } from "react";
import { DrawerProps, Drawer as MuiDrawer } from "@mui/material";
import classNames from "classnames";

export interface IDrawerProps extends DrawerProps {
   open?: boolean;
   className?: string;
   iconClose?: ReactNode;
   fullWidth?: boolean;
   fullHeight?: boolean;
   maxHeight?: string;
   title?: any;
   header?: any;
   footer?: any;
   anchor?: "left" | "top" | "right" | "bottom";
   onBack?: () => void;
   onClose?: () => void;
}

export const DrawerHeader = ({
   iconClose,
   backLabel,
   onBack,
   onClose,
   children,
}: PropsWithChildren<{
   iconClose?: ReactNode;
   backLabel?: any;
   onBack?: () => void;
   onClose?: () => void;
}>) => {
   return (
      <>
         {onBack ? (
            <div className="drawer-custom__header d-flex align-items-center justify-content-center">
               <button className="back-icon" onClick={onBack}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.2151 4.94118C15.1338 4.94118 15.0878 4.96372 15.0436 5.01007L15.0374 5.01661L8.02298 11.823C7.98313 11.8599 7.96651 11.8878 7.95913 11.9051C7.94817 11.9308 7.94118 11.9608 7.94118 12C7.94118 12.0496 7.94966 12.0821 7.95913 12.1042C7.96651 12.1215 7.98304 12.1495 8.02289 12.1864L8.0311 12.194L15.0364 18.9917C15.0789 19.0344 15.1271 19.0588 15.2151 19.0588C15.2775 19.0588 15.3141 19.0455 15.3391 19.0307L15.353 19.0226L15.3673 19.0154C15.392 19.003 15.4099 18.9872 15.4272 18.9561L15.4319 18.9475L15.437 18.9392C15.4582 18.9044 15.4706 18.8654 15.4706 18.811C15.4706 18.7346 15.4498 18.6812 15.401 18.6281L8.56279 11.9997L15.3971 5.38502C15.4523 5.32142 15.4706 5.26436 15.4706 5.19834C15.4706 5.13309 15.4561 5.10001 15.4434 5.08036L15.4349 5.06708L15.4272 5.05326C15.4077 5.01806 15.384 4.99442 15.3492 4.97503L15.3391 4.96938C15.3141 4.95464 15.2775 4.94118 15.2151 4.94118ZM14.3697 4.35302C14.5975 4.11808 14.8902 4 15.2151 4C15.4239 4 15.6275 4.04798 15.8121 4.15556C15.9938 4.2579 16.1395 4.40274 16.2428 4.58347C16.3605 4.77188 16.4118 4.98265 16.4118 5.19834C16.4118 5.5149 16.2952 5.79773 16.0837 6.02908L16.074 6.03966L9.91556 12.0003L16.0705 17.9663L16.0768 17.973C16.2975 18.2042 16.4118 18.4918 16.4118 18.811C16.4118 19.0271 16.3575 19.234 16.2456 19.4209C16.1415 19.6049 15.9923 19.7514 15.8033 19.8495C15.621 19.9535 15.4206 20 15.2151 20C14.895 20 14.6031 19.8885 14.3731 19.6595L7.3792 12.8731C7.25971 12.7615 7.16 12.6292 7.09374 12.4742C7.02875 12.3222 7 12.1619 7 12C7 11.8402 7.03024 11.6836 7.09374 11.5351C7.16 11.3801 7.2597 11.2478 7.37919 11.1363L14.3697 4.35302Z"
                        fill="black"
                     />
                     <path
                        d="M15.0436 5.01007C15.0878 4.96372 15.1338 4.94118 15.2151 4.94118C15.2775 4.94118 15.3141 4.95464 15.3391 4.96938L15.3492 4.97503C15.384 4.99442 15.4077 5.01806 15.4272 5.05326L15.4349 5.06708L15.4434 5.08036C15.4561 5.10001 15.4706 5.13309 15.4706 5.19834C15.4706 5.26436 15.4523 5.32142 15.3971 5.38502L8.56279 11.9997L15.401 18.6281C15.4498 18.6812 15.4706 18.7346 15.4706 18.811C15.4706 18.8654 15.4582 18.9044 15.437 18.9392L15.4319 18.9475L15.4272 18.9561C15.4099 18.9872 15.392 19.003 15.3673 19.0154L15.353 19.0226L15.3391 19.0307C15.3141 19.0455 15.2775 19.0588 15.2151 19.0588C15.1271 19.0588 15.0789 19.0344 15.0364 18.9917L8.0311 12.194L8.02289 12.1864C7.98304 12.1495 7.96651 12.1215 7.95913 12.1042C7.94966 12.0821 7.94118 12.0496 7.94118 12C7.94118 11.9608 7.94817 11.9308 7.95913 11.9051C7.96651 11.8878 7.98313 11.8599 8.02298 11.823L15.0374 5.01661L15.0436 5.01007Z"
                        fill="black"
                     />
                  </svg>
                  {backLabel && <div className="back-label">{backLabel}</div>}
               </button>
               {children && <div className="drawer-custom__header--title">{children}</div>}
            </div>
         ) : children || iconClose ? (
            <div className="drawer-custom__header">
               {children && <div className="drawer-custom__header--title">{children}</div>}
               {iconClose ? (
                  iconClose
               ) : (
                  <div className={`img-close`} style={{ cursor: "pointer" }} onClick={onClose}>
                     <img src="/assets/img/icon/close.svg" alt="Space T" />
                  </div>
               )}
            </div>
         ) : undefined}
      </>
   );
};

export const Drawer: React.FC<React.PropsWithChildren<IDrawerProps>> = ({
   open,
   className,
   iconClose,
   fullWidth,
   fullHeight,
   maxHeight,
   title,
   anchor,
   header,
   footer,
   onBack,
   onClose,
   children,
   ...props
}) => {
   return (
      <MuiDrawer
         className={classNames(
            "drawer-custom",
            className,
            fullHeight ? "full-height" : "",
            fullWidth ? "fullWidth" : "",
            onBack || title || iconClose ? "showHeader" : ""
         )}
         anchor={anchor || "bottom"}
         PaperProps={{
            style: { ...(maxHeight ? { maxHeight, height: "100%" } : undefined) },
         }}
         open={Boolean(open)}
         onClose={onClose}
         {...props}
      >
         <div className="drawer-custom__body" style={{ paddingBottom: !!footer ? "73px" : 0 }}>
            {header ? (
               header
            ) : (
               <DrawerHeader iconClose={iconClose} onBack={onBack} onClose={onClose}>
                  {title}
               </DrawerHeader>
            )}
            {children}
         </div>
         {footer}
      </MuiDrawer>
   );
};

export default Drawer;
